import { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
  Tooltip
} from '@material-ui/core';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { UserListHead, UserListToolbar } from '../../user';
import { DeleteTeamButton } from '.';
import { applySortFilter, getComparator } from '../../../utils/sort';

const teamsTableHead = [{ id: 'name', label: 'Name', alignRight: false }, { id: '' }];

TeamsTable.propTypes = {
  teamsList: PropTypes.array,
  openModal: PropTypes.func,
  setModalTeam: PropTypes.func,
  updateTeams: PropTypes.func
};

export default function TeamsTable({ teamsList, openModal, setModalTeam, updateTeams }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - teamsList.length) : 0;

  const filteredUsers = applySortFilter(teamsList, getComparator(order), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Card>
      <UserListToolbar
        placeholder="Search teams..."
        filterName={filterName}
        onFilterName={handleFilterByName}
      />

      <Scrollbar>
        <TableContainer data-testid="teams-table" sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={teamsTableHead}
              rowCount={teamsList.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers != null &&
                filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => (
                    <TableRow hover key={i} tabIndex={-1}>
                      <TableCell align="left">{row}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="Team Info">
                          <IconButton
                            onClick={() => {
                              setModalTeam(row);
                              openModal(true);
                            }}
                            color="primary"
                          >
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <DeleteTeamButton updateTeams={updateTeams} team={row} variant="icononly" />
                      </TableCell>
                    </TableRow>
                  ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={teamsList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
