// material
import { IconButton } from '@material-ui/core';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
// utils
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

function copyContentClipboard(event, text) {
  event.stopPropagation();
  if (!navigator.clipboard) {
    console.debug('copy not supported for this browser');
    return;
  }
  navigator.clipboard.writeText(text);
}

CopyButton.propTypes = {
  content: PropTypes.string
};

export default function CopyButton({ content }) {
  return (
    <IconButton
      aria-label="copy"
      size="small"
      onClick={(event) => {
        copyContentClipboard(event, content);
      }}
    >
      <ContentCopyOutlinedIcon fontSize="inherit" />
    </IconButton>
  );
}
