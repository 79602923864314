/* eslint-disable */
'use strict';

const SIG_SERVER_HOST = 'localhost:8443';

// The public elements in this file implement the Server Connector Interface,
// part of the contract between the signaling server and the webrtc client.
// No changes that break backward compatibility are allowed here. Any new
// features must be added as a new function/class in the interface. Any
// additions to the interface must be checked for existence by the client before
// using it.

// The id of the device the client is supposed to connect to.
// The List Devices page in the signaling server may choose any way to pass the
// device id to the client page, this function retrieves that information once
// the client loaded.
// In this case the device id is passed as a parameter in the url.
export function getDeviceID() {
  // TODO: take the device ID from the url params
  return 'cvd-1';
}

// Creates a connector capable of communicating with the signaling server.
export async function createConnector(rackname, jwt) {
  return new WebsocketConnector(rackname, jwt);
}

// Get the appropriate webrtc proxy address depending on the environment
function getWebrtcProxyAddress() {
  const webrtcProxyPort = 443;
  switch (process.env.REACT_APP_STAGE) {
    case 'dev':
      return `wss://webrtcproxy.virtualrack-dev.nextcloud.aero:${webrtcProxyPort}/connect_client`;
    case 'test':
      return `wss://webrtcproxy.virtualrack-test.nextcloud.aero:${webrtcProxyPort}/connect_client`;
    case 'prod':
      return `wss://webrtcproxy.virtualrack.nextcloud.aero:${webrtcProxyPort}/connect_client`;
    default:
      return `wss://webrtcproxy.virtualrack-dev.nextcloud.aero:${webrtcProxyPort}/connect_client`;
  }
}

// A connector object provides high level functions for communicating with the
// signaling server, while hiding away implementation details.
// This class is an interface and shouldn't be instantiated direclty.
// Only the public methods present in this class form part of the Server
// Connector Interface, any implementations of the interface are considered
// internal and not accessible to client code.
class Connector {
  constructor() {
    if (this.constructor == Connector) {
      throw new Error('Connector is an abstract class');
    }
  }

  // Registers a callback to receive messages from the device. A race may occur
  // if this is called after requestDevice() is called in which some device
  // messages are lost.
  onDeviceMsg(cb) {
    throw 'Not implemented!';
  }

  // Selects a particular device in the signaling server and opens the signaling
  // channel with it (but doesn't send any message to the device). Returns a
  // promise to an object with the following properties:
  // - deviceInfo: The info object provided by the device when it registered
  // with the server.
  // - infraConfig: The server's infrastructure configuration (mainly STUN and
  // TURN servers)
  // The promise may take a long time to resolve if, for example, the server
  // decides to wait for a device with the provided id to register with it. The
  // promise may be rejected if there are connectivity issues, a device with
  // that id doesn't exist or this client doesn't have rights to access that
  // device.
  async requestDevice(deviceId) {
    throw 'Not implemented!';
  }

  // Sends a message to the device selected with requestDevice. It's an error to
  // call this function before the promise from requestDevice() has resolved.
  // Returns an empty promise that is rejected when the message can not be
  // delivered, either because the device has not been requested yet or because
  // of connectivity issues.
  async sendToDevice(msg) {
    throw 'Not implemented!';
  }
}

// Returns real implementation for ParentController.
export function createParentController() {
  return new PostMsgParentController();
}

// ParentController object provides methods for sending information from device
// UI to operator UI. This class is just an interface and real implementation is
// at the operator side. This class shouldn't be instantiated directly.
class ParentController {
  constructor() {
    if (this.constructor === DeviceDisplays) {
      throw new Error('ParentController is an abstract class');
    }
  }

  // Create and return a message object that contains display information of
  // device. Created object can be sent to operator UI using send() method.
  // rotation argument is device's physycan rotation so it will be commonly
  // applied to all displays.
  createDeviceDisplaysMessage(rotation) {
    throw 'Not implemented';
  }
}

// This class represents displays information for a device. This message is
// intended to be sent to operator UI to determine panel size of device UI.
// This is an abstract class and should not be instantiated directly. This
// message is created using createDeviceDisplaysMessage method of
// ParentController. Real implementation of this class is at operator side.
export class DeviceDisplaysMessage {
  constructor(parentController, rotation) {
    if (this.constructor === DeviceDisplaysMessage) {
      throw new Error('DeviceDisplaysMessage is an abstract class');
    }
  }

  // Add a display information to deviceDisplays message.
  addDisplay(display_id, width, height) {
    throw 'Not implemented';
  }

  // Send DeviceDisplaysMessage created using createDeviceDisplaysMessage to
  // operator UI. If operator UI does not exist (in the case device web page
  // is opened directly), the message will just be ignored.
  send() {
    throw 'Not implemented';
  }
}

// End of Server Connector Interface.

// Retrieves the websocket url to use for the webrtc connection
function getWebsocketUrl() {
  // return location.protocol + "//" + location.host + "/" + path;
  return (location.protocol == 'http:' ? 'ws://' : 'wss://') + SIG_SERVER_HOST + `/connect_client`;
}

// Implementation of the Connector interface using Websocket connection
class WebsocketConnector extends Connector {
  constructor(rackname, jwt) {
    super();

    // let wsUrl = getWebsocketUrl();
    let webrtcProxyAddress = getWebrtcProxyAddress();
    let wsUrl = `${webrtcProxyAddress}?rack=${rackname}&token=${jwt}`;
    // create Websocket connection to sig server (this.wsPromise)
    this._wsPromise = new Promise((resolve, reject) => {
      try {
        let ws = new WebSocket(wsUrl);
        console.log('try connecting', ws);
        ws.onopen = () => {
          console.info(`Connected to ${wsUrl}`);
          resolve(ws);
        };
        ws.onerror = (evt) => {
          console.error('WebSocket error:', evt);
          reject(evt);
          // If the promise was already resolved the previous line has no effect
          this._wsPromise = Promise.reject(new Error(evt));
        };
        ws.onmessage = (e) => {
          let data = JSON.parse(e.data);
          this._onWebsocketMessage(data);
          console.log('got message', e);
        };
        ws.onclose = (e) => {
          console.log('websocket closed', e);
        };
      } catch (e) {
        console.log('err', e);
      }
    });
  }

  _onWebsocketMessage(message) {
    const type = message.message_type;
    if (message.error) {
      console.error(message.error);
      return;
    }
    switch (type) {
      case 'config':
        this._infra_config = message;
        break;
      case 'device_info':
        if (this._on_device_available) {
          this._on_device_available(message.device_info);
          delete this._on_device_available;
        } else {
          console.error('Received unsolicited device info');
        }
        break;
      case 'device_msg':
        this._onDeviceMessage(message.payload);
        break;
      default:
        console.error('Unrecognized message type from server: ', type);
        console.error(message);
    }
  }

  _onDeviceMessage(message) {
    let type = message.type;
    switch (type) {
      case 'offer':
        if (this._onOffer) {
          this._onOffer({ type: 'offer', sdp: message.sdp });
        } else {
          console.error('Receive offer, but nothing is wating for it');
        }
        break;
      case 'ice-candidate':
        if (this._onIceCandidate) {
          this._onIceCandidate(
            new RTCIceCandidate({
              sdpMid: message.mid,
              sdpMLineIndex: message.mLineIndex,
              candidate: message.candidate
            })
          );
        } else {
          console.error('Received ice candidate but nothing is waiting for it');
        }
        break;
      default:
        console.error('Unrecognized message type from device: ', type);
    }
  }

  // Sends the json object to the device using websocket
  async _wsSendJson(obj) {
    let ws = await this._wsPromise;
    return ws.send(JSON.stringify(obj));
  }

  // Sends the payload to the device using websocket
  async _sendToDevice(payload) {
    this._wsSendJson({ message_type: 'forward', payload });
  }

  // Sets the callback function to call when it receives an offer message
  onOffer(cb) {
    this._onOffer = cb;
  }

  // Sets the callback function to call when it receives an ice candidate message
  onIceCandidate(cb) {
    this._onIceCandidate = cb;
  }

  // Connector function that sets the callback function to call when it receives a message from the device
  onDeviceMsg(cb) {
    this._onDeviceMsgCb = cb;
  }

  // Connector function that handles requesting a device connection
  async requestDevice(device_id) {
    return new Promise((resolve, reject) => {
      this._on_device_available = (deviceInfo) =>
        resolve({
          deviceInfo,
          infraConfig: this._infra_config
        });
      this._wsSendJson({
        message_type: 'connect',
        device_id
      });
    });
  }

  // Connection function that handles sending messages to the Device
  async sendToDevice(payload) {
    this._wsSendJson({ message_type: 'forward', payload });
  }
}

export class DisplayInfo {
  display_id = '';
  width = 0;
  height = 0;

  constructor(display_id, width, height) {
    this.display_id = display_id;
    this.width = width;
    this.height = height;
  }
}

export class DeviceDisplaysMessageImpl {
  device_id = '';
  rotation = 0;
  displays = [];
  parentController = null;

  constructor(parentController, rotation) {
    this.device_id = getDeviceID();
    this.parentController = parentController;
    this.rotation = rotation;
  }

  addDisplay(display_id, width, height) {
    this.displays.push(new DisplayInfo(display_id, width, height));
  }

  send() {
    this.parentController.postMessageToParent(DeviceFrameMessage.TYPE_DISPLAYS_INFO, this);
  }
}

export class DeviceFrameMessage {
  static TYPE_DISPLAYS_INFO = 'displays_info';

  static [Symbol.hasInstance](instance) {
    return 'type' in instance && 'payload' in instance;
  }

  type = '';
  payload = {};

  constructor(type, payload) {
    this.type = type;
    this.payload = payload;
  }
}

class PostMsgParentController {
  createDeviceDisplaysMessage(rotation) {
    return new DeviceDisplaysMessageImpl(this, rotation);
  }

  postMessageToParent(type, payload) {
    if (window.parent === window) return;

    window.parent.postMessage(new DeviceFrameMessage(type, payload));
  }
}
