import { Icon } from '@iconify/react';
import database from '@iconify/icons-ant-design/database-outlined';
// import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import fileSearch from '@iconify/icons-ant-design/file-search-outlined';
import plusOutlined from '@iconify/icons-ant-design/plus-circle-outlined';
import safetyCertificateOutlined from '@iconify/icons-ant-design/safety-certificate-outline';
import teamOutlined from '@iconify/icons-ant-design/team-outlined';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Virtual Racks',
    path: '/racks',
    icon: getIcon(database)
  },
  {
    title: 'New VRack',
    path: '/createrack',
    icon: getIcon(plusOutlined)
  },
  {
    title: 'My Team',
    icon: getIcon(teamOutlined),
    children: [
      {
        title: 'Loadables',
        path: '/team/upload'
      }
    ]
  },
  {
    title: 'Documentation',
    icon: getIcon(fileSearch),
    children: [
      {
        title: 'Release Notes',
        path: '/static/help-system/vrack online help.htm#release_notes.htm',
        target: '_blank',
        external: true
      },
      {
        title: 'User Guide',
        path: '/static/help-system/vrack online help.htm',
        target: '_blank',
        external: true
      },
      {
        title: 'Vrackctl Installation Guide',
        path: '/static/help-system/Content/PDFs/Virtual Rack CLI Installation Guide.pdf',
        target: '_blank',
        external: true
      },
      {
        title: 'Vrackctl Developer Guide',
        path: '/static/help-system/Content/PDFs/Virtual Rack CLI Developer Guide.pdf',
        target: '_blank',
        external: true
      },
      {
        title: 'MikroTik Configuration Guide',
        path: '/static/help-system/Content/PDFs/MikroTik Configuration Guide.pdf',
        target: '_blank',
        external: true
      }
    ]
  },
  {
    title: 'Admin',
    children: [
      {
        title: 'Teams',
        path: '/admin/teams'
      },
      {
        title: 'Policies',
        path: '/admin/policies'
      },
      {
        title: 'Settings',
        path: '/admin/settings'
      }
    ],
    icon: getIcon(safetyCertificateOutlined)
  }
];

export default sidebarConfig;
