import { FormControl, FormControlLabel, RadioGroup, Radio, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import InfoToolTip from '../../common/InfoToolTip';

const persistDataToolTip =
  'Persisting data will allow VM data to be saved in case the VM crashes or if it was powered off. Turn this feature off if data persistence is not needed and would prefer a faster creation time.';

PersistData.propTypes = {
  rack: PropTypes.object,
  updateRackGateway: PropTypes.func,
  currentLRU: PropTypes.object,
  setLRUPersist: PropTypes.func
};

export default function PersistData({ rack, updateRackGateway, currentLRU, setLRUPersist }) {
  const setGatewayPersistence = (event) => {
    updateRackGateway('persistData', event.target.value);
  };

  return (
    <FormControl>
      <Typography variant="subtitle1">
        Persist Data
        <InfoToolTip title={persistDataToolTip} />
      </Typography>
      <RadioGroup
        aria-label="persistence"
        value={rack ? rack.rack.gateway.persistData : currentLRU.persistData}
      >
        <FormControlLabel
          value="true"
          label="Yes"
          onChange={rack ? setGatewayPersistence : setLRUPersist}
          control={<Radio size="small" />}
        />
        <FormControlLabel
          value="false"
          label="No"
          onChange={rack ? setGatewayPersistence : setLRUPersist}
          control={<Radio size="small" />}
        />
      </RadioGroup>
    </FormControl>
  );
}
