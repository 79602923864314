export function GetPayloadJson() {
  // returns a parsed json obj from the local storage payload obj
  const payloadStr = localStorage.getItem('payload');
  if (payloadStr) {
    return JSON.parse(payloadStr);
  }
  return null;
}

export function CheckJWTValid() {
  // checks if the nextcloud jwt is valid
  const payload = GetPayloadJson();
  // check if nextcloud payload is present in localstorage
  if (payload) {
    // check if jwt expired
    if (payload.exp < Date.now() / 1000) {
      console.log('nextcloud jwt expired');
      return false;
    }
    return true;
  }
  return false;
}

export function GetUserEmail() {
  const payload = GetPayloadJson();
  if (payload) {
    return payload.email.toLowerCase();
  }
}

export function GetFullName() {
  const payload = GetPayloadJson();
  if (payload) {
    return `${payload.pac_first_name} ${payload.pac_last_name}`;
  }
  return 'Uknown User';
}

export function IsAdmin(details) {
  if (details) {
    return details.isAdmin;
  }
  return false;
}

export function GetUserICAO() {
  const payload = GetPayloadJson();
  if (payload) {
    return payload.pac_icao_code;
  }
}
