import { Icon } from '@iconify/react';
import serverIcon from '@iconify/icons-ant-design/cloud-server-outlined';
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography, CardContent, Stack } from '@material-ui/core';
import RouterIcon from '@mui/icons-material/Router'; // utils

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(7),
  height: theme.spacing(7),
  justifyContent: 'center',
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

MikroTik.propTypes = {};

export default function MikroTik() {
  return (
    <RootStyle>
      <CardContent sx={{ marginY: 2 }}>
        <Stack direction="row" spacing={3}>
          <IconWrapperStyle>
            <Icon icon={serverIcon} width={35} height={35} />
          </IconWrapperStyle>
          <Stack>
            <Typography variant="h5">MikroTik Connection</Typography>
            <Typography variant="overline" sx={{ opacity: 0.72 }}>
              Connected to AP 999
            </Typography>
          </Stack>
          <RouterIcon
            sx={{
              fontSize: 110,
              position: 'absolute',
              zIndex: -1,
              right: 10,
              top: 5,
              opacity: 0.3
            }}
            htmlColor="white"
          />
        </Stack>
      </CardContent>
    </RootStyle>
  );
}
