import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { MenuItem, FormControl, InputLabel, Select, Alert } from '@material-ui/core';
import { datadogRum } from '@datadog/browser-rum';
import { set } from '../../store/slices/identitySlice';
import { getIDToken } from '../authentication/login/amplify';

async function getIdentities() {
  const jwt = await getIDToken();
  if (jwt) {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/identities`,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${jwt}` }
    });
  }
  console.error('failed to get JWT, user needs to login');
  return null;
}

// the access request url for people who needs access to virtual racks
const accessRequestUrl =
  'https://konos.panasonic.aero/pacesc?id=sc_cat_item&sys_id=626b43271b07d0507484eb9cbc4bcb18&sysparm_category=905d7d121beed4d07484eb9cbc4bcb27';

// ----------------------------------------------------------------------
export default function IdentityChooser() {
  const [identities, setIdentities] = useState(null);
  const [selectedIdentity, setSelectedIdentity] = useState('');
  const [failedGetIdentity, setFailedGetIdentity] = useState(false);
  const dispatch = useDispatch();
  const globalIdentity = useSelector((state) => state.identity.value);
  const setIdentity = (event) => {
    setSelectedIdentity(event.target.value);
    dispatch(set(event.target.value));
  };

  const getIdentitiesHandler = () => {
    getIdentities()
      .then((response) => {
        // update the global identity to set the first on the list
        setIdentities(response.data);
        if (globalIdentity === '') {
          dispatch(set(response.data[0]));
          setSelectedIdentity(response.data[0]);
        } else {
          setSelectedIdentity(globalIdentity);
        }
        setFailedGetIdentity(false);
      })
      .catch((error) => {
        setFailedGetIdentity(true);
        console.error('failed to get identities', error);
      });
  };

  useEffect(() => {
    // updates datadog RUM identity for the user for their current session
    if (selectedIdentity) {
      datadogRum.setUserProperty('identity', selectedIdentity);
    }
  }, [selectedIdentity]);

  useEffect(() => {
    getIdentitiesHandler();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  if (identities) {
    return (
      <>
        {identities.length > 0 ? (
          <FormControl sx={{ minWidth: 170 }}>
            <InputLabel>Identity</InputLabel>
            <Select value={selectedIdentity} label="Identity" onChange={setIdentity} autoWidth>
              {identities.map((identity) => (
                <MenuItem key={identity} value={identity}>
                  {identity}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <span>
            <Alert severity="error">
              Please &nbsp;
              <a target="_blank" rel="noopener noreferrer" href={accessRequestUrl}>
                request
              </a>
              &nbsp; to have access to Virtual Rack
            </Alert>
          </span>
        )}
      </>
    );
  }
  return failedGetIdentity ? (
    <span>
      <Alert severity="error">
        Unable to reach Virtual Rack service. Please check your connection and try again.
      </Alert>
    </span>
  ) : null;
}
