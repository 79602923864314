/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Card, CardHeader, Grid, TextField, Button, Stack, Autocomplete } from '@material-ui/core';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import { getIDToken } from '../../authentication/login/amplify';

async function getRacks() {
  const jwt = await getIDToken();
  if (jwt) {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}/racks`,
      headers: { Authorization: `Bearer ${jwt}` }
    });
  }
  return Promise.reject(new Error('missing jwt'));
}

async function updateRackStop(rackName, enabled) {
  const jwt = await getIDToken();
  if (jwt) {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_API}/admin/features/auto-rack-stop`,
      data: { rackName, enabled },
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
    });
  }
  return Promise.reject(new Error('missing jwt'));
}

function getRacksAutocompleteOptions(racks) {
  // retrieves the list of racks in autocomplete options format
  const newRacks = racks.map((rack) => ({ label: rack.name, id: rack.name }));
  return newRacks;
}

export default function UpdateRackStop() {
  const [rackSelected, setRackSelected] = useState('');
  const [rackList, setRackList] = useState([]);
  const dispatch = useDispatch();
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  const onClickUpdate = (enabled) => {
    updateRackStop(rackSelected.id, enabled)
      .then(() => {
        openSnackbar({ message: 'Updated rack stop feature successfully!' });
      })
      .catch((err) => {
        openSnackbar({
          message: err.response.data.message,
          severity: 'error'
        });
      });
  };

  const onChangeRackName = (event, newValue) => {
    setRackSelected(newValue);
  };

  useEffect(() => {
    // query the list of all vrack names
    getRacks().then((res) => {
      setRackList(res.data);
    });
  }, []);

  return (
    <Card>
      <CardHeader
        title="Automatic Rack Stop Feature"
        subheader="Disables or re-enables the automatic inactive rack stop feature for a particular rack."
      />
      <Grid container p={3} pr={5} rowSpacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            value={rackSelected}
            onChange={onChangeRackName}
            options={getRacksAutocompleteOptions(rackList)}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Racks" />}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <Button color="error" variant="contained" onClick={() => onClickUpdate(false)}>
              Disable
            </Button>
            <Button color="success" variant="contained" onClick={() => onClickUpdate(true)}>
              Enable
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
