import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Grid
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import InfoToolTip from '../../common/InfoToolTip';
import BetaChip from '../../common/BetaChip';

const flightSimToolTip =
  'Runs a software-based flight simulator on the gateway. Control the simulator here: https://flightsimulator-dev.nextcloud.aero';

EnableFlightSimulator.propTypes = {
  rack: PropTypes.object,
  updateRack: PropTypes.func
};

export default function EnableFlightSimulator({ rack, updateRack }) {
  const setFlightSimulatorEnabled = (event) => {
    // updates the rack object's flight simulator enabled property
    const rackCopy = { ...rack };
    rackCopy.rack.simulatedServices.flightSimulator.enabled = event.target.value === 'true';
    updateRack(rackCopy);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">
          Flight Simulator <BetaChip />
          <InfoToolTip title={flightSimToolTip} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            aria-label="persistence"
            value={rack.rack.simulatedServices.flightSimulator.enabled.toString()}
          >
            <FormControlLabel
              value="true"
              label="Enabled"
              onChange={setFlightSimulatorEnabled}
              control={<Radio />}
            />
            <FormControlLabel
              value="false"
              label="Disabled"
              onChange={setFlightSimulatorEnabled}
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
}
