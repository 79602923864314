import { useState, createRef } from 'react';

import PropTypes from 'prop-types';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import SearchNotFound from '../../SearchNotFound';
import { UserListHead, UserListToolbar } from '../../user';
import { RemoveRoleButton } from '.';
import { applySortFilter, getComparator } from '../../../utils/sort';

const roleTableHead = [{ id: 'role', label: 'Role', alignRight: false }, { id: '' }];

RoleTable.propTypes = {
  roles: PropTypes.array,
  team: PropTypes.string,
  updateRoles: PropTypes.func
};

export default function RoleTable({ roles, updateRoles, team }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const tableRef = createRef();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (tableRef.current) {
      tableRef.current.scrollIntoView();
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - roles.length) : 0;

  const filteredUsers = applySortFilter(roles, getComparator(order), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      <UserListToolbar
        disableGutters
        variant="dense"
        sx={{ margin: 0 }}
        placeholder="Search roles..."
        filterName={filterName}
        onFilterName={handleFilterByName}
      />
      <TableContainer data-testid="role-table" sx={{ maxHeight: 350 }}>
        <Table ref={tableRef} size="small">
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={roleTableHead}
            rowCount={roles.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredUsers != null &&
              filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => (
                  <TableRow hover key={i} tabIndex={-1}>
                    <TableCell align="left">{row}</TableCell>
                    <TableCell align="right">
                      <RemoveRoleButton
                        role={row}
                        team={team}
                        updateTable={updateRoles}
                        variant="icononly"
                      />
                    </TableCell>
                  </TableRow>
                ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {isUserNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={roles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
