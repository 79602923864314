/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Container, Grid } from '@material-ui/core';
import { WelcomeBanner, NewsSlider } from '../components/rack/dashboard';
import RacksTable from '../components/rack/dashboard/RacksTable';
import Page from '../components/Page';
import DismissableAlert from '../components/common/DismissibleAlert';
import { getIDToken } from '../components/authentication/login/amplify';

const styles = {
  alert: {
    paddingTop: '0px !important'
  }
};

export default function Racks() {
  const [rackList, setRackList] = useState([]);
  const identity = useSelector((state) => state.identity.value);

  useEffect(() => {
    // initial call to get racklist when identity is updated
    fetchRacks(identity);
    // periodic call to refresh racklist
    const interval = setInterval(() => {
      fetchRacks(identity);
    }, 10000);
    return () => clearInterval(interval);
  }, [identity]);

  const fetchRacks = async (identity) => {
    const jwt = await getIDToken();
    if (jwt && identity !== '') {
      axios
        .get(`${process.env.REACT_APP_API}/racks/identity/${identity}`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
        .then((response) => {
          setRackList(response.data);
          return response.data;
        })
        .catch((error) => {
          console.error('failed to get list of racks', error.message);
        });
    }
  };

  return (
    <Page title="Racks | Virtual Racks">
      <Container maxWidth={false}>
        <Grid container spacing={3} pb={5}>
          <Grid container item xs={12} spacing={3} alignItems="stretch">
            <Grid item xs={12} sx={styles.alert}>
              <DismissableAlert disabled severity="warning" variant="filled">
                SSH access to VRack gateways via password authentication is being discontinued soon.
                Please switch to &nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/static/help-system/Content/PDFs/Virtual Rack CLI Installation Guide.pdf"
                >
                  vrackctl
                </a>
                &nbsp; immediately to securely connect to your VRacks. View the &nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/static/help-system/Content/PDFs/Virtual%20Rack%20CLI%20Developer%20Guide.pdf"
                >
                  Vrackctl Developer Guide
                </a>
                &nbsp; for more information. Please contact the Virtual Rack team for any issues.
              </DismissableAlert>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <WelcomeBanner />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <NewsSlider />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RacksTable rackList={rackList} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
