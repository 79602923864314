import PropTypes from 'prop-types';
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Grid,
  Divider,
  Chip,
  Collapse,
  Stack,
  IconButton,
  Box,
  Typography
} from '@material-ui/core';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { boardIDValues } from './SmartMonitorOptions';

function parseLruData(rackData) {
  const lruData = [];

  Object.keys(rackData.rack.lrus).forEach((lru) => {
    if (rackData.rack.lrus[lru].enabled) {
      lruData.push({ name: lru, ...rackData.rack.lrus[lru] });
    }
  });
  return lruData;
}

// styles for the table
const styles = {
  tableRow: {
    '&:last-child td, &:last-child th': { border: 0 }
  },
  subRowCell: {
    padding: 0
  },
  subRowContent: { paddingX: 2, paddingY: 1 }
};

Row.propTypes = {
  lru: PropTypes.object
};

function Row({ lru }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow key={lru.name} sx={styles.tableRow}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {lru.name}
        </TableCell>
        <TableCell align="right">{lru.units.join(',')}</TableCell>
        <TableCell align="right">{lru.persistData.toString()}</TableCell>
        <TableCell align="right">{lru.size.toString()}</TableCell>
        <TableCell align="right">{lru.diskSize.toString()}</TableCell>
        <TableCell align="right">{lru.resources.limits.cpu}</TableCell>
        <TableCell align="right">{lru.resources.limits.memory}</TableCell>
      </TableRow>
      {/* have a collapsible row for smart monitor configs */}
      {lru.name === 'smartmonitor' ? (
        <TableRow>
          <TableCell colSpan={1} sx={styles.subRowCell} />
          <TableCell colSpan={6} sx={styles.subRowCell}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Stack direction="row" spacing={2} sx={styles.subRowContent}>
                <Box>
                  <Typography variant="overline" mr={2}>
                    IP
                  </Typography>
                  <Chip label={lru.config.ip} size="small" variant="outlined" color="primary" />
                </Box>
                <Box>
                  <Typography variant="overline" mr={2}>
                    Type
                  </Typography>
                  <Chip label={lru.config.type} size="small" variant="outlined" color="primary" />
                </Box>
                <Box>
                  <Typography variant="overline" mr={2}>
                    Subtype
                  </Typography>
                  <Chip
                    label={lru.config.subtype}
                    size="small"
                    variant="outlined"
                    color="primary"
                  />
                </Box>
                <Box>
                  <Typography variant="overline" mr={2}>
                    Board
                  </Typography>
                  <Chip
                    label={boardIDValues[lru.config.boardId].label}
                    size="small"
                    variant="outlined"
                    color="primary"
                  />
                </Box>
              </Stack>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
}

BuildPartsListSummary.propTypes = {
  rack: PropTypes.object
};

export default function BuildPartsListSummary({ rack }) {
  return (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={12}>
        <Divider>
          <Chip label="Build Parts List" variant="outlined" color="secondary" />
        </Divider>
      </Grid>
      <Grid pb={2} item xs={12}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>LRU</TableCell>
                <TableCell align="right">Units</TableCell>
                <TableCell align="right">Persist Data</TableCell>
                <TableCell align="right">Size</TableCell>
                <TableCell align="right">Disk Size</TableCell>
                <TableCell align="right">CPU Limit</TableCell>
                <TableCell align="right">Memory Limit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parseLruData(rack).map((lru, i) => (
                <Row key={i} lru={lru} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
