import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { CheckJWTValid, IsAdmin } from '../../utils/authentication';

ProtectedRoute.propTypes = {
  children: PropTypes.node
};

export default function ProtectedRoute({ children }) {
  const details = useSelector((state) => state.userDetails.value);
  const location = useLocation();
  const valid = CheckJWTValid();

  let userDetails = details;
  if (Object.keys(userDetails).length === 0) {
    userDetails = JSON.parse(localStorage.getItem('user_details'));
  }
  const isAdmin = IsAdmin(userDetails);

  if (!valid) {
    return <Navigate to="/login" />;
  }

  // Check if admin route
  if (location.pathname.startsWith('/admin')) {
    // Check current user metadata
    if (!isAdmin) {
      return <Navigate to="/401" />;
    }
  }
  return <span>{children}</span>;
}
