import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Auth, Amplify } from 'aws-amplify';
import { datadogRum } from '@datadog/browser-rum';
import qs from 'query-string';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { setPayload } from '../../../store/slices/jwtPayloadSlice';
import { setUserDetails } from '../../../store/slices/userDetailsSlice';
import { CheckJWTValid, GetPayloadJson } from '../../../utils/authentication';

const PAYLOAD_LOCALSTORE_KEY = 'payload';
const NEXTCLOUD_LOGIN_URL = 'https://login.nextcloud.aero';
const COGNITO_SETTINGS = {
  identityPoolId: 'us-west-2:3f3a012c-7a71-4e3a-9f78-906b9af0e905',
  region: 'us-west-2'
};

/**
 * saves the jwt locally to persist over refreshes
 */
function getConfig(userPoolId, userPoolWebClientId, userPoolDomain) {
  const Auth = {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: COGNITO_SETTINGS.identityPoolId,
    // REQUIRED - Amazon Cognito Region
    region: COGNITO_SETTINGS.region,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    oauth: {
      // Domain name
      domain: `${userPoolDomain}.auth.${COGNITO_SETTINGS.region}.amazoncognito.com`,
      // Authorized scopes
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      // Callback URL
      redirectSignIn: `${window.location.origin}`,
      // Sign out URL
      redirectSignOut: `https://login.nextcloud.aero`,
      // 'token' for Implicit grant
      responseType: 'CODE',
      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: true
      }
    }
  };
  return { Auth };
}

/**
 * saves the jwt locally to persist over refreshes
 */
function saveJwtPayload(jwtPayload, dispatchFunc) {
  localStorage.setItem(PAYLOAD_LOCALSTORE_KEY, JSON.stringify(jwtPayload));
  // set the jwt globally with redux to trigger changes to other components
  dispatchFunc(setPayload(jwtPayload));
}

/**
 * parse out next cloud tokens from the redirect url and store them in localstorage.
 * @param {dispatchFunc} the dispatch function from redux for the jwt
 */
function parseTokenFromUrl(dispatchFunc) {
  const auth = qs.parse(window.location.hash);

  if (auth.id_token) {
    const jwtPayload = jwt_decode(auth.id_token);
    saveJwtPayload(jwtPayload, dispatchFunc);
  }
  if (auth.refresh_token) {
    saveRefreshToken(auth.refresh_token);
  }
}

/**
 * initializes amplify and the auth library with the amplify config
 */
function awsAmplifyConfigure() {
  const payload = GetPayloadJson();
  const config = getConfig(
    payload.pac_user_pool_id,
    payload.pac_app_client_id,
    payload.pac_user_pool_domain
  );
  Amplify.configure(config);
  Auth.configure(config);
}

/**
 * Saves the refresh token both to localstorage and the amplify refresh token
 * @param {refreshToken} the refresh token that we want to save
 */
function saveRefreshToken(refreshToken) {
  // store refresh token to amplify user session
  Auth.currentAuthenticatedUser()
    .then((user) => {
      const keyPrefix = [
        'CognitoIdentityServiceProvider',
        user.pool.getClientId(),
        user.username
      ].join('.');
      const refreshTokenKey = `${keyPrefix}.refreshToken`;
      user.storage.setItem(refreshTokenKey, refreshToken);
    })
    .catch((err) => {
      console.error('saveRefreshToken: amplify get authenticated user error', err);
    });
}

/**
 * initialize the amplify user
 */
function initSessionUser() {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      // disable redirects with amplify so we can control redirects ourselves for logout
      user.storage.setItem('amplify-signin-with-hostedUI', false);

      // set user information for datadog sessions
      datadogRum.setUser({
        id: user.attributes.email.toLowerCase(),
        name: `${user.attributes.given_name} ${user.attributes.family_name}`,
        email: user.attributes.email.toLowerCase()
      });
    })
    .catch((err) => {
      console.error('amplify get authenticated user error', err);
    });
}

/**
 * Initialize the user metadata from the Vrack API
 */
async function initUserMetadata(dispatch) {
  const jwt = await getIDToken();
  if (jwt) {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}/user`,
      headers: { Authorization: `Bearer ${jwt}` }
    })
      .then((response) => {
        console.debug(`successfully fetched user data: ${JSON.stringify(response.data)}`);
        dispatch(setUserDetails(response.data));
        localStorage.setItem('user_details', JSON.stringify(response.data));
        return response.data;
      })
      .catch(() => {});
  } else {
    console.error('JWT has expired, cannot fetch user metadata');
  }
}

// signs the user out of the user session and invalidates the user's refresh token
export function signOut() {
  // amplify's signout clears the localstorage tokens
  Auth.signOut()
    .then(() => {
      // clear the payload from the localstorage
      localStorage.removeItem(PAYLOAD_LOCALSTORE_KEY);
      // upon successful signout, we will redirect user to the nextcloud logout url to invalidate refresh tokens globally for the user
      const params = new URLSearchParams();
      params.append('returnTo', window.location.origin);
      params.append('signOut', 'global');
      window.location.assign([NEXTCLOUD_LOGIN_URL, params.toString()].join('?'));
    })
    .catch((err) => {
      console.error('failed to sign out', err);
    });
}

// retrieves the ID token from the current user session
export async function getIDToken() {
  try {
    const data = await Auth.currentSession();
    return data.idToken.jwtToken;
  } catch (err) {
    console.warn('failed to get the current session', err);
  }
  return null;
}

/**
 * this component initializes the amplify session
 */
export function AmplifySession() {
  const dispatch = useDispatch();

  useEffect(() => {
    parseTokenFromUrl(dispatch);
    if (CheckJWTValid()) {
      // configure aws amplify with user session
      awsAmplifyConfigure();
      initSessionUser();
      initUserMetadata(dispatch);
    }
  }, [dispatch]);

  return null;
}
