import { filter } from 'lodash';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Box
} from '@material-ui/core';
import filesize from 'filesize';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import { UserListHead, UserListToolbar } from '../user';
import DeleteLoadableButton from './DeleteLoadableButton';
import { fDateTime } from '../../utils/formatTime';
import { getComparatorWithOrderBy } from '../../utils/sort';

const loadablesTableHead = [
  { id: 'filename', label: 'Filename', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'uploaded_by', label: 'Uploader', alignRight: false },
  { id: 'uploaded_at', label: 'Upload Time', alignRight: false },
  { id: 'filesize', label: 'Size', alignRight: false },
  { id: '' }
];

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (loadable) => {
      // searches loadable filename
      if (loadable.filename.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        return true;
      }
      // searches loadable description
      if (loadable.description.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        return true;
      }
      // searches loadable uploader
      if (loadable.uploaded_by.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        return true;
      }

      return false;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

LoadablesTable.propTypes = {
  loadableList: PropTypes.array,
  updateLoadableList: PropTypes.func
};

export default function LoadablesTable({ loadableList, updateLoadableList }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - loadableList.length) : 0;

  const filteredUsers = applySortFilter(
    loadableList,
    getComparatorWithOrderBy(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Box>
      <UserListToolbar
        placeholder="Search loadables..."
        filterName={filterName}
        onFilterName={handleFilterByName}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={loadablesTableHead}
              rowCount={loadableList.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers != null &&
                filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => (
                    <TableRow hover key={i} tabIndex={-1}>
                      <TableCell align="left">{row.filename}</TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                      <TableCell align="left">{row.uploaded_by}</TableCell>
                      <TableCell align="left">{fDateTime(row.uploaded_at * 1000)}</TableCell>
                      <TableCell align="left">{filesize(row.filesize)}</TableCell>
                      <TableCell align="right">
                        <DeleteLoadableButton
                          updateLoadableList={updateLoadableList}
                          loadable={row}
                          variant="icononly"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={loadableList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
