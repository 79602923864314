import { useState } from 'react';

import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  Box,
  Table,
  TableBody,
  TableHead,
  Button
} from '@material-ui/core';
import filesize from 'filesize';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import palette from '../../../theme/palette';

BuildTableRow.propTypes = {
  row: PropTypes.shape({
    ID: PropTypes.string,
    build_reason: PropTypes.string,
    systems: PropTypes.string,
    loadables: PropTypes.object
  }),
  updateKitID: PropTypes.func,
  updateKitIDType: PropTypes.func,
  closeModal: PropTypes.func
};

export default function BuildTableRow({ row, updateKitID, updateKitIDType, closeModal }) {
  const [open, setOpen] = useState(false);

  const selectKit = (srr) => {
    updateKitID(srr.toString());
    updateKitIDType('kit_srr');
  };

  return (
    <>
      <TableRow hover onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.ID}</TableCell>
        <TableCell align="right">{row.systems}</TableCell>
        <TableCell align="right">{row.build_reason}</TableCell>
      </TableRow>
      <TableRow sx={{ background: palette.grey[100] }}>
        <TableCell sx={{ paddingY: 0 }} colSpan={6}>
          <Collapse in={open} unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Kit Name</TableCell>
                    <TableCell>Part Number</TableCell>
                    <TableCell align="right">Version</TableCell>
                    <TableCell align="right">File Size</TableCell>
                    <TableCell align="right">SRR</TableCell>
                    <TableCell align="right">&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.loadables.software.map((kit, i) => (
                    <TableRow key={i}>
                      <TableCell>{kit.name}</TableCell>
                      <TableCell>{kit.partnumber}</TableCell>
                      <TableCell align="right">{kit.version}</TableCell>
                      <TableCell align="right">{filesize(kit.filesize)}</TableCell>
                      <TableCell align="right">{kit.srr}</TableCell>
                      <TableCell align="right">
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          label="Select Kit"
                          onClick={() => {
                            selectKit(kit.srr);
                            closeModal();
                          }}
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
