import { Icon } from '@iconify/react';
import { styled } from '@material-ui/core/styles';
import { Card, Typography, Grid, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import plusFill from '@iconify/icons-eva/plus-fill';
import { GetFullName } from '../../../utils/authentication';

const RootStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 5),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

export default function WelcomeBanner() {
  return (
    <RootStyle>
      <Grid container alignItems="center" alignContent="center" py={2}>
        <Grid item xs={6} md={8} p={1}>
          <Typography variant="h4" sx={{ opacity: 0.72 }}>
            Welcome back,
          </Typography>
          <Typography variant="h4" sx={{ opacity: 1 }} mb={2}>
            {GetFullName()}
          </Typography>
          <Typography variant="body2" sx={{ opacity: 1 }} mb={5}>
            Virtual Rack allows you to create your own virtual environment for development and
            testing. Click the button below to get started!
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/createrack"
            startIcon={<Icon icon={plusFill} />}
          >
            New VRack
          </Button>
        </Grid>
        <Grid container item xs={6} md={4} justifyContent="right">
          <img height="200px" alt="welcome banner" src="/static/illustrations/cloud.svg" />
        </Grid>
      </Grid>
    </RootStyle>
  );
}
