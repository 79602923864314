// material
import { Box, Stack, Divider } from '@material-ui/core';
// components

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function DashboardFooter() {
  return (
    <Box pt={2}>
      <Divider />
      <Stack justifyContent="center" alignItems="center">
        <Box p={1}>
          <Box component="img" src="/static/pac-logo.svg" sx={{ height: 15 }} />
        </Box>
      </Stack>
    </Box>
  );
}
