import { Icon } from '@iconify/react';
import serverIcon from '@iconify/icons-ant-design/cloud-server-outlined';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography, CardContent, Stack, Box } from '@material-ui/core';
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';
// utils
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(7),
  height: theme.spacing(7),
  justifyContent: 'center',
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

LRUCount.propTypes = {
  lrus: PropTypes.array
};

export default function LRUCount({ lrus }) {
  return (
    <RootStyle>
      <CardContent sx={{ marginY: 1 }}>
        <Stack direction="row" spacing={3}>
          <IconWrapperStyle>
            <Icon icon={serverIcon} width={35} height={35} />
          </IconWrapperStyle>
          <Box>
            <Typography variant="h4">{lrus ? lrus.length : 0}</Typography>
            <Typography variant="overline" sx={{ opacity: 0.72 }}>
              Virtual LRUs
            </Typography>
          </Box>
          <DvrOutlinedIcon
            sx={{
              fontSize: 110,
              position: 'absolute',
              zIndex: -1,
              right: 10,
              top: 5,
              opacity: 0.3
            }}
            htmlColor="white"
          />
        </Stack>
      </CardContent>
    </RootStyle>
  );
}
