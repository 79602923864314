// material
import { styled, useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Stack,
  Grid,
  Container,
  Typography,
  Button,
  Grow
} from '@material-ui/core';
// layouts

// components
import Page from '../components/Page';
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%'
}));
// ----------------------------------------------------------------------

export default function Login() {
  const theme = useTheme();

  return (
    <RootStyle title="Login | Virtual Rack">
      <Container maxWidth={false}>
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item sx={{ width: '80%' }} xs={12}>
            <Grow in timeout={1800}>
              <Card sx={{ boxShadow: theme.customShadows.z8 }}>
                <Grid container item alignItems="center" justifyContent="center">
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    item
                    sx={{
                      minHeight: '65vh',
                      background: theme.palette.gradients.primary
                    }}
                    sm={5}
                  >
                    <Stack
                      sx={{ marginY: 3 }}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Typography
                        variant="h5"
                        align="center"
                        sx={{
                          letterSpacing: 3,
                          fontWeight: 100,
                          color: 'white',
                          marginBottom: '15px'
                        }}
                      >
                        WELCOME TO
                      </Typography>
                      <Logo
                        color="white"
                        sx={{
                          height: '80px',
                          width: '260px',
                          marginBottom: '15px'
                        }}
                      />

                      <Typography
                        sx={{ color: 'white', fontStyle: 'italic', m: 1 }}
                        variant="body2"
                        align="center"
                        color="text.secondary"
                      >
                        Your Home for Virtual Testing Environments
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item sm={7}>
                    <CardContent>
                      <Grid container alignItems="center" justifyContent="center" item>
                        <Grid item xs={12}>
                          <Typography
                            variant="h5"
                            align="center"
                            sx={{
                              letterSpacing: 3,
                              fontWeight: 100,

                              marginBottom: '15px'
                            }}
                          >
                            LOGIN TO VIRTUAL RACK
                          </Typography>
                        </Grid>
                        <Grid item sx={{ textAlign: 'center' }} xs={12}>
                          <Button
                            onClick={() => {
                              window.location.href = `https://login.nextcloud.aero/?returnTo=${window.location.origin}`;
                            }}
                            size="large"
                            variant="contained"
                            sx={{ marginTop: '15px', textTransform: 'none' }}
                          >
                            Login via NEXT Cloud Portal
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
