/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
// material
import { Grid, TextField } from '@material-ui/core';

const descriptionInputHelperText = `A short description of the loadable you wish to upload. Must be no more than 64 characters long and contain only spaces and alphanumeric characters,'.', and ':'. Must start and end with alphanumeric character. It is suggested that you include partnumber and version in your description. Ex: Partnumber: 0000000C Version: 1.1`;
const descriptionInputErrWrongLengthText = 'Description must be no more than 64 characters long';
const descriptionInputErrInvalidCharacters = `Description must contain spaces,alphanumeric characters,':',and '.' only. Must start and end with alphanumeric character.`;
const descriptionInputErrEmpty = `Description cannot be empty`;

LoadableDetails.propTypes = {
  clearErrors: PropTypes.func,
  description: PropTypes.string,
  setDescription: PropTypes.func,
  descriptionError: PropTypes.bool
};

export default function LoadableDetails({
  description,
  setDescription,

  descriptionError,
  clearErrors
}) {
  const [descriptionHelperText, setDescriptionHelperText] = useState(descriptionInputHelperText);

  const validateInputs = (description) => {
    if (descriptionError) {
      if (description === '') {
        setDescriptionHelperText(descriptionInputErrEmpty);
      } else if (description.length > 64) {
        setDescriptionHelperText(descriptionInputErrWrongLengthText);
      } else {
        setDescriptionHelperText(descriptionInputErrInvalidCharacters);
      }
    }
  };

  useEffect(() => {
    if (descriptionError) {
      validateInputs(description);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [descriptionError]);

  const handleDescriptionChange = (event) => {
    clearErrors();
    setDescriptionHelperText(descriptionInputHelperText);
    setDescription(event.target.value);
  };

  return (
    <Grid item xs={12} sm={6}>
      <TextField
        data-testid="description-field"
        required
        id="outlined-required"
        label="Description"
        value={description}
        helperText={descriptionHelperText}
        onChange={handleDescriptionChange}
        fullWidth
        error={descriptionError}
      />
    </Grid>
  );
}
