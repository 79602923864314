import { Snackbar, Alert, Slide } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackbarClose, setSnackbarDefaults } from '../store/slices/snackbarSlice';

export default function RootSnackbar() {
  // root snackbar for handling sitewide notifications
  const dispatch = useDispatch();
  const open = useSelector((state) => state.snackbar.open);
  const message = useSelector((state) => state.snackbar.message);
  const duration = useSelector((state) => state.snackbar.duration);
  const severity = useSelector((state) => state.snackbar.severity);

  const handleSnackbarClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    // set snackbar close
    dispatch(setSnackbarClose());
    // delay setting defaults to prevent values from changing mid transition
    setTimeout(() => {
      dispatch(setSnackbarDefaults());
    }, 500);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      TransitionComponent={Slide}
      onClose={handleSnackbarClose}
      autoHideDuration={duration}
    >
      <Alert severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
