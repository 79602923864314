import { useState } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Button } from '@material-ui/core';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenSnackbar } from '../../store/slices/snackbarSlice';
import { getIDToken } from '../authentication/login/amplify';
import ConfirmDialog from '../ConfirmDialog';

DeleteLoadableButton.propTypes = {
  variant: PropTypes.string,
  loadable: PropTypes.object,

  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object
  ]),
  disabled: PropTypes.bool,
  updateLoadableList: PropTypes.func
};

export default function DeleteLoadableButton({
  variant,
  loadable,
  sx = [],
  disabled,
  updateLoadableList
}) {
  const dispatch = useDispatch();
  const [confirmDialogOpen, setDialogOpen] = useState(false);
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));
  const identity = useSelector((state) => state.identity.value);
  const deleteLoadable = async (loadable) => {
    console.debug(`Deleting loadable: ${loadable}`);
    const reqBody = {
      files: [
        {
          filename: loadable.filename
        }
      ]
    };
    const jwt = await getIDToken();
    if (jwt) {
      // proceed to delete loadable
      axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API}/identities/${identity}/loadables`,
        data: reqBody,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
      })
        .then((response) => {
          console.debug(`successfully deleted loadable: ${JSON.stringify(response.data)}`);
          openSnackbar({
            message: `Loadable Filename: ${loadable.filename} deleted successfully!`
          });
          updateLoadableList();
          return response.data;
        })
        .catch((error) => {
          console.error(error.message);
          openSnackbar({ message: 'Failed to delete loadable', severity: 'error' });
        });
    } else {
      console.error('JWT has expired, cannot delete loadable');
    }
  };

  const handleDialogCancel = () => {
    setDialogOpen(false);
  };
  const handleDialogConfirm = () => {
    deleteLoadable(loadable);
    setDialogOpen(false);
  };
  const handleDialogOpen = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  return (
    <>
      <ConfirmDialog
        title="Delete Loadable?"
        content="Are you sure you wish to delete the specified loadable?"
        onCancelHandler={handleDialogCancel}
        onConfirmHandler={handleDialogConfirm}
        isOpen={confirmDialogOpen}
      />
      {variant === 'icononly' && (
        <Tooltip title="Delete Loadable">
          <IconButton onClick={handleDialogOpen} sx={{ ...sx }} disabled={disabled} color="primary">
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      {variant === 'text' && (
        <Tooltip title="Delete Loadable">
          <Button
            onClick={handleDialogOpen}
            variant="contained"
            sx={{ ...sx }}
            disabled={disabled}
            startIcon={<DeleteOutlineIcon />}
          >
            Delete Loadable
          </Button>
        </Tooltip>
      )}
    </>
  );
}
