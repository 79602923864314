/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import useVrackApi from '../../../hooks/useVrackApi';
// the regex rule to validate the rack name. this ensures the name is all lowercase, starts & ends with alphanumeric, and contains no spaces
const defaultTailNumberHelperText = `Uniquely identifies a virtual rack for ground/airside services.`;
const tailNumberErrorText = `Tail Number is invalid. Please make sure that it's unique, can only be a maximum of 8 characters, and contain only alphanumeric and ' -
  ' characters. Must start and end with alphanumeric character.`;
const tailNumberMaxLength = 8;
const validTailNumber = new RegExp(
  `^(?=.{1,${tailNumberMaxLength}}$)[a-zA-Z0-9]+(\\s{0,1}[a-zA-Z0-9-]+)*(\\s{0,1}[a-zA-Z0-9]+)+$`
);

// updates the rack object's tail number
function updateRackTailNumber(rack, updateRack, tailNumber) {
  const rackCopy = { ...rack };
  rackCopy.rack.tailNumber = tailNumber;
  updateRack(rackCopy);
}

// validates if the tail number is valid from the vrack api
async function validateTailNumber(vrackApiValidateTailNumber, tailNumber) {
  try {
    // validate if tail number is correct length doesn't have any unexpeced characters
    if (!validTailNumber.test(tailNumber)) {
      return false;
    }

    await vrackApiValidateTailNumber({
      url: `/racks/tailnumber/${tailNumber}`
    });

    return true;
  } catch (error) {
    console.error('Error validating tail number', error);
    return false;
  }
}

TailNumber.propTypes = {
  rack: PropTypes.object,
  updateRack: PropTypes.func,
  setNextButtonDisabled: PropTypes.func,
  setValidTail: PropTypes.func
};

export default function TailNumber({ rack, updateRack, setNextButtonDisabled, setValidTail }) {
  const [tailNumber, setTailNumber] = useState(rack.rack.tailNumber || '');
  const [tailNumberError, setTailNumberError] = useState(false);
  const [tailNumberHelperText, setTailNumberHelperText] = useState(defaultTailNumberHelperText);
  const { fetchData: vrackApiValidateTailNumber } = useVrackApi('get', '/racks/tailnumber');
  const handleRackTailNumberChange = async (event) => {
    const tailNumber = event.target.value;
    setTailNumber(tailNumber);

    if (tailNumber !== '') {
      if (await validateTailNumber(vrackApiValidateTailNumber, tailNumber)) {
        updateRackTailNumber(rack, updateRack, tailNumber);
        setTailNumberError(false);
        setValidTail(false);
      } else {
        // failed to validate the tail number
        setNextButtonDisabled(true);
        setTailNumberError(true);
        updateRackTailNumber(rack, updateRack, '');
        setValidTail(true);
      }
    } else {
      // tail number is empty
      setTailNumberError(false);
      updateRackTailNumber(rack, updateRack, tailNumber);
      setValidTail(false);
    }
  };

  // updates the helper text of the input when error is updated
  useEffect(() => {
    if (tailNumberError) {
      setTailNumberHelperText(tailNumberErrorText);
    } else {
      setTailNumberHelperText(defaultTailNumberHelperText);
    }
  }, [tailNumberError]);
  return (
    <TextField
      data-testid="tail-number"
      label="Tail Number"
      error={tailNumberError}
      helperText={tailNumberHelperText}
      value={tailNumber}
      onChange={handleRackTailNumberChange}
      fullWidth
    />
  );
}
