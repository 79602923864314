import { Icon } from '@iconify/react';
import userIcon from '@iconify/icons-ant-design/user-outlined';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography, Grid } from '@material-ui/core';

// utils
import PropTypes from 'prop-types';
import { GetUserEmail } from '../../../utils/authentication';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 5),
  color: theme.palette.primary.darker
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  textAlign: 'right',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

function getUserRackCount(rackList) {
  // counts the number of racks in rackList that was provisioned by the logged in user
  const email = GetUserEmail();
  let userRackCount = 0;
  rackList.forEach((rack) => {
    if (rack.provisioner_username.toLowerCase() === email.toLowerCase()) {
      userRackCount += 1;
    }
  });
  return userRackCount;
}

UserRacksCount.propTypes = {
  rackList: PropTypes.object
};

export default function UserRacksCount({ rackList }) {
  return (
    <RootStyle>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            User VRacks
          </Typography>
          <Typography variant="h3">{rackList ? getUserRackCount(rackList) : 0}</Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="right">
          <IconWrapperStyle>
            <Icon icon={userIcon} width={35} height={35} />
          </IconWrapperStyle>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
