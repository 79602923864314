import { Typography, Box, Stack, Button, ListItem } from '@material-ui/core';
import PropTypes from 'prop-types';

SliderItem.propTypes = {
  overlineText: PropTypes.string,
  headerText: PropTypes.string,
  slideImage: PropTypes.string,
  slideText: PropTypes.string,
  slideLink: PropTypes.string,
  slideLinkText: PropTypes.string,
  slideListItems: PropTypes.array
};

export default function SliderItem({
  overlineText,
  headerText,
  slideImage,
  slideText,
  slideLink,
  slideLinkText,
  slideListItems
}) {
  return (
    <Box sx={{ position: 'relative', minHeight: '300px', height: '100%' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: `url(${slideImage})`
        }}
        height="100%"
        width="100%"
      />
      <Stack
        p={4}
        spacing={2}
        direction="column"
        justifyContent="center"
        sx={{
          position: 'absolute',
          top: 0,
          background:
            'linear-gradient(rgba(100,100,100,.1) 2%, rgba(0,0,0,.5) 40%, rgba(0,0,0,.8));',
          color: '#f1f1f1',
          height: '100%',
          width: '100%'
        }}
      >
        <Typography variant="overline" sx={{ opacity: 0.72 }}>
          {overlineText}
        </Typography>
        <Typography variant="h4" sx={{ lineHeight: 1 }}>
          {headerText}
        </Typography>
        {slideText ? <Typography variant="body2">{slideText}</Typography> : null}
        {slideListItems ? (
          <Box pl={3}>
            <ul>
              {slideListItems.map((item, i) => (
                <ListItem
                  dense
                  disablePadding
                  disableGutters
                  key={i}
                  sx={{
                    display: 'list-item',
                    fontSize: '14px'
                  }}
                >
                  {item}
                </ListItem>
              ))}
            </ul>
          </Box>
        ) : null}
        {slideLink ? (
          <Button
            href={slideLink}
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            variant="contained"
            size="small"
            sx={{ width: '100px' }}
          >
            {slideLinkText}
          </Button>
        ) : null}
      </Stack>
    </Box>
  );
}
