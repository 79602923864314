import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Stack
} from '@material-ui/core';

// regex used to validate ip
const ipRegex =
  /^(172\.17\.(?!127)(?:1[2-8][0-9]|19[0-1])\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9]))$/;

// this map shows what subtype values
export const boardIDValues = {
  1: { label: 'Altus 4K 24in display', subtype: '144' },
  2: { label: 'ECO4K 12in display Snapdragon S820 Auto SoC', subtype: '152' },
  3: { label: 'Altus 4K 43in display', subtype: '146' },
  4: { label: 'Elite 4K 32in display Snapdragon Auto S820 SoC', subtype: '163' },
  6: { label: 'Elite 4K 24in display Snapdragon Auto S820 SoC', subtype: '162' },
  7: { label: 'Elite 4K 20in display Snapdragon Auto S820 SoC', subtype: '161' },
  8: { label: 'Elite 4K 43in display Snapdragon Auto S820 SoC', subtype: '164' },
  10: { label: 'Elite 4K 18in display Snapdragon Auto S820 SoC', subtype: '168' },
  65: { label: 'Elite 4K 16in display Snapdragon Auto S820 SoC', subtype: '165' },
  66: { label: 'ECO4K 13in display Snapdragon S820 Auto SoC', subtype: '153' }
};

const styles = {
  inputs: {
    width: 330
  },
  smallInputs: {
    width: 157
  }
};

SmartMonitorOptions.propTypes = {
  lru: PropTypes.object,
  updateLRU: PropTypes.func
};

export function SmartMonitorOptions({ lru, updateLRU }) {
  const [ip, setIP] = useState(lru.config.ip);
  const [ipError, setIPError] = useState(false);

  const setSmartMonitorIP = (ip) => {
    // update smart monitor ip address
    const s = { ...lru };
    s.config.ip = ip;
    updateLRU(s);
  };

  const setBoard = (event) => {
    // update smart monitor board ID as well as subtype
    const s = { ...lru };
    // updates boardId
    s.config.boardId = event.target.value;
    // update the subtype as well depending on the boardId
    s.config.subtype = boardIDValues[event.target.value].subtype;
    updateLRU(s);
  };

  const validateIP = (event) => {
    // make sure ip begins with 172.17.
    if (event.target.value.startsWith('172.17.')) {
      setIP(event.target.value);
      setIPError(false);
      if (ipRegex.test(event.target.value)) {
        // save ip back to rack object
        setSmartMonitorIP(event.target.value);
      } else {
        setIPError(true);
      }
    }
  };

  // returns a sorted list of board id dependent on their labels
  const getSortedBoardIDs = () =>
    Object.keys(boardIDValues).sort((a, b) =>
      boardIDValues[a].label > boardIDValues[b].label ? 1 : -1
    );

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <TextField label="Type" value={lru.config.type} sx={styles.smallInputs} disabled />
          <TextField label="Subtype" value={lru.config.subtype} disabled sx={styles.smallInputs} />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <InputLabel>Board Type</InputLabel>
          <Select
            value={lru.config.boardId}
            label="board-type"
            onChange={setBoard}
            sx={styles.inputs}
          >
            {getSortedBoardIDs().map((boardId) => (
              <MenuItem key={boardId} value={boardId}>
                {boardIDValues[boardId].label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Specifies the type of smart monitor hardware</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="IP Address"
          variant="outlined"
          value={ip}
          onChange={validateIP}
          sx={styles.inputs}
          helperText="Make sure the ip is valid within 172.17.[128-191].[0-255]"
          error={ipError}
        />
      </Grid>
    </Grid>
  );
}
