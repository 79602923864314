import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import { Tooltip, IconButton, Button } from '@material-ui/core';
import { SSHModal } from '.';

function isRackSSHDisabled(rack) {
  if (rack) {
    return rack.hostname === '';
  }
  return true;
}

ConnectRackButton.propTypes = {
  rackDetails: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object
  ])
};

ConnectRackButton.defaultProps = {
  rackDetails: {},
  type: 'text',
  variant: 'contained',
  color: 'primary'
};

export default function ConnectRackButton({ rackDetails, variant, type, color, sx = [] }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sshModalOpen, setSSHModalOpen] = useState(false);

  const openModal = () => {
    searchParams.append('ssh_open', true);
    setSearchParams(searchParams);
    setSSHModalOpen(true);
  };
  const closeModal = () => {
    searchParams.delete('ssh_open');
    setSearchParams(searchParams);
    setSSHModalOpen(false);
  };
  return (
    <>
      <SSHModal
        isOpen={sshModalOpen}
        closeHandler={closeModal}
        hostname={rackDetails ? rackDetails.hostname : ''}
        rackName={rackDetails ? rackDetails.name : ''}
      />
      <Tooltip title="Connect to VRack" placement="top">
        <>
          {type === 'icononly' && (
            <IconButton
              variant={variant}
              onClick={openModal}
              sx={{ ...sx }}
              disabled={isRackSSHDisabled(rackDetails)}
              color={color}
            >
              <TerminalOutlinedIcon />
            </IconButton>
          )}
          {type === 'text' && (
            <Button
              onClick={openModal}
              variant={variant}
              sx={{ ...sx }}
              disabled={isRackSSHDisabled(rackDetails)}
              startIcon={<TerminalOutlinedIcon />}
              color={color}
            >
              Connect
            </Button>
          )}
        </>
      </Tooltip>
    </>
  );
}
