// material
import { Container, Grid, Typography, Stack } from '@material-ui/core';
// components
import { MOTD, UpdateRackStop } from '../components/admin/settings';
import Page from '../components/Page';

export default function AdminSettings() {
  return (
    <Page title="Admin | Settings | Virtual Racks">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Settings</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <MOTD />
          </Grid>
          <Grid item xs={12}>
            <UpdateRackStop />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
