import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import { getIDToken } from '../../authentication/login/amplify';

AddTeamDialog.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  updateTeams: PropTypes.func
};

export default function AddTeamDialog({ isOpen, closeHandler, updateTeams }) {
  const handleClose = () => {
    closeHandler();
  };

  const handleChange = (event) => {
    setNewTeam(event.target.value);
  };
  const handleAdd = () => {
    addTeam(newTeam);
  };

  const addTeam = async (team) => {
    console.debug(`Adding team: ${team}`);
    const reqBody = [team];
    const jwt = await getIDToken();
    if (jwt) {
      // proceed to add team
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/admin/teams`,
        data: reqBody,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
      })
        .then((response) => {
          console.debug(`successfully added team: ${JSON.stringify(response.data)}`);
          openSnackbar({ message: 'Added team successfully!' });
          updateTeams();
          closeHandler();
          return response.data;
        })
        .catch(() => {
          openSnackbar({ message: 'Failed to add team', severity: 'error' });
          closeHandler();
        });
    } else {
      console.error('JWT has expired, cannot create team');
    }
  };
  const [newTeam, setNewTeam] = useState('');

  const dispatch = useDispatch();
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Add Team</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the name of the team you'd like to add</DialogContentText>
        <TextField
          onChange={handleChange}
          required
          autoFocus
          margin="dense"
          id="team"
          label="Team"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}
