import { createSlice } from '@reduxjs/toolkit';

const defaultOpen = false;
const defaultSeverity = 'success';
const defaultMessage = '';
const defaultDuration = 5000;

export const snackbarSlice = createSlice({
  // this slice is to be able to control the root level snackbar for global popup/notification system
  name: 'snackbar',
  initialState: {
    open: defaultOpen,
    severity: defaultSeverity,
    message: defaultMessage,
    duration: defaultDuration
  },
  reducers: {
    // this reducer's payload will look something like this: {message: 'alert', severity: 'error'}
    setOpenSnackbar: (state, action) => {
      state.open = action.payload;
      if (action.payload.message) {
        state.message = action.payload.message;
      }
      if (action.payload.severity) {
        state.severity = action.payload.severity;
      }
      if (action.payload.duration) {
        state.duration = action.payload.duration;
      }
      state.open = true;
    },
    setSnackbarClose: (state) => {
      state.open = false;
    },
    setSnackbarDefaults: (state) => {
      // sets snackbar state to default values, useful after closing the snackbar
      state.open = defaultOpen;
      state.severity = defaultSeverity;
      state.message = defaultMessage;
      state.duration = defaultDuration;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setOpenSnackbar, setSnackbarClose, setSnackbarDefaults } = snackbarSlice.actions;
export default snackbarSlice.reducer;
