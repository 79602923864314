import { useEffect, useState } from 'react';

import {
  Grid,
  TextField,
  Button,
  Stack,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton
} from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getIDToken } from '../../authentication/login/amplify';
import { SearchKitByProgram, SoftwareKitConfigSelect } from '.';

const kitSelectDefaultHelperText = 'Choose either an SRR or Release ID number for your kit';
const invalidKitErrorHelperText = 'Invalid Kit provided';
const invalidKitFormatErrorHelperText = 'Invalid Kit format provided';
const failedToValidateKitErrorHelperText = 'Failed to validate the kit. Please try again later';

SoftwareKitSelect.propTypes = {
  kitID: PropTypes.string,
  setKitID: PropTypes.func,
  kitIDType: PropTypes.string,
  setKitIDType: PropTypes.func,
  kitConfig: PropTypes.object,
  setKitConfig: PropTypes.func,
  setNextButtonDisabled: PropTypes.func
};

export default function SoftwareKitSelect({
  kitID,
  setKitID,
  kitIDType,
  setKitIDType,
  kitConfig,
  setKitConfig,
  setNextButtonDisabled
}) {
  const [searchOpen, setSearchOpen] = useState(false);
  const [isKitValid, setIsKitValid] = useState(true);
  const [kitSelectHelperText, setKitSelectHelperText] = useState(kitSelectDefaultHelperText);

  const validateKit = async () => {
    const idType = kitIDType === 'kit_srr' ? 'srr' : 'release_id';

    if (kitID === '') {
      setIsKitValid(true);
      setKitSelectHelperText(kitSelectDefaultHelperText);
      if (setNextButtonDisabled) {
        setNextButtonDisabled(false);
      }
      return;
    }

    const jwt = await getIDToken();
    if (jwt) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}/scmdb/release/${kitID}?type=${idType}`,
        headers: { Authorization: `Bearer ${jwt}` }
      })
        .then((resp) => {
          if (resp.status === 200) {
            setIsKitValid(true);
            setKitSelectHelperText(kitSelectDefaultHelperText);
            if (setNextButtonDisabled) {
              setNextButtonDisabled(false);
            }
          }
        })
        .catch((err) => {
          console.error('failed to validate kit', err);
          setIsKitValid(false);
          if (err.response.status === 404) {
            setKitSelectHelperText(invalidKitErrorHelperText);
          } else if (err.response.status === 400) {
            setKitSelectHelperText(invalidKitFormatErrorHelperText);
          } else {
            setKitSelectHelperText(failedToValidateKitErrorHelperText);
          }
          if (setNextButtonDisabled) {
            setNextButtonDisabled(true);
          }
        });
    } else {
      console.error('JWT has expired, cannot fetch available kit configs');
    }
  };

  useEffect(() => {
    validateKit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitID, kitIDType, setNextButtonDisabled]);

  return (
    <>
      <SearchKitByProgram
        isOpen={searchOpen}
        updateKitID={setKitID}
        updateKitIDType={setKitIDType}
        closeHandler={() => setSearchOpen(false)}
      />
      <Grid spacing={2} container item sm={12}>
        <Grid item md={12} sm={12}>
          <Stack direction="row" alignItems="flex-start">
            <TextField
              size="small"
              id="kit"
              label="Kit Identifier"
              value={kitID}
              onChange={(event) => {
                setKitID(event.target.value);
              }}
              error={!isKitValid}
              helperText={kitSelectHelperText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ToggleButtonGroup
                      size="small"
                      color="primary"
                      value={kitIDType}
                      exclusive
                      onChange={(event) => {
                        setKitIDType(event.target.value);
                      }}
                    >
                      <ToggleButton value="kit_srr" sx={{ borderRadius: '0px' }}>
                        SRR
                      </ToggleButton>
                      <ToggleButton value="kit_release_id">Release ID</ToggleButton>
                    </ToggleButtonGroup>
                  </InputAdornment>
                )
              }}
              sx={{ '& .MuiInputBase-root': { paddingRight: 0 } }}
            />
            <Button
              sx={{ height: 'fit-content', marginX: 2, marginY: 0.5 }}
              startIcon={<SearchIcon />}
              variant="outlined"
              onClick={() => {
                setSearchOpen(true);
              }}
            >
              Search By Program
            </Button>
          </Stack>
        </Grid>
        <Grid item md={12} sm={12}>
          <SoftwareKitConfigSelect
            kitID={kitID}
            kitIDType={kitIDType}
            kitConfig={kitConfig}
            setKitConfig={setKitConfig}
          />
        </Grid>
      </Grid>
    </>
  );
}
