import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  TextField,
  Modal,
  Fade,
  Backdrop,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Autocomplete
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import { getIDToken } from '../../authentication/login/amplify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  p: 4
};

AddPolicyModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  policies: PropTypes.array,
  updatePolicies: PropTypes.func
};

const dedupePolicyField = (policies, field) => {
  switch (field) {
    case 'role': {
      const unique = [...new Set(policies.map((policy) => policy.role))];
      return unique;
    }

    case 'resource': {
      const unique = [...new Set(policies.map((policy) => policy.resource))];
      return unique;
    }
    case 'action': {
      const unique = [...new Set(policies.map((policy) => policy.action))];
      return unique;
    }
    default:
      return policies;
  }
};

export default function AddPolicyModal({ isOpen, closeHandler, policies, updatePolicies }) {
  const addPolicy = async (policy) => {
    const jwt = await getIDToken();
    console.debug(`Adding policy: ${JSON.stringify(policy)}`);
    const reqBody = [policy];
    if (jwt) {
      // proceed to add policy
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/admin/policies`,
        data: reqBody,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
      })
        .then((response) => {
          console.debug(`successfully created policy: ${JSON.stringify(response.data)}`);
          openSnackbar({ message: 'Policy created successfully!' });
          updatePolicies();
          return response.data;
        })
        .catch(() => {
          openSnackbar({ message: 'Failed to create policy', severity: 'error' });
        });
    } else {
      console.error('JWT has expired, cannot create policy');
    }
  };

  const handleActionChange = (event, newValue) => {
    const updated = { ...newPolicy, action: newValue };
    setNewPolicy(updated);
  };

  const handleLimitChange = (event) => {
    const updated = { ...newPolicy, limit: event.target.value };
    setNewPolicy(updated);
  };

  const handleResourceChange = (event, newValue) => {
    const updated = { ...newPolicy, resource: newValue };
    setNewPolicy(updated);
  };

  const handleRoleChange = (event, newValue) => {
    const updated = { ...newPolicy, role: newValue };
    setNewPolicy(updated);
  };

  const [newPolicy, setNewPolicy] = useState({});

  const dispatch = useDispatch();
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  return (
    <Modal
      data-testid="add-policy-modal"
      open={isOpen}
      sx={{ outline: 'none' }}
      onClose={closeHandler}
      aria-labelledby="add-policy-modal"
      aria-describedby="modal to add a new policy for RBAC"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={isOpen}>
        <Grid direction="row" sx={style} container>
          <Grid item xs={12} md={1} lg={3} onClick={closeHandler} />
          <Grid item xs={12} md={10} lg={6}>
            <Card>
              <CardHeader title="Add New Policy" />
              <Divider sx={{ marginTop: 2 }} />
              <CardContent>
                <Box sx={{ width: '100%', marginTop: '10px' }}>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <Autocomplete
                        id="action"
                        freeSolo
                        onInputChange={handleActionChange}
                        sx={{ width: 200 }}
                        options={dedupePolicyField(policies, 'action')}
                        renderInput={(params) => <TextField {...params} label="Action" />}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Autocomplete
                        id="resource"
                        freeSolo
                        sx={{ width: 200 }}
                        onInputChange={handleResourceChange}
                        options={dedupePolicyField(policies, 'resource')}
                        renderInput={(params) => <TextField {...params} label="Resource" />}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="Limit"
                        defaultValue=""
                        sx={{ width: 200 }}
                        onChange={handleLimitChange}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Autocomplete
                        id="role"
                        freeSolo
                        onInputChange={handleRoleChange}
                        sx={{ width: 200 }}
                        options={dedupePolicyField(policies, 'role')}
                        renderInput={(params) => <TextField {...params} label="Role" />}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
              <Divider />
              <Box sx={{ padding: 2, textAlign: 'right' }}>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    addPolicy(newPolicy);
                    closeHandler();
                  }}
                  color="primary"
                  variant="contained"
                >
                  Add Policy
                </Button>
                <Button
                  sx={{ marginLeft: 1 }}
                  color="secondary"
                  variant="contained"
                  endIcon={<CloseOutlinedIcon />}
                  onClick={closeHandler}
                >
                  Close
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} med={1} lg={3} onClick={closeHandler} />
        </Grid>
      </Fade>
    </Modal>
  );
}
