import { useState } from 'react';
import axios from 'axios';
// utils
import PropTypes from 'prop-types';
// material
import { IconButton, Tooltip, Button } from '@material-ui/core';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useDispatch } from 'react-redux';

import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import ConfirmDialog from '../../ConfirmDialog';
import { getIDToken } from '../../authentication/login/amplify';
// ----------------------------------------------------------------------
DeletePolicyButton.propTypes = {
  variant: PropTypes.string,
  policy: PropTypes.object,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object
  ]),
  disabled: PropTypes.bool,
  updatePolicies: PropTypes.func
};

export default function DeletePolicyButton({ variant, policy, sx = [], disabled, updatePolicies }) {
  const dispatch = useDispatch();
  const [confirmDialogOpen, setDialogOpen] = useState(false);
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  const deletePolicy = async (policy) => {
    console.debug(`Deleting policy: ${JSON.stringify(policy)}`);
    const reqBody = [policy];
    const jwt = await getIDToken();
    if (jwt) {
      // proceed to delete policy
      axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API}/admin/policies`,
        data: reqBody,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
      })
        .then((response) => {
          console.debug(`successfully deleted policy: ${JSON.stringify(response.data)}`);
          openSnackbar({ message: 'Policy deleted successfully!' });
          updatePolicies();
          return response.data;
        })
        .catch((error) => {
          console.error(error.message);
          openSnackbar({ message: 'Failed to delete policy', severity: 'error' });
        });
    } else {
      console.error('JWT has expired, cannot delete policy');
    }
  };

  const handleDialogCancel = () => {
    setDialogOpen(false);
  };
  const handleDialogConfirm = () => {
    deletePolicy(policy);
    setDialogOpen(false);
  };
  const handleDialogOpen = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  return (
    <>
      <ConfirmDialog
        title="Delete Policy?"
        content="Are you sure you wish to delete the specified policy?"
        onCancelHandler={handleDialogCancel}
        onConfirmHandler={handleDialogConfirm}
        isOpen={confirmDialogOpen}
      />
      {variant === 'icononly' && (
        <Tooltip title="Delete Policy">
          <IconButton
            data-testid="delete-policy-icon"
            onClick={handleDialogOpen}
            sx={{ ...sx }}
            disabled={disabled}
            color="primary"
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      {variant === 'text' && (
        <Tooltip title="Delete Policy">
          <Button
            onClick={handleDialogOpen}
            variant="contained"
            sx={{ ...sx }}
            disabled={disabled}
            startIcon={<DeleteOutlineIcon />}
          >
            Delete Policy
          </Button>
        </Tooltip>
      )}
    </>
  );
}
