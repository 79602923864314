import PropTypes from 'prop-types';
import { TableRow, TableCell, Skeleton } from '@material-ui/core';

TableRowLoadingSkeleton.propTypes = {
  animation: PropTypes.string,
  cellCount: PropTypes.number
};

TableRowLoadingSkeleton.defaultProps = {
  animation: 'wave',
  cellCount: 0
};

export default function TableRowLoadingSkeleton({ cellCount, animation }) {
  return (
    <TableRow data-testid="table-row-skeleton">
      {Array(cellCount)
        .fill('')
        .map((_, i) => (
          <TableCell key={i}>
            <Skeleton animation={animation} />
          </TableCell>
        ))}
    </TableRow>
  );
}
