import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';

function getRunningStateText(runningState) {
  if (runningState === '') {
    // if running state is empty, this means that the rack was made before
    // the stopping of racks was available so it must be running
    return 'running';
  }
  return runningState;
}

function getButtonColor(runningState) {
  switch (getRunningStateText(runningState)) {
    case 'configuring':
      return 'warning';
    case 'running':
      return 'success';
    default:
      return 'secondary';
  }
}

RunningStateChip.propTypes = {
  runningState: PropTypes.string
};

RunningStateChip.defaultProps = {
  runningState: 'stopped'
};

export default function RunningStateChip({ runningState }) {
  return (
    <Chip
      label={getRunningStateText(runningState)}
      size="small"
      color={getButtonColor(runningState)}
    />
  );
}
