import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';

import InfoToolTip from '../../common/InfoToolTip';
import { SmartMonitorOptions } from './SmartMonitorOptions';
import { getAddedLRUs } from './LRU';

const systemTypes = {
  '': { value: '', label: 'None' },
  eXW: { value: 'eXW', label: 'eXWireless' },
  eXCi: { value: 'eXCi', label: 'eXConnect Integrated' },
  eXCs: { value: 'eXCs', label: 'eXConnect Standalone' },
  eX1: { value: 'eX1', label: 'eX1' }
};

export const LRU_OPTIONS_TITLE = 'LRU Options';

// list of possible units given a specified system type
const validUnitCombinations = {
  None: ['0', '1'],
  eXW: ['0'],
  eXCi: ['8'],
  eXCs: ['8'],
  eX1: ['0', '1', '2']
};

LRUOptions.propTypes = {
  lrus: PropTypes.object,
  currentLRU: PropTypes.object,
  updateCurrentLRU: PropTypes.func
};

export function LRUOptions({ lrus, currentLRU, updateCurrentLRU }) {
  const setLRUUnits = (event) => {
    // update units for lru
    const s = { ...currentLRU };
    s.units[event.target.name] = event.target.checked;
    updateCurrentLRU(s);
  };

  const setLRUSystemType = (event) => {
    // clear any existing units set when changing system type
    const s = { ...currentLRU };
    Object.keys(s.units).forEach((unit) => {
      s.units[unit] = false;
    });

    // update system type
    s.system_type = event.target.value;
    updateCurrentLRU(s);
  };

  const getValidSystemTypes = () => {
    // if no LRUs have been added yet and current LRU is FS05, only allowed system types are eXW
    if (getAddedLRUs(lrus) > 0 && currentLRU.value === 'fs05') {
      return [systemTypes.eXW];
    }
    if (lrus.bc03.enabled && currentLRU.value === 'fs05') {
      return [systemTypes.eXCs];
    }
    return Object.values(systemTypes);
  };

  const isUnitDisabled = (unit) => {
    // only LRU with units requiring disabling is fs05
    if (currentLRU.value !== 'fs05') {
      return false;
    }

    // if system type is undefined or empty, replace with 'None'
    const systemType = currentLRU.system_type ? currentLRU.system_type : 'None';

    // check whether valid units include the specified unit #
    return !validUnitCombinations[systemType].includes(unit);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">{LRU_OPTIONS_TITLE}</Typography>
      </Grid>
      <Grid container item ml={2} xs={12}>
        {Object.keys(currentLRU.units).length > 0 ? (
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Units
              <InfoToolTip title="Specifying more unit numbers will deploy more LRUs of the same type." />
            </Typography>
            <FormGroup row>
              {Object.keys(currentLRU.units).map((k) => (
                <FormControlLabel
                  key={k}
                  checked={currentLRU.units[k]}
                  disabled={isUnitDisabled(k)}
                  control={<Checkbox name={k.toString()} />}
                  onChange={setLRUUnits}
                  label={k}
                />
              ))}
            </FormGroup>
          </Grid>
        ) : null}
        {currentLRU.value === 'fs05' ? (
          <Grid item pt={2} xs={12}>
            <FormControl>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                System Type
              </Typography>
              <Select
                displayEmpty
                labelId="system-type-label"
                id="system-type-selector"
                value={currentLRU.system_type}
                onChange={setLRUSystemType}
              >
                {getValidSystemTypes().map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>The system type for your FS05 unit.</FormHelperText>
            </FormControl>
          </Grid>
        ) : null}
        {currentLRU.value === 'smartmonitor' ? (
          <SmartMonitorOptions lru={currentLRU} updateLRU={updateCurrentLRU} />
        ) : null}
      </Grid>
    </>
  );
}
