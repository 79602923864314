import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'yyyy-MM-dd HH:mm:ss xxx');
}

export function fDateTimeSuffix(date) {
  if (date) {
    return format(new Date(date), 'MM/dd/yyyy p');
  }
  return 'n/a';
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function elapsedTimeToStr(elapsedTime) {
  // retrieves a string representation of the elapsed time in d/h/m/s
  if (elapsedTime) {
    const seconds = Number(elapsedTime);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? `${d}d ` : '';
    const hDisplay = h > 0 ? `${h}h ` : '';
    const mDisplay = m > 0 ? `${m}m ` : '';
    const sDisplay = s > 0 ? `${s}s ` : '';
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }
  return 'N/A';
}

export function getElapsedSeconds(unixTime) {
  // retrieves a second representation of elapsed time since the provided unix time
  if (unixTime) {
    return Date.now() / 1000 - unixTime;
  }
  return -1;
}
