import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@material-ui/core';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import RackTDsModal from './RackTDsModal';

RackTDsButton.propTypes = {
  rackDetails: PropTypes.object,
  variant: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object
  ]),
  disabled: PropTypes.bool
};

RackTDsButton.defaultProps = {
  rackDetails: {},
  variant: 'contained',
  color: 'primary'
};

export default function RackTDsButton({ variant, color, sx = [], disabled, rackDetails }) {
  const [tdModalOpen, setTDModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (rackDetails.statuses.length > 0) {
      const lastStatus = rackDetails.statuses[rackDetails.statuses.length - 1].metadata.rack_status;
      if (
        lastStatus.details === 'setting flight data values' &&
        lastStatus.status === 'configuring'
      ) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    }
  }, [rackDetails]);

  const openModal = () => {
    setTDModalOpen(true);
  };

  const closeModal = () => {
    setTDModalOpen(false);
  };
  return (
    <>
      <RackTDsModal
        isOpen={tdModalOpen}
        closeHandler={closeModal}
        rackName={rackDetails.name}
        rackDetails={rackDetails}
        loading={loading}
        setLoading={setLoading}
      />

      <Tooltip title="Flight Data Values" placement="top">
        <Button
          data-testid="tds-button"
          onClick={openModal}
          variant={variant}
          sx={{ ...sx }}
          disabled={disabled}
          startIcon={<FlightTakeoffIcon />}
          color={color}
        >
          Flight Data
        </Button>
      </Tooltip>
    </>
  );
}
