import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Typography, Grid, Chip } from '@material-ui/core';
import { elapsedTimeToStr, getElapsedSeconds, fDateTimeSuffix } from '../../../utils/formatTime';
import { RegionLabels } from '../../../utils/regions';
import { RunningStateChip } from '.';

function formatElapsedTime(time) {
  const elapsed = getElapsedSeconds(time);
  return elapsedTimeToStr(elapsed);
}

GeneralInfo.propTypes = {
  rackDetails: PropTypes.object
};

export default function GeneralInfo({ rackDetails }) {
  return (
    <Card>
      <CardHeader title="General" />
      <CardContent sx={{ marginBottom: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" mr={2}>
              Name
            </Typography>
            <Chip label={rackDetails.name} size="small" variant="outlined" color="primary" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" mr={2}>
              Provisioner
            </Typography>
            <Chip
              label={rackDetails.provisioner_username}
              size="small"
              variant="outlined"
              color="primary"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" mr={2}>
              Running
            </Typography>
            <RunningStateChip runningState={rackDetails.running.state} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" mr={2}>
              Team / Identity
            </Typography>
            <Chip
              label={rackDetails.provisioner_identity}
              size="small"
              variant="outlined"
              color="primary"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" mr={2}>
              Created Date
            </Typography>
            <Chip
              label={fDateTimeSuffix(rackDetails.created_date)}
              size="small"
              variant="outlined"
              color="primary"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" mr={2}>
              Last Accessed Time
            </Typography>
            <Chip
              label={formatElapsedTime(rackDetails.last_accessed)}
              size="small"
              variant="outlined"
              color="primary"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" mr={2}>
              Region
            </Typography>
            <Chip
              label={RegionLabels[rackDetails.region]}
              size="small"
              variant="outlined"
              color="primary"
            />
          </Grid>
          {rackDetails.tailNumber !== '' && (
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="overline" mr={2}>
                Tail Number
              </Typography>
              <Chip
                label={rackDetails.tailNumber}
                size="small"
                variant="outlined"
                color="primary"
              />
            </Grid>
          )}
          {rackDetails.xid !== 0 && (
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="overline" mr={2}>
                XID
              </Typography>
              <Chip label={rackDetails.xid} size="small" variant="outlined" color="primary" />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
