import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { SoftwareKitSelect } from '../../loading';

LoadKitTabPanel.propTypes = {
  kitID: PropTypes.string,
  setKitID: PropTypes.func,
  kitIDType: PropTypes.string,
  setKitIDType: PropTypes.func,
  kitType: PropTypes.string,
  setKitType: PropTypes.func,
  kitConfig: PropTypes.object,
  setKitConfig: PropTypes.func
};

export default function LoadKitTabPanel({
  kitID,
  setKitID,
  kitIDType,
  setKitIDType,
  kitType,
  setKitType,
  kitConfig,
  setKitConfig
}) {
  const setKitTypeChange = (event) => {
    setKitType(event.target.value);
  };

  return (
    <FormControl>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SoftwareKitSelect
            kitID={kitID}
            setKitID={setKitID}
            kitIDType={kitIDType}
            setKitIDType={setKitIDType}
            kitConfig={kitConfig}
            setKitConfig={setKitConfig}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Kit Type</FormLabel>
          <RadioGroup value={kitType} onChange={setKitTypeChange}>
            <FormControlLabel value="software" control={<Radio size="small" />} label="Software" />
            <FormControlLabel value="content" control={<Radio size="small" />} label="Content" />
            <FormControlLabel value="media" control={<Radio size="small" />} label="Media" />
          </RadioGroup>
        </Grid>
      </Grid>
    </FormControl>
  );
}
