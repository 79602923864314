// A mapping of regions and their user-friendly labels
export const RegionLabels = {
  'us-west-2': 'US West (Oregon)',
  'ap-south-1': 'Asia Pacific (Mumbai)'
};

export const RegionCoordinates = {
  'us-west-2': { lat: 45.9174667, long: -119.2684488 },
  'ap-south-1': { lat: 19.2425503, long: 72.9667878 }
};
