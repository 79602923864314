import { Icon } from '@iconify/react';
import exclamationIcon from '@iconify/icons-ant-design/exclamation-circle-outlined';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography, Grid } from '@material-ui/core';

// utils
import PropTypes from 'prop-types';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 5),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  textAlign: 'right',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));

function getFailedCount(rackList) {
  let failedCount = 0;
  rackList.forEach((rack) => {
    if (rack.statuses && rack.statuses.length > 0) {
      if (rack.statuses[rack.statuses.length - 1].status === 'interrupted') {
        failedCount += 1;
      }
    }
  });
  return failedCount;
}

FailedRacksCount.propTypes = {
  rackList: PropTypes.object
};

export default function FailedRacksCount({ rackList }) {
  return (
    <RootStyle>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Failed VRacks
          </Typography>
          <Typography variant="h3">{rackList ? getFailedCount(rackList) : 0}</Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="right">
          <IconWrapperStyle>
            <Icon icon={exclamationIcon} width={35} height={35} />
          </IconWrapperStyle>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
