import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip, IconButton } from '@material-ui/core';

InfoToolTip.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string
};

InfoToolTip.defaultProps = {
  placement: 'right'
};

export default function InfoToolTip({ placement, title }) {
  return (
    <Tooltip title={title} placement={placement}>
      <IconButton size="small" color="primary" sx={{ ml: 1 }}>
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}
