/* eslint-disable arrow-body-style */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SoftwareKitSelect } from '../loading';
import LoadComponent from '../loading/LoadComponent';

// parses the rack object for the initial value of the KitID field
function getInitialKitID(rack) {
  if (rack.kit_release_id) {
    return rack.kit_release_id;
  }
  if (rack.kit_srr) {
    return rack.kit_srr;
  }
  return '';
}

LoadInfo.propTypes = {
  updateRack: PropTypes.func,
  rack: PropTypes.object,
  setNextButtonDisabled: PropTypes.func
};

export default function LoadInfo({ rack, updateRack, setNextButtonDisabled }) {
  const initialKitID = getInitialKitID(rack);
  const [kitIDType, setKitIDType] = useState(rack.kit_release_id ? 'kit_release_id' : 'kit_srr');
  const [kitID, setKitID] = useState(initialKitID);
  const [kitConfig, setKitConfig] = useState(rack.kit_config ? rack.kit_config : '');

  const updateRackComponents = (value) => {
    // updates the rack's components property with the new values
    const rackCopy = { ...rack };
    rackCopy.rack.components = value;
    updateRack(rackCopy);
  };

  useEffect(() => {
    const currRack = { ...rack };
    currRack.kit_release_id = undefined;
    currRack.kit_srr = undefined;
    if (kitID !== '') {
      if (kitIDType === 'kit_srr') {
        currRack.kit_srr = kitID;
      } else {
        currRack.kit_release_id = kitID;
      }
      updateRack(currRack);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [kitID, kitIDType]);

  useEffect(() => {
    const currRack = { ...rack };
    currRack.kit_config = kitConfig;
    updateRack(currRack);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [kitConfig]);

  return (
    <Grid container spacing={2} item ml={2} mt={1} mb={2} xs={12}>
      <Grid container item spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h6">Load Software Kit</Typography>
        </Grid>
        <Grid spacing={2} container item sm={12}>
          <SoftwareKitSelect
            kitID={kitID}
            setKitID={setKitID}
            kitIDType={kitIDType}
            setKitIDType={setKitIDType}
            kitConfig={kitConfig}
            setKitConfig={setKitConfig}
            setNextButtonDisabled={setNextButtonDisabled}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h6">Load Additional Components</Typography>
        </Grid>
        <Grid item sm={12}>
          <LoadComponent
            componentsList={rack.components}
            setComponentsList={updateRackComponents}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
