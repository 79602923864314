// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Card } from '@material-ui/core';
import Slider from 'react-slick';
import SliderItem from './SliderItem';
import SliderContent from './SliderContent.json';

const settings = {
  dots: true,
  infinite: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000
};

export default function NewsSlider() {
  return (
    <Card>
      <Slider {...settings} height="100%">
        {SliderContent.map((item, i) => (
          <SliderItem
            key={i}
            overlineText={item.overlineText}
            headerText={item.headerText}
            slideImage={item.slideImage}
            slideText={item.slideText}
            slideLink={item.slideLink}
            slideLinkText={item.slideLinkText}
            slideListItems={item.slideListItems}
          />
        ))}
      </Slider>
    </Card>
  );
}
