import { styled } from '@material-ui/core/styles';
import { Box, Link, Typography, Avatar, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AccountPopover from '../../../layouts/dashboard/AccountPopover';
import { GetFullName } from '../../../utils/authentication';
import { signOut } from './amplify';

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  };
}

function getAirline() {
  const payload = localStorage.getItem('payload');
  if (payload) {
    const payloadParsed = JSON.parse(payload);
    return payloadParsed.pac_tenant_name;
  }
  return '';
}

LoginBox.propTypes = {
  popover: PropTypes.bool
};

export default function LoginBox({ popover }) {
  const jwtPayload = useSelector((state) => state.jwtPayload.value);

  const [fullName, setFullName] = useState(' ');
  const [airline, setAirline] = useState('');

  useEffect(() => {
    setFullName(GetFullName());
    setAirline(getAirline());
  }, [jwtPayload]);

  return (
    <div>
      {!popover ? (
        <Box sx={{ mb: 5, mx: 2.5 }}>
          <Link underline="none" rel="noreferrer">
            <AccountStyle>
              <Avatar {...stringAvatar(fullName)} />
              <Box sx={{ ml: 1 }}>
                <Typography variant="subtitle2" sx={{ paddingLeft: 1, color: 'text.primary' }}>
                  {fullName}
                </Typography>
                <Typography variant="body2" sx={{ paddingLeft: 1, color: 'text.secondary' }}>
                  {airline}
                </Typography>
                <Button onClick={signOut} sx={{ paddingTop: 0 }}>
                  Logout
                </Button>
              </Box>
            </AccountStyle>
          </Link>
        </Box>
      ) : (
        <AccountPopover
          account={{
            username: fullName,
            airlineName: airline,
            condensedName: stringAvatar(fullName)
          }}
        />
      )}
    </div>
  );
}
