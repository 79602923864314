import { createSlice } from '@reduxjs/toolkit';

export const identitySlice = createSlice({
  name: 'identity',
  initialState: {
    value: ''
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { set } = identitySlice.actions;

export default identitySlice.reducer;
