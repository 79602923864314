import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack
} from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutomaticRackStop from './AutomaticRackStop';
import RegionSelect from './RegionSelect';
import RackName from './RackName';

const styles = {
  advancedSettings: {
    paddingLeft: 0
  }
};

VRackInfo.propTypes = {
  updateRack: PropTypes.func,
  updateRackName: PropTypes.func,
  setNextButtonDisabled: PropTypes.func,
  rack: PropTypes.object,
  rackName: PropTypes.string
};

export default function VRackInfo({
  rack,
  rackName,
  updateRack,
  updateRackName,
  setNextButtonDisabled
}) {
  const [isRackNameValid, setIsRackNameValid] = useState(false);
  const [isRegionValid, setIsRegionValid] = useState(false);

  const handleRackNameValidationChange = (isValid) => {
    setIsRackNameValid(isValid);
  };

  const handleRegionValidationChange = (isValid) => {
    setIsRegionValid(isValid);
  };

  useEffect(() => {
    setNextButtonDisabled(!(isRackNameValid && isRegionValid));
  }, [isRackNameValid, isRegionValid, setNextButtonDisabled]);

  useEffect(() => {
    setNextButtonDisabled(!(isRackNameValid && isRegionValid));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Grid
      data-testid="vrack-info"
      container
      ml={0}
      spacing={2}
      rowSpacing={3}
      mt={1}
      mb={2}
      item
      xs={12}
    >
      <Grid container item sm={12}>
        <RegionSelect
          rack={rack}
          updateRack={updateRack}
          handleRegionValidationChange={handleRegionValidationChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <RackName
          rackName={rackName}
          updateRackName={updateRackName}
          handleRackNameValidationChange={handleRackNameValidationChange}
        />
      </Grid>
      <Grid item sm={12}>
        <Accordion>
          <AccordionSummary sx={styles.advancedSettings} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Advanced Settings</Typography>
          </AccordionSummary>
          <AccordionDetails sx={styles.advancedSettings}>
            <Stack spacing={3}>
              <AutomaticRackStop rack={rack} updateRack={updateRack} />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
