/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Container, Grid, Typography, Button, Stack } from '@material-ui/core';
import TeamsTable from '../components/admin/team/TeamsTable';
import Page from '../components/Page';
import TeamDetailsModal from '../components/admin/team/TeamDetailsModal';
import AddTeamDialog from '../components/admin/team/AddTeamDialog';
import { getIDToken } from '../components/authentication/login/amplify';

export default function Teams() {
  const [teamList, setTeamList] = useState([]);

  const [teamDetailsModal, setTeamDetailsModalOpen] = useState(false);
  const [addTeamModal, setAddTeamModalOpen] = useState(false);
  const [modalTeam, setModalTeam] = useState('');
  const identity = useSelector((state) => state.identity.value);

  useEffect(() => {
    // initial call to get racklist when identity is updated
    updateTeams();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [identity]);

  const updateTeams = async () => {
    // initial call to get racklist when identity is updated
    const jwt = await getIDToken();
    if (jwt) {
      fetchTeams(jwt, identity);
    }
  };

  const fetchTeams = (jwt, identity) => {
    if (identity !== '') {
      axios
        .get(`${process.env.REACT_APP_API}/admin/teams`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
        .then((response) => {
          setTeamList(response.data);
          return response.data;
        })
        .catch((error) => {
          console.error('failed to get list of teams ', error.message);
        });
    }
  };

  return (
    <Page title="Admin | Teams | Virtual Racks">
      <TeamDetailsModal
        team={modalTeam}
        isOpen={teamDetailsModal}
        closeHandler={() => {
          setTeamDetailsModalOpen(false);
        }}
      />
      <AddTeamDialog
        isOpen={addTeamModal}
        updateTeams={updateTeams}
        closeHandler={() => {
          setAddTeamModalOpen(false);
        }}
      />
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Teams</Typography>
              <Button
                onClick={() => {
                  setAddTeamModalOpen(true);
                }}
                variant="contained"
              >
                New Team
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TeamsTable
              updateTeams={updateTeams}
              openModal={setTeamDetailsModalOpen}
              setModalTeam={setModalTeam}
              teamsList={teamList}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
