import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  color: PropTypes.string
};

const getLogoAsset = (color) => {
  if (color === 'white') {
    return '/static/vrack-logo-white.svg';
  }
  return '/static/vrack-logo.svg';
};

export default function Logo({ sx, color }) {
  return (
    <Box component="img" src={getLogoAsset(color)} sx={{ width: '100%', height: 50, ...sx }} />
  );
}
