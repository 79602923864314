import PropTypes from 'prop-types';
// material
import { Card, CardContent, Typography, Tooltip, Stack, IconButton } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import palette from '../../../theme/palette';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import { getConnectivityObject, getLastCheckedText } from './Connectivity';
import useVrackApi from '../../../hooks/useVrackApi';

const tooltipText = 'enables public internet / cell modem connectivity in the rack';
const tooltipTextUnavailable =
  'rack was created without simulated cell modem enabled prior to always being available';
const connectivityType = 'cellmodem';

InternetConnectivity.propTypes = {
  rackName: PropTypes.string,
  connectivity: PropTypes.object
};

InternetConnectivity.defaultProps = {
  connectivity: { cellmodem: { state: 'disabled' } }
};

export default function InternetConnectivity({ rackName, connectivity }) {
  const [isConfiguring, setIsConfiguring] = useState(false);
  const identity = useSelector((state) => state.identity.value);
  const { fetchData: vrackApiSetConnectivity } = useVrackApi('post', '/racks/connectivity');
  const dispatch = useDispatch();

  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));
  // checks if connectivity is unavailable. this means the cell modem wasn't enabled
  const isUnavailable = () => getConnectivityObject(connectivity, connectivityType).state === '';
  // checks if connectivity button should be disabled. should be disabled if cell modem is unavailable or if configuring
  const isButtonDisabled = () => (isUnavailable() ? true : isConfiguring);

  useEffect(() => {
    const conn = getConnectivityObject(connectivity, connectivityType);
    // check connectivity state if it's configuring
    setIsConfiguring(conn.state === 'configuring');
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [connectivity]);

  const setRackConnectivityHandler = () => {
    const conn = getConnectivityObject(connectivity, connectivityType);
    const enabled = conn.state !== 'enabled';

    const bodyData = {
      environment: 'aws',
      identity,
      releaseName: rackName,
      type: 'cellmodem',
      enabled
    };

    vrackApiSetConnectivity({ data: bodyData })
      .then(() => {
        setIsConfiguring(true);
        openSnackbar({
          message: `'successfully sent request to set rack connectivity'`
        });
      })
      .catch((error) => {
        console.error('failed to set rack connectivity', error.response.data.message);
        openSnackbar({
          message: `${error.response.data.message}`,
          severity: 'error'
        });
      });
  };

  const getColor = () => {
    const conn = getConnectivityObject(connectivity, connectivityType);
    // get the appropriate color based on connectivity state
    if (conn.state === 'configuring' || isConfiguring) {
      return palette.warning;
    }
    if (conn.state === 'enabled') {
      return palette.success;
    }
    if (conn.state === 'failed' || conn.state === 'cancelled') {
      return palette.error;
    }
    if (conn.state === 'disabled') {
      return palette.primary;
    }
    return palette.secondary;
  };

  const getConnectivityState = () => {
    const conn = getConnectivityObject(connectivity, connectivityType);
    // returns the appropriate connectivity state to show
    if (isConfiguring) {
      return 'configuring';
    }
    if (connectivity) {
      if (isUnavailable()) {
        return 'not provisioned';
      }
      return conn.state;
    }
    return '';
  };

  const RootStyle = styled(Card)(() => ({
    boxShadow: 'none',
    color: getColor().contrastText,
    backgroundColor: isUnavailable() ? getColor().main : getColor().darker
  }));

  return (
    <RootStyle>
      <CardContent sx={{ marginY: 1 }}>
        <Stack direction="row" spacing={3}>
          <Tooltip title={isUnavailable() ? tooltipTextUnavailable : tooltipText}>
            <span>
              <IconButton
                disabled={isButtonDisabled()}
                size="large"
                sx={{
                  border: 'solid 1px',
                  display: isUnavailable() ? 'none' : '',
                  borderColor: getColor().light,
                  height: 50
                }}
                onClick={setRackConnectivityHandler}
              >
                <PowerSettingsNewOutlinedIcon htmlColor={getColor().light} />
              </IconButton>
            </span>
          </Tooltip>

          <Stack>
            <Typography variant="h5" sx={{ color: isUnavailable() ? getColor().light : 'white' }}>
              Cellular Network
            </Typography>
            <Typography variant="overline" sx={{ fontWeight: 100 }}>
              {getConnectivityState()}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            right: 10,
            bottom: 10,
            fontSize: '8pt',
            color: isUnavailable() ? getColor().light : 'lightgrey'
          }}
        >
          {getLastCheckedText(connectivity, connectivityType)}
        </Typography>
        <SensorsOutlinedIcon
          sx={{
            fontSize: 120,
            position: 'absolute',
            zIndex: -1,
            right: 0,
            top: 0,
            opacity: 0.5
          }}
          htmlColor={getColor().dark}
        />
      </CardContent>
    </RootStyle>
  );
}
