import PropTypes from 'prop-types';
// material
import {
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  IconButton,
  Stack,
  Tooltip
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import TvIcon from '@mui/icons-material/Tv';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import BetaChip from '../../common/BetaChip';
import { useIsFeatureFlagEnabled } from '../../../utils/FeatureFlags';

LRUInfo.propTypes = {
  rackDetails: PropTypes.object
};

export default function LRUInfo({ rackDetails }) {
  const isWebrtcEnabled = useIsFeatureFlagEnabled('webrtc');

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="LRUs" />
      <CardContent>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>LRU</TableCell>
                <TableCell align="right">IP</TableCell>
                <TableCell align="right">Unit</TableCell>
                <TableCell align="right">Master LRU</TableCell>
                <TableCell align="right">Remote View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rackDetails.lrus
                ? rackDetails.lrus.map((row, i) => (
                    <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row.type.toUpperCase()}
                        {row.type === 'smartmonitor' ? <BetaChip sx={{ ml: 1 }} /> : null}
                      </TableCell>
                      <TableCell align="right">{row.ip}</TableCell>
                      <TableCell align="right">{row.unit}</TableCell>
                      <TableCell align="right">{row.is_master.toString()}</TableCell>
                      <TableCell align="right">
                        {row.type === 'smartmonitor' ? (
                          <Stack direction="row" justifyContent="flex-end">
                            <Tooltip title="connect using vnc">
                              <IconButton
                                variant="contained"
                                component={Link}
                                to={`/vsm/${rackDetails.name}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <TvIcon />
                              </IconButton>
                            </Tooltip>
                            {isWebrtcEnabled && (
                              <Tooltip title="connect using webrtc">
                                <IconButton
                                  variant="contained"
                                  component={Link}
                                  to={`/webrtc/${rackDetails.name}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <ConnectedTvIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Stack>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))
                : [0, 1].map((_, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
