import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// material
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Skeleton
} from '@material-ui/core';
import axios from 'axios';
import BuildTableRow from './BuildTableRows';
import Scrollbar from '../../Scrollbar';
import { getIDToken } from '../../authentication/login/amplify';

BuildSelect.propTypes = {
  buildseries: PropTypes.string,
  updateKitID: PropTypes.func,
  updateKitIDType: PropTypes.func,
  closeModal: PropTypes.func
};

BuildSelect.defaultProps = {
  buildseries: null
};

export default function BuildSelect({ buildseries, updateKitID, updateKitIDType, closeModal }) {
  const [builds, setBuilds] = useState([]);
  const [buildLoaded, setBuildLoaded] = useState(false);

  const getTableBody = () => {
    // if build is not yet loaded, proceed to render skeleton data
    if (!buildLoaded) {
      return [0, 1, 2, 3].map((_, index) => (
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            <Skeleton />
          </TableCell>
          <TableCell align="right">
            <Skeleton />
          </TableCell>
          <TableCell align="right">
            <Skeleton />
          </TableCell>
          <TableCell align="right">
            <Skeleton />
          </TableCell>
        </TableRow>
      ));
    }

    // if build is loaded and the total length of builds is 0, render the not found body
    if (buildLoaded && builds.length === 0) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
            <Paper>
              <Typography gutterBottom align="center" variant="subtitle1">
                Builds Not found
              </Typography>
              <Typography variant="body2" align="center">
                No builds found for the selected program that are usable on Virtual Rack
              </Typography>
            </Paper>
          </TableCell>
        </TableRow>
      );
    }

    // otherwise, assume build data exists and proceed to render table contents
    return builds.map((build, i) => (
      <BuildTableRow
        key={i}
        row={build}
        closeModal={closeModal}
        updateKitIDType={updateKitIDType}
        updateKitID={updateKitID}
      />
    ));
  };

  useEffect(() => {
    const fetchBuilds = async () => {
      if (buildseries !== '') {
        // Proceed to fetch all of the airlines stored
        console.debug(`Fetching available builds for ${buildseries}`);
        const jwt = await getIDToken();
        if (jwt) {
          axios({
            method: 'get',
            url: `${process.env.REACT_APP_API}/scmdb/programs/${buildseries}/builds`,
            headers: { Authorization: `Bearer ${jwt}` }
          })
            .then((response) => {
              console.debug(`successfully fetched builds: ${JSON.stringify(response.data)}`);
              response.data.sort((a, b) => -1 * a.ID.localeCompare(b.ID));
              // remove any builds that do not have a software kit specified
              const validBuilds = response.data.filter(
                (build) => build.loadables.software.length > 0
              );
              setBuilds(validBuilds);
              setBuildLoaded(true);
            })
            .catch(() => {});
        } else {
          console.error('JWT has expired, cannot add role to team');
        }
      } else {
        // empty out builds table if build series was reset
        setBuilds([]);
      }
    };

    setBuildLoaded(false);
    setBuilds([]);
    fetchBuilds();
  }, [buildseries]);

  return (
    <Scrollbar sx={{ maxHeight: 500 }}>
      <TableContainer>
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Build ID</TableCell>
              <TableCell align="right">System</TableCell>
              <TableCell align="right">Build Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{getTableBody()}</TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
