/* eslint-disable jsx-a11y/media-has-caption */
import { Box, IconButton } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import MicIcon from '@mui/icons-material/Mic';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InfoIcon from '@mui/icons-material/Info';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import CloseIcon from '@mui/icons-material/Close';
import { connectWebrtc } from './js/app';

import './css/style.css';
import './css/controls.css';
import './js/adb';
import './js/cf_webrtc';

export default function VSM() {
  const { rackname } = useParams();

  useEffect(() => {
    connectWebrtc(rackname);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Box data-testid="vsm" id="vsm-body" sx={{ height: '100%' }}>
      <div>
        <div id="loader" />
        <div id="error-message-div">
          <h3 id="error-message" className="hidden">
            <span className="material-icons close-btn">close</span>
          </h3>
        </div>
        <section id="device-connection">
          <audio id="device-audio" />
          <div id="controls-and-displays">
            <div id="control-panel-default-buttons" className="control-panel-column">
              <IconButton disabled id="power_btn" aria-label="power_btn">
                <PowerSettingsNewIcon />
              </IconButton>
              <IconButton id="bluetooth-modal-button" aria-label="bluetooth">
                <BluetoothIcon />
              </IconButton>
              <IconButton id="back_btn" aria-label="back_btn">
                <ArrowBackIcon />
              </IconButton>
              <IconButton id="home_btn" aria-label="home_btn">
                <HomeIcon />
              </IconButton>
              <IconButton id="menu_btn" aria-label="menu_btn">
                <MenuIcon />
              </IconButton>
              <IconButton id="rotate_left_btn" aria-label="rotate_left_btn">
                <RotateLeftIcon />
              </IconButton>
              <IconButton id="rotate_right_btn" aria-label="rotate_right_btn">
                <RotateRightIcon />
              </IconButton>
              <IconButton id="volume_up_btn" aria-label="volume_up_btn">
                <VolumeUpIcon />
              </IconButton>
              <IconButton id="volume_down_btn" aria-label="volume_down_btn">
                <VolumeDownIcon />
              </IconButton>
              <IconButton id="volume_off_btn" aria-label="volume_off_btn">
                <VolumeOffIcon />
              </IconButton>
              <IconButton id="camera_off_btn" aria-label="camera_off_btn">
                <NoPhotographyIcon />
              </IconButton>
              <IconButton id="record_video_btn" aria-label="record_video_btn">
                <RadioButtonCheckedIcon />
              </IconButton>
              <IconButton id="mic_btn" aria-label="mic_btn">
                <MicIcon />
              </IconButton>
              <IconButton id="location-modal-button" aria-label="location-modal-button">
                <LocationOnIcon />
              </IconButton>
              <IconButton id="device-details-button" aria-label="device-details-button">
                <InfoIcon />
              </IconButton>
              <IconButton id="rotation-modal-button" aria-label="rotation-modal-button">
                <ScreenRotationIcon />
              </IconButton>
            </div>
            <div id="control-panel-custom-buttons" className="control-panel-column" />
            <div id="device-displays" tabIndex="-1" />
          </div>
        </section>
        <div id="device-details-modal" className="modal">
          <div id="device-details-modal-header" className="modal-header">
            <h2>Device Details</h2>
            <IconButton id="device-details-close" aria-label="rotation-modal-button">
              <CloseIcon />
            </IconButton>
          </div>
          <hr />
          <h3>Hardware Configuration</h3>
          <span id="device-details-hardware">unknown</span>
        </div>
        <div id="bluetooth-modal" className="modal-wrapper">
          <div id="bluetooth-prompt" className="modal">
            <div id="bluetooth-prompt-header" className="modal-header">
              <h2>Bluetooth</h2>
              <IconButton id="bluetooth-prompt-close" aria-label="rotation-modal-button">
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <div id="bluetooth-prompt-text" className="bluetooth-text">
                We have enabled a BT Wizard to simplify adding a
                <br />
                bluetooth device.
                <br />
                Alternatively, you can enter the BT Console if you
                <br />
                want to exercise full control.
              </div>
              <br />
              <div className="bluetooth-button">
                <button
                  type="button"
                  id="bluetooth-prompt-wizard"
                  title="Start Wizard"
                  className="modal-button-highlight"
                >
                  Start Wizard
                </button>
                <button
                  type="button"
                  id="bluetooth-prompt-list"
                  title="Device List"
                  className="modal-button"
                >
                  Device List
                </button>
                <button
                  type="button"
                  id="bluetooth-prompt-console"
                  title="BT Console"
                  className="modal-button"
                >
                  BT Console
                </button>
              </div>
            </div>
          </div>
          <div id="bluetooth-wizard" className="modal">
            <div id="bluetooth-wizard-modal-header" className="modal-header">
              <h2>BT Wizard</h2>
              <button
                type="button"
                id="bluetooth-wizard-close"
                title="Close"
                className="material-icons modal-close"
              >
                close
              </button>
            </div>
            <div>
              <div className="bluetooth-text-field">
                <input type="text" id="bluetooth-wizard-name" placeholder="Device Name" />
              </div>
              <div className="bluetooth-drop-down">
                <select id="bluetooth-wizard-type" required>
                  <option value="beacon">Beacon</option>
                  <option value="beacon_swarm">Beacon Swarm</option>
                  <option value="keyboard">Keyboard</option>
                  <option value="remote_loopback">Remote Loopback</option>
                  <option value="scripted_beacon">Scripted Beacon</option>
                </select>
              </div>
              <div className="bluetooth-text-field">
                <input type="text" id="bluetooth-wizard-mac" placeholder="Device MAC" required />
              </div>
              <div className="bluetooth-button">
                <button
                  type="button"
                  id="bluetooth-wizard-device"
                  title="Add Device"
                  className="modal-button-highlight"
                  disabled
                >
                  Add Device
                </button>
                <button
                  type="button"
                  id="bluetooth-wizard-cancel"
                  title="Cancel"
                  className="modal-button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div id="bluetooth-wizard-confirm" className="modal">
            <div id="bluetooth-wizard-confirm-header" className="modal-header">
              <h2>BT Wizard</h2>
              <button
                type="button"
                id="bluetooth-wizard-confirm-close"
                title="Close"
                className="material-icons modal-close"
              >
                close
              </button>
            </div>
            <div id="bluetooth-wizard-text" className="bluetooth-text">
              Device added. See device details below.
            </div>
            <br />
            <div className="bluetooth-text">
              <p>
                Name: <b>GKeyboard</b>
              </p>
              <p>
                Type: <b>Keyboard</b>
              </p>
              <p>
                MAC Addr: <b>be:ac:01:55:00:03</b>
              </p>
            </div>
            <div className="bluetooth-button">
              <button
                type="button"
                id="bluetooth-wizard-another"
                title="Add Another"
                className="modal-button-highlight"
              >
                Add Another
              </button>
            </div>
          </div>
          <div id="bluetooth-list" className="modal">
            <div id="bluetooth-list-header" className="modal-header">
              <h2>Device List</h2>
              <button
                type="button"
                id="bluetooth-list-close"
                title="Close"
                className="material-icons modal-close"
              >
                close
              </button>
            </div>
            <div className="bluetooth-text">
              <div>
                <button
                  type="button"
                  title="Delete"
                  data-device-id="delete"
                  className="bluetooth-list-trash material-icons"
                >
                  delete
                </button>
                GKeyboard | Keyboard | be:ac:01:55:00:03
              </div>
              <div>
                <button
                  type="button"
                  title="Delete"
                  data-device-id="delete"
                  className="bluetooth-list-trash material-icons"
                >
                  delete
                </button>
                GHeadphones | Audio | dc:fa:32:00:55:02
              </div>
            </div>
          </div>
          <div id="bluetooth-console" className="modal">
            <div id="bluetooth-console-modal-header" className="modal-header">
              <h2>BT Console</h2>
              <button
                type="button"
                id="bluetooth-console-close"
                title="Close"
                className="material-icons modal-close"
              >
                close
              </button>
            </div>
            <div>
              <div colSpan="2">
                <textarea id="bluetooth-console-view" readOnly rows="10" cols="60" />
              </div>
              <div width="1">
                <p id="bluetooth-console-cmd-label">Command:</p>
              </div>
              <div width="100">
                <input id="bluetooth-console-input" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div id="location-modal" className="modal-wrapper">
          <div id="location-prompt-modal" className="modal">
            <div id="location-prompt-modal-header" className="modal-header">
              <h2>Location</h2>
              <button
                type="button"
                id="location-prompt-modal-close"
                title="Close"
                className="material-icons modal-close"
              >
                close
              </button>
            </div>
            <div>
              <div id="location-prompt-text" className="location-text">
                <div className="location-button">
                  <button
                    type="button"
                    id="location-set-wizard"
                    title="Set location"
                    className="modal-button-highlight"
                  >
                    Set Location
                  </button>
                  <button
                    type="button"
                    id="locations-import-wizard"
                    title="Import locations"
                    className="modal-button"
                  >
                    Import Locations
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="location-set-modal" className="modal">
          <div id="location-set-modal-header" className="modal-header">
            <h2>Set Location</h2>
            <button
              type="button"
              id="location-set-modal-close"
              title="Close"
              className="material-icons modal-close"
            >
              close
            </button>
          </div>
          <div>
            <div className="location-text-field">
              <input
                type="number"
                step="0.01"
                min="-180"
                max="180"
                defaultValue="-122.083"
                id="location-set-longitude"
                placeholder="Longitude"
                required
              />
            </div>
            <div className="location-text-field">
              <input
                type="number"
                step="0.01"
                min="-90"
                max="90"
                defaultValue="37.415"
                id="location-set-latitude"
                placeholder="Latitude"
                required
              />
            </div>
            <div className="location-text-field">
              <input
                type="number"
                step="0.01"
                defaultValue="0.0"
                id="location-set-altitude"
                placeholder="Elevation"
                required
              />
            </div>
            <div className="location-button">
              <button
                type="button"
                id="location-set-confirm"
                title="Confirm Location"
                className="modal-button-highlight"
              >
                Update Location
              </button>
              <button
                type="button"
                id="location-set-cancel"
                title="Cancel"
                className="modal-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div id="locations-import-modal" className="modal">
          <div id="locations-import-modal-header" className="modal-header">
            <h2>Import Locations</h2>
            <button
              type="button"
              id="locations-import-modal-close"
              title="Close"
              className="material-icons modal-close"
            >
              close
            </button>
          </div>
          <div className="location-text">
            <input type="file" id="locations_select_file" accept=".gpx, .kml" />
            <button
              type="button"
              id="locations-send-btn"
              title="Send Locations"
              className="modal-button-highlight"
            >
              Send Locations
            </button>
          </div>
        </div>
        <div id="rotation-modal" className="modal">
          <div id="rotation-modal-header" className="modal-header">
            <h2>Rotation sensors</h2>
            <button
              type="button"
              id="rotation-modal-close"
              title="Close"
              className="material-icons modal-close"
            >
              close
            </button>
          </div>
          <hr />
          <h3>Rotate the device</h3>
          <span id="rotation-bar">
            <div className="rotation-slider">
              X
              <input
                className="rotation-slider-range"
                type="range"
                defaultValue="0"
                min="-180"
                max="180"
                step="0.1"
              />
              <span className="rotation-slider-value">0</span>
            </div>
            <br />
            <div className="rotation-slider">
              Y
              <input
                className="rotation-slider-range"
                type="range"
                defaultValue="0"
                min="-180"
                max="180"
                step="0.1"
              />
              <span className="rotation-slider-value">0</span>
            </div>
            <br />
            <div className="rotation-slider">
              Z
              <input
                className="rotation-slider-range"
                type="range"
                defaultValue="0"
                min="-180"
                max="180"
                step="0.1"
              />
              <span className="rotation-slider-value">0</span>
            </div>
            <br />
          </span>
          <div className="fixed-orientation">
            <button type="button" id="left-position-button" title="left" className="material-icons">
              crop_landscape
            </button>
            <button
              type="button"
              id="upright-position-button"
              title="upright"
              className="material-icons"
            >
              crop_portrait
            </button>
            <button
              type="button"
              id="right-position-button"
              title="right"
              className="material-icons"
            >
              crop_landscape
            </button>
            <button
              type="button"
              id="upside-position-button"
              title="upside down"
              className="material-icons"
            >
              crop_portrait
            </button>
          </div>
          <div className="sensors">
            <div id="accelerometer">
              Accelerometer: <span id="accelerometer-value">0.00 9.81 0.00</span>
            </div>
            <div id="magnetometer">
              Magnetometer: <span id="magnetometer-value">0 5.9 -48.4</span>
            </div>
            <div id="gyroscope">
              Gyroscope: <span id="gyroscope-value">0.00 0.00 0.00</span>
            </div>
          </div>
        </div>
        <template id="display-template">
          <div className="device-display">
            <div className="device-display-info" />
            <div className="device-video-container">
              <video autoPlay muted className="device-display-video" />
            </div>
          </div>
        </template>
      </div>
    </Box>
  );
}
