import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import { getIDToken } from '../../authentication/login/amplify';

AssignRoleToTeamDialog.propTypes = {
  isOpen: PropTypes.bool,
  team: PropTypes.string,
  closeHandler: PropTypes.func,
  updateRoles: PropTypes.func
};

export default function AssignRoleToTeamDialog({ isOpen, closeHandler, team, updateRoles }) {
  const assignRoleToTeam = async (role, teamName) => {
    console.debug(`Assigning role: ${role} to team: ${team}`);
    const reqBody = {
      team: teamName,
      roles: [role]
    };
    const jwt = await getIDToken();
    if (jwt) {
      // proceed to assign role to team
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/admin/teams/roles`,
        data: reqBody,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
      })
        .then((response) => {
          console.debug(`successfully added role to team: ${JSON.stringify(response.data)}`);
          openSnackbar({ message: 'Role added to team successfully!' });
          updateRoles();
          return response.data;
        })
        .catch(() => {
          openSnackbar({ message: 'Failed to add role to team', severity: 'error' });
        });
    } else {
      console.error('JWT has expired, cannot add role to team');
    }
  };
  const [role, setRole] = useState('');
  const handleUpdateRole = (event) => {
    setRole(event.target.value);
  };
  const handleClose = () => {
    closeHandler();
  };
  const handleAddRole = () => {
    assignRoleToTeam(role, team);
    closeHandler();
  };
  const dispatch = useDispatch();
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Add Role To Team</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the name of the role you wish to add to the team
        </DialogContentText>
        <TextField
          onChange={handleUpdateRole}
          required
          autoFocus
          margin="dense"
          id="role"
          label="Role"
          type="role"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleAddRole}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}
