/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
// material
import { Card, CardHeader, Grid, TextField, Button } from '@material-ui/core';
// components
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import { getIDToken } from '../../authentication/login/amplify';

async function getMOTD() {
  const jwt = await getIDToken();
  if (jwt) {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}/racks/motd/none`,
      headers: { Authorization: `Bearer ${jwt}` }
    });
  }
  return Promise.reject(new Error('missing jwt'));
}

async function updateMOTD(motd) {
  const jwt = await getIDToken();
  if (jwt) {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_API}/admin/motd`,
      data: { motd },
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
    });
  }
  return Promise.reject(new Error('missing jwt'));
}

export default function MOTD() {
  const [motd, setMOTD] = useState('');
  const dispatch = useDispatch();
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  const onClickUpdate = () => {
    updateMOTD(motd)
      .then(() => {
        console.debug('successfully updated motd');
        openSnackbar({ message: 'updated motd successfully!' });
      })
      .catch((err) => {
        console.error('failed to update motd', err);
        openSnackbar({ message: 'Failed to update MOTD', severity: 'error' });
      });
  };

  const onChangeMotd = (event) => {
    setMOTD(event.target.value);
  };

  useEffect(() => {
    // initial call to get motd
    getMOTD()
      .then((response) => {
        setMOTD(response.data.motd);
      })
      .catch((err) => {
        console.error('failed to get motd', err);
      });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Card data-testid="motd">
      <CardHeader
        title="Message of the Day (MOTD)"
        subheader="Update the message of the day that shows up on the Gateway VMs"
      />
      <Grid container p={3} pr={5} rowSpacing={2}>
        <Grid item xs={12}>
          <TextField fullWidth multiline rows={5} defaultValue={motd} onChange={onChangeMotd} />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={onClickUpdate}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
