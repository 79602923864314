import Raptorize from './components/raptorize';
import Router from './routes';
import ThemeConfig from './theme';
import ScrollToTop from './components/ScrollToTop';
import { AmplifySession } from './components/authentication/login/amplify';
import RootSnackbar from './utils/RootSnackbar';
import useFeatureFlags from './utils/FeatureFlags';
import useDataDogRUM from './utils/DataDog';

export default function App() {
  useFeatureFlags();
  useDataDogRUM();

  return (
    <ThemeConfig>
      <AmplifySession />
      <ScrollToTop />
      <Router />
      <Raptorize repeat />
      <RootSnackbar />
    </ThemeConfig>
  );
}
