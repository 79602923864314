import { useState } from 'react';
import { Alert, Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = {
  snackbar: { position: 'static' },
  alert: { fontSize: '10pt' },
  alert_links: { color: 'white' }
};

DismissableAlert.propTypes = {
  children: PropTypes.array,
  disabled: PropTypes.bool
};

export default function DismissableAlert(props) {
  const { children, disabled } = props;
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  if (disabled) {
    return null;
  }
  return (
    <Snackbar open={open} sx={styles.snackbar}>
      <Alert {...props} onClose={handleClose} sx={{ '&': styles.alert, '& a': styles.alert_links }}>
        {children}
      </Alert>
    </Snackbar>
  );
}
