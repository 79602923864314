import PropTypes from 'prop-types';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Box, Link } from '@material-ui/core';
import { fDateTimeSuffix } from '../../../../utils/formatTime';

const styles = {
  table: {
    '.MuiDataGrid-cellContent': {
      overflow: 'auto',
      textOverflow: 'inherit'
    }
  }
};

const columns = [
  {
    field: 'srr',
    renderCell: (params) =>
      params.value ? (
        <Link
          underline="hover"
          href={`http://synergy.mascorp.com:8600/change/servlet/com.continuus.webpt.servlet.PTweb?ACTION_FLAG=frameset_form&TEMPLATE_FLAG=ProblemReportView&database=%2Fdata%2Fccmdb%2Fpanasonic&role=User&problem_number=LF%23${params.value}`}
          target="_blank"
          rel="noreferrer"
        >
          {params.value}
        </Link>
      ) : (
        'n/a'
      ),
    headerName: 'SRR',
    description: 'the SRR of the kit from SCMDB',
    flex: 0.3
  },
  {
    field: 'fileId',
    headerName: 'File ID',
    description: 'the release file ID of the kit from SCMDB',
    flex: 0.3,
    renderCell: (params) =>
      params.value ? (
        <Link
          underline="hover"
          href={`http://scmdb.mascorp.com/py/release/${params.value}`}
          target="_blank"
          rel="noreferrer"
          sx={{
            overflow: 'auto',
            textOverflow: 'inherit'
          }}
        >
          {params.value}
        </Link>
      ) : (
        'n/a'
      )
  },
  { field: 'type', headerName: 'Type', description: 'the type of kit', flex: 0.3 },
  {
    field: 'loaded_by',
    description: 'the user that initiated the loading operation',
    headerName: 'Loaded By',
    flex: 1
  },
  {
    field: 'date',
    headerName: 'Date Loaded',
    description: 'the date the kit was loaded to the rack',
    flex: 0.5,
    valueGetter: (params) => fDateTimeSuffix(params.value)
  },
  {
    field: 'status',
    headerName: 'Status',
    description: 'the status of the load operation',
    flex: 0.5
  }
];

function getKitLoadingHistory(kitLoadingHistory) {
  // returns a list of kits loaded in order from newest to oldest
  const kitList = [];
  if (kitLoadingHistory != null) {
    kitLoadingHistory.forEach((kitEntry, i) => {
      kitList.push({
        id: i,
        srr: kitEntry.kit.srr,
        fileId: kitEntry.kit.fileId,
        type: kitEntry.kit.type,
        loaded_by: kitEntry.loaded_by,
        date: kitEntry.date,
        status: kitEntry.status
      });
    });
  }
  return kitList.reverse();
}

KitLoadHistory.propTypes = {
  rackDetails: PropTypes.object
};

export default function KitLoadHistory({ rackDetails }) {
  const [pageSize, setPageSize] = useState(5);
  const rows = rackDetails.loading_history
    ? getKitLoadingHistory(rackDetails.loading_history.kits)
    : [];

  return (
    <Box>
      <Typography variant="subtitle2" display="block" my={2}>
        Kits
      </Typography>
      <DataGrid
        getRowId={(row) => row.id}
        rows={rows}
        columns={columns}
        autoHeight
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        disableSelectionOnClick
        density="compact"
        sx={styles.table}
      />
    </Box>
  );
}
