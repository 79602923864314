/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Container, Grid, Typography, Button, Stack } from '@material-ui/core';
import Page from '../components/Page';
import { AddPolicyModal, PolicyTable } from '../components/admin/policy';
import { getIDToken } from '../components/authentication/login/amplify';

export default function Policies() {
  const [policyList, setPolicyList] = useState([]);
  const [addModalOpen, setModalOpen] = useState(false);

  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);
  const identity = useSelector((state) => state.identity.value);

  useEffect(() => {
    updatePolicies(identity);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [identity]);

  // initial call to get racklist when identity is updated
  const updatePolicies = async (identity) => {
    const jwt = await getIDToken();
    if (jwt && identity !== '') {
      axios
        .get(`${process.env.REACT_APP_API}/admin/policies`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
        .then((response) => {
          setPolicyList(response.data);
          return response.data;
        })
        .catch((error) => {
          console.error('failed to get list of policies ', error.message);
        });
    }
  };

  return (
    <Page title="Admin | Policies | Virtual Racks">
      <AddPolicyModal
        updatePolicies={updatePolicies}
        policies={policyList}
        isOpen={addModalOpen}
        closeHandler={closeModal}
      />
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Policies</Typography>
              <Button onClick={openModal} variant="contained">
                New Policy
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <PolicyTable
              updatePolicies={updatePolicies}
              policyList={policyList}
              // setError={setError}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
