import { Link as RouterLink, Outlet } from 'react-router-dom';
import { Stack } from '@material-ui/core';
// material
import DashboardFooter from './dashboard/DashboardFooter';

// components
import Logo from '../components/Logo';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  return (
    <>
      <Stack sx={{ height: '100vh' }} justifyContent="space-between">
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Outlet />
        <DashboardFooter />
      </Stack>
    </>
  );
}
