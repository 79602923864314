/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { dump, load } from 'js-yaml';
// material
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField
} from '@material-ui/core';
// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoToolTip from '../../common/InfoToolTip';
import { useIsFeatureFlagEnabled } from '../../../utils/FeatureFlags';
import { GatewaySize, EnableFlightSimulator, PersistData } from '.';

const scriptPlaceholder = `- apt install -y iperf
- echo "boot" > /boot.txt
- docker run nginx`;

const initScriptDefaultHelperText =
  'These commands run only once during the first time the gateway vm boots up.';

const bootScriptDefaultHelperText = 'These commands run every time the gateway vm reboots.';

function scriptToJson(scripts) {
  // parse string yaml into json object
  try {
    return load(scripts);
  } catch (error) {
    console.error('failed to parse startup script to json', error);
    return null;
  }
}

function scriptToYaml(scripts) {
  // converts script object to yaml
  if (!scripts) {
    return '';
  }
  try {
    return dump(scripts);
  } catch (error) {
    console.error('failed to parse startup script to yaml', error);
    return null;
  }
}

const styles = {
  advancedSettings: {
    paddingLeft: 0
  }
};

GatewayInfo.propTypes = {
  updateRack: PropTypes.func,
  rack: PropTypes.object,
  setNextButtonDisabled: PropTypes.func
};

export default function GatewayInfo({ rack, updateRack, setNextButtonDisabled }) {
  // helper functions to extract values from the rack object for initialization
  const parseInitScriptFromRack = () => scriptToYaml(rack.rack.gateway.initScript);
  const parseBootScriptFromRack = () => scriptToYaml(rack.rack.gateway.bootScript);

  const [initScript, updateInitScript] = useState(parseInitScriptFromRack);
  const [bootScript, updateBootScript] = useState(parseBootScriptFromRack);
  const [initScriptError, updateInitScriptError] = useState(false);
  const [bootScriptError, updateBootScriptError] = useState(false);
  const [initScriptHelperText, updateInitScriptHelperText] = useState(initScriptDefaultHelperText);
  const [bootScriptHelperText, updateBootScriptHelperText] = useState(bootScriptDefaultHelperText);
  const isFlightSimulatorEnabled = useIsFeatureFlagEnabled('flight_simulator');

  const updateRackGateway = (key, value) => {
    // updates the rack object's gateway property with the new values
    const rackCopy = { ...rack };
    rackCopy.rack.gateway[key] = value;
    updateRack(rackCopy);
  };

  const validateScript = (s, type) => {
    const defaultScriptErrorMessage =
      "Invalid script format. Separate each command with a '-' followed by a space";
    const script = scriptToJson(s);
    if (s !== '' && (!script || !s.startsWith('- '))) {
      // failed to parse or script didn't start with a '- ', set error messages on the text boxes
      if (type === 'initScript') {
        updateInitScriptError(true);
        updateInitScriptHelperText(defaultScriptErrorMessage);
      } else {
        updateBootScriptError(true);
        updateBootScriptHelperText(defaultScriptErrorMessage);
      }
      updateRackGateway(type, null);
      return;
    }
    if (s === '') {
      // successfully parsed script
      updateRackGateway(type, null);
    } else {
      updateRackGateway(type, script);
    }
    if (type === 'initScript') {
      updateInitScriptError(false);
      updateInitScriptHelperText(initScriptDefaultHelperText);
    } else {
      updateBootScriptError(false);
      updateBootScriptHelperText(bootScriptDefaultHelperText);
    }
  };

  useEffect(() => {
    validateScript(initScript, 'initScript');
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [initScript]);

  useEffect(() => {
    // update rack object when boot script is updated
    validateScript(bootScript, 'bootScript');
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [bootScript]);

  useEffect(() => {
    validateInputs();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const validateInputs = () => {
    setNextButtonDisabled(false);
  };

  return (
    <Grid data-testid="gateway-settings" container item spacing={2} ml={2} mt={1} mb={2} xs={12}>
      <Grid container item sm={12} spacing={2}>
        <GatewaySize updateRack={updateRack} rack={rack} />
      </Grid>
      {isFlightSimulatorEnabled ? (
        <Grid container item sm={12} spacing={2}>
          <EnableFlightSimulator updateRack={updateRack} rack={rack} />
        </Grid>
      ) : null}
      <Grid item sm={12}>
        <Accordion>
          <AccordionSummary sx={styles.advancedSettings} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Advanced Settings</Typography>
          </AccordionSummary>
          <AccordionDetails sx={styles.advancedSettings}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <PersistData rack={rack} updateRackGateway={updateRackGateway} />
              </Grid>
              <Grid item sm={12}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Startup Scripts
                  <InfoToolTip title="Commands that will run automatically on Gateway VM bootup" />
                </Typography>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="overline" sx={{ marginBottom: 2, display: 'block' }}>
                      Init Script
                    </Typography>
                    <TextField
                      placeholder={scriptPlaceholder}
                      multiline
                      fullWidth
                      rows={5}
                      onChange={(event) => {
                        updateInitScript(event.target.value);
                      }}
                      helperText={initScriptHelperText}
                      error={initScriptError}
                      value={initScript}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="overline" sx={{ marginBottom: 2, display: 'block' }}>
                      Boot Script
                    </Typography>
                    <TextField
                      placeholder={scriptPlaceholder}
                      multiline
                      fullWidth
                      rows={5}
                      onChange={(event) => {
                        updateBootScript(event.target.value);
                      }}
                      error={bootScriptError}
                      helperText={bootScriptHelperText}
                      value={bootScript}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
