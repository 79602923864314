import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  Grid,
  Typography,
  Slider
} from '@material-ui/core';

const styles = {
  sizeContent: { marginLeft: 0 }
};
const cpuMarks = [
  {
    value: 2000,
    label: '2'
  },
  {
    value: 3000,
    label: '3'
  },
  {
    value: 4000,
    label: '4'
  },
  {
    value: 5000,
    label: '5'
  },
  {
    value: 6000,
    label: '6'
  },
  {
    value: 7000,
    label: '7'
  },
  {
    value: 8000,
    label: '8'
  }
];
const memoryMarks = [
  {
    value: 2000,
    label: '2000'
  },
  {
    value: 3000,
    label: '3000'
  },
  {
    value: 4000,
    label: '4000'
  },
  {
    value: 5000,
    label: '5000'
  },
  {
    value: 6000,
    label: '6000'
  },
  {
    value: 7000,
    label: '7000'
  },
  {
    value: 8000,
    label: '8000'
  }
];

const diskMarks = [
  {
    value: 1,
    label: '50'
  },
  {
    value: 2,
    label: '100'
  },
  {
    value: 3,
    label: '500'
  },
  {
    value: 4,
    label: '1000'
  }
];

const diskMarkSizeMap = {
  1: 'small',
  2: 'medium',
  3: 'large',
  4: 'original'
};

const diskSizeMarkMap = {
  small: 1,
  medium: 2,
  large: 3,
  original: 4
};

const resourceSizeMap = {
  small: 2000,
  medium: 4000,
  large: 6000,
  original: 8000
};

const sizes = ['small', 'medium', 'large', 'original', 'custom'];

function parseResourceLimit(rack, resourceType) {
  // parses the resource limit from the rack object depending on the resource type passed
  const resource = rack.rack.gateway.resources.limits[resourceType];
  const resourceVal = parseInt(resource.slice(0, resource.length - 1), 10);
  return resourceVal;
}

GatewaySize.propTypes = {
  rack: PropTypes.object,
  updateRack: PropTypes.func
};

export default function GatewaySize({ rack, updateRack }) {
  const parseCpuFromRack = () => parseResourceLimit(rack, 'cpu');
  const parseMemoryFromRack = () => parseResourceLimit(rack, 'memory');
  const [cpuLimit, updateCPULimit] = useState(parseCpuFromRack);
  const [memoryLimit, updateMemoryLimit] = useState(parseMemoryFromRack);

  const isCustom = () => rack.rack.gateway.size === 'custom';

  const updateRackGateway = (key, value) => {
    // updates the rack object's gateway property with the new values
    const rackCopy = { ...rack };
    rackCopy.rack.gateway[key] = value;
    updateRack(rackCopy);
  };

  useEffect(() => {
    // update rack object when cpu or memory limit is updated
    updateRackGateway('resources', {
      limits: {
        cpu: `${cpuLimit}m`,
        memory: `${memoryLimit}M`
      }
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [cpuLimit, memoryLimit]);

  const setSize = (event) => {
    const s = event.target.value;
    // if not custom, update the disk, memory, and cpu size accordingly
    if (s !== 'custom') {
      const rackCopy = { ...rack };
      rackCopy.rack.gateway.size = s;
      rackCopy.rack.gateway.diskSize = s;
      updateRack(rackCopy);

      updateCPULimit(resourceSizeMap[s]);
      updateMemoryLimit(resourceSizeMap[s]);
    } else {
      updateRackGateway('size', s);
    }
  };

  const getDiskSize = () => diskSizeMarkMap[rack.rack.gateway.diskSize];
  const setDiskSize = (event) => {
    updateRackGateway('diskSize', diskMarkSizeMap[event.target.value]);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">Size</Typography>
      </Grid>
      <Grid container item ml={2} xs={12} rowSpacing={2} sx={styles.sizeContent}>
        <Grid item sm={12}>
          <FormControl>
            <InputLabel id="size-label">Instance Size</InputLabel>
            <Select
              labelId="size-label"
              label="Instance Size"
              value={rack.rack.gateway.size}
              onChange={setSize}
              sx={{ width: 230 }}
            >
              {sizes.map((el, i) => (
                <MenuItem key={i} value={el}>
                  {el} {el === 'small' ? '(default)' : ''}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              The instance size will affect the disk size and resource limits
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ ml: 1 }}>
          <Typography variant="subtitle2">Disk Size (GB)</Typography>
          <Slider
            aria-label="DiskSize"
            sx={{ marginLeft: 1.2, width: '90%', maxWidth: 400 }}
            marks={diskMarks}
            max={diskMarks[diskMarks.length - 1].value}
            min={diskMarks[0].value}
            step={null}
            value={getDiskSize()}
            onChange={setDiskSize}
            disabled={!isCustom()}
            size="small"
          />
          <Typography variant="subtitle2">CPU Limit (# Cores)</Typography>
          <Slider
            aria-label="CPULimit"
            sx={{ marginLeft: 1.2, width: '90%', maxWidth: 400 }}
            marks={cpuMarks}
            max={cpuMarks[cpuMarks.length - 1].value}
            min={cpuMarks[0].value}
            step={null}
            value={cpuLimit}
            onChange={(event) => {
              updateCPULimit(event.target.value);
            }}
            disabled={!isCustom()}
            size="small"
          />
          <Typography variant="subtitle2">Memory Limit (MB)</Typography>
          <Slider
            aria-label="MemoryLimit"
            sx={{ marginLeft: 1.2, width: '90%', maxWidth: 400 }}
            valueLabelDisplay="auto"
            marks={memoryMarks}
            max={memoryMarks[memoryMarks.length - 1].value}
            min={memoryMarks[0].value}
            step={500}
            value={memoryLimit}
            onChange={(event) => {
              updateMemoryLimit(event.target.value);
            }}
            disabled={!isCustom()}
            size="small"
          />
        </Grid>
      </Grid>
    </>
  );
}
