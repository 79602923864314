import { createSlice } from '@reduxjs/toolkit';

export const jwtPayloadSlice = createSlice({
  name: 'jwtPayload',
  initialState: {
    value: {},
    jwt: ''
  },
  reducers: {
    setPayload: (state, action) => {
      state.value = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setPayload } = jwtPayloadSlice.actions;

export default jwtPayloadSlice.reducer;
