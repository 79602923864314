import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

function initializeDataDogRUM() {
  datadogRum.init({
    applicationId: 'b791c357-d34e-45ee-a3a0-2c7fae44d192',
    clientToken: 'pubda5300e132e2ca7d338af9b045f1b26e',
    site: 'datadoghq.com',
    service: 'vracks-web-ui',
    env: process.env.REACT_APP_STAGE,
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [process.env.REACT_APP_API]
  });
}

// this hooks into DataDog's RUM (Real User Monitoring)
export default function useDataDogRUM() {
  useEffect(() => {
    initializeDataDogRUM();
  }, []);
}
