/*
 * Copyright (C) 2022 The Android Open Source Project
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export function loadFile(onloadFunc) {
  const input = document.getElementById('locations_select_file');

  if (!input.files[0]) {
    alert('Please select a file ');
    return;
  }

  const file = input.files[0];
  const fr = new FileReader();
  fr.addEventListener('error', () => {
    alert('Error occurred reading file');
  });

  fr.addEventListener('load', () => {
    console.debug('the location file is fully loaded');
    onloadFunc(fr.result);
  });

  fr.readAsText(file);
}
