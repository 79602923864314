import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';

BetaChip.propTypes = {
  sx: PropTypes.object
};

BetaChip.defaultProps = {
  sx: {}
};

export default function BetaChip({ sx }) {
  return <Chip color="success" size="small" label="beta" sx={sx} />;
}
