import { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Button } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useDispatch } from 'react-redux';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import ConfirmDialog from '../../ConfirmDialog';
import { getIDToken } from '../../authentication/login/amplify';

RemoveUserButton.propTypes = {
  variant: PropTypes.string,
  team: PropTypes.string,
  user: PropTypes.string,
  updateTable: PropTypes.func,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object
  ]),
  disabled: PropTypes.bool
};

export default function RemoveUserButton({ variant, team, user, updateTable, sx = [], disabled }) {
  const dispatch = useDispatch();
  const [confirmDialogOpen, setDialogOpen] = useState(false);
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  const deleteUserFromTeam = async (user, team) => {
    console.debug(`Deleting user: ${user} from team: ${team}`);
    const reqBody = {
      username: user,
      teams: [team]
    };
    const jwt = await getIDToken();
    if (jwt) {
      // proceed to delete team
      axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API}/admin/users/teams`,
        data: reqBody,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
      })
        .then((response) => {
          console.debug(`successfully removed user from team: ${JSON.stringify(response.data)}`);
          openSnackbar({ message: 'User removed from team successfully!' });
          updateTable();
          return response.data;
        })
        .catch((error) => {
          console.error(error.message);
          openSnackbar({ message: 'Failed to remove user from team', severity: 'error' });
        });
    } else {
      console.error('JWT has expired, cannot remove user from team');
    }
  };

  const handleDialogCancel = () => {
    setDialogOpen(false);
  };
  const handleDialogConfirm = () => {
    deleteUserFromTeam(user, team);
    setDialogOpen(false);
  };
  const handleDialogOpen = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  return (
    <>
      <ConfirmDialog
        title="Delete User?"
        content="Are you sure you wish to remove the specified user from the team?"
        onCancelHandler={handleDialogCancel}
        onConfirmHandler={handleDialogConfirm}
        isOpen={confirmDialogOpen}
      />
      {variant === 'icononly' && (
        <Tooltip title="Delete User">
          <IconButton onClick={handleDialogOpen} sx={{ ...sx }} disabled={disabled} color="primary">
            <RemoveCircleOutlineIcon data-testid="delete-icon" />
          </IconButton>
        </Tooltip>
      )}
      {variant === 'text' && (
        <Tooltip title="Delete User">
          <Button
            onClick={handleDialogOpen}
            variant="contained"
            sx={{ ...sx }}
            disabled={disabled}
            startIcon={<RemoveCircleOutlineIcon />}
          >
            Delete User
          </Button>
        </Tooltip>
      )}
    </>
  );
}
