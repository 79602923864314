import { useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Modal,
  Fade,
  Backdrop,
  Grid,
  Card,
  CardHeader,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TableRowLoadingSkeleton from '../../common/TableRowLoadingSkeleton';

const styles = {
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    p: 4
  },
  table: { overflow: 'auto', maxHeight: 500 },
  tableRow: {
    '&:last-child td, &:last-child th': { border: 0 }
  },
  tableText: {
    fontFamily:
      'var(--default-mono-font, "GitLab Mono"),"JetBrains Mono","Menlo","DejaVu Sans Mono","Liberation Mono","Consolas","Ubuntu Mono","Courier New","andale mono","lucida console",monospace',
    fontSize: '9pt'
  }
};

function getRackStatusColor(status) {
  let color = '#cc9419';
  switch (status) {
    case 'deployed':
    case 'provisioned':
    case 'loaded':
    case 'completed':
      color = 'green';
      break;
    case 'error':
    case 'cancelled':
      color = 'red';
      break;
    default:
      break;
  }
  return color;
}

// scrolls to the top of the status history
function scrollTopHistory(statusRef) {
  const scrollableDiv = statusRef.current;
  if (scrollableDiv) {
    scrollableDiv.scrollTop = 0;
  }
}

RackStatusHistoryModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  rackStatuses: PropTypes.array,
  jobInProgress: PropTypes.bool
};

export default function RackStatusHistoryModal({
  isOpen,
  closeHandler,
  rackStatuses,
  jobInProgress
}) {
  const statusRef = useRef(null);

  useEffect(() => {
    // scroll to the bottom of the history once loaded
    if (isOpen) {
      setTimeout(() => scrollTopHistory(statusRef), 100);
    }
  }, [isOpen, rackStatuses]);

  return (
    <Modal
      open={isOpen}
      onClose={closeHandler}
      aria-labelledby="ssh-modal"
      aria-describedby="modal to show connect information for virtual racks"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={isOpen}>
        <Grid container direction="row" sx={styles.wrapper}>
          <Grid item xs={12} md={1} lg={3} onClick={closeHandler} />
          <Grid item xs={12} md={10} lg={6}>
            <Card>
              <CardHeader title="Rack Status History" sx={{ paddingBottom: 2 }} />
              <Divider />
              <TableContainer ref={statusRef} sx={styles.table}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Rack Status</TableCell>
                      <TableCell align="right">Progress</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ background: '' }}>
                    {jobInProgress && <TableRowLoadingSkeleton cellCount={4} />}
                    {[...rackStatuses].reverse().map((status, i) => (
                      <TableRow
                        key={`${i}-${status.metadata.rack_status.status}-${status.metadata.rack_status.status}-${status.metadata.rack_status.details}`}
                        sx={styles.tableRow}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              width: 5,
                              height: 20,
                              background: getRackStatusColor(status.metadata.rack_status.status),
                              borderRadius: 1
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography
                            color={getRackStatusColor(status.metadata.rack_status.status)}
                            variant="body2"
                            sx={styles.tableText}
                          >
                            {status.metadata.rack_status.status}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography color="secondary" variant="body2" sx={styles.tableText}>
                            {status.status}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={styles.tableText}>
                            {status.metadata.rack_status.status === 'error'
                              ? status.metadata.error
                              : status.metadata.rack_status.details}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider />
              <Box sx={{ p: 1, textAlign: 'right' }}>
                <Button
                  onClick={closeHandler}
                  size="small"
                  color="secondary"
                  endIcon={<CloseOutlinedIcon />}
                >
                  Close
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} med={1} lg={3} onClick={closeHandler} />
        </Grid>
      </Fade>
    </Modal>
  );
}
