import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import { getIDToken } from '../../authentication/login/amplify';

AssignUserToTeamDialog.propTypes = {
  isOpen: PropTypes.bool,
  team: PropTypes.string,
  closeHandler: PropTypes.func,
  updateUsers: PropTypes.func
};

export default function AssignUserToTeamDialog({ isOpen, closeHandler, team, updateUsers }) {
  const assignUserToTeam = async (user, team) => {
    console.debug(`Assigning user: ${user} to team: ${team}`);
    const reqBody = {
      username: user,
      teams: [team]
    };
    const jwt = await getIDToken();
    if (jwt) {
      // proceed to add user to team
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/admin/users/teams`,
        data: reqBody,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
      })
        .then((response) => {
          console.debug(`successfully added user to team: ${JSON.stringify(response.data)}`);
          openSnackbar({ message: 'User added to team successfully!' });
          updateUsers();
        })
        .catch(() => {
          openSnackbar({ message: 'Failed to add user to team', severity: 'error' });
        });
    } else {
      console.error('JWT has expired, cannot add user to team');
    }
  };
  const [user, setUser] = useState('');
  const handleUpdateUser = (event) => {
    setUser(event.target.value);
  };

  const handleAddUserToTeam = () => {
    assignUserToTeam(user, team);
    closeHandler();
  };
  const handleClose = () => {
    closeHandler();
  };
  const dispatch = useDispatch();
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Add User To Team</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the email address for the user you wish to add to the team
        </DialogContentText>
        <TextField
          required
          onChange={handleUpdateUser}
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleAddUserToTeam}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}
