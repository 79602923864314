import { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Button } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useDispatch } from 'react-redux';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import ConfirmDialog from '../../ConfirmDialog';
import { getIDToken } from '../../authentication/login/amplify';

RemoveRoleButton.propTypes = {
  variant: PropTypes.string,
  team: PropTypes.string,
  role: PropTypes.string,
  updateTable: PropTypes.func,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object
  ]),
  disabled: PropTypes.bool
};

export default function RemoveRoleButton({ variant, team, role, updateTable, sx = [], disabled }) {
  const dispatch = useDispatch();
  const [confirmDialogOpen, setDialogOpen] = useState(false);
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  const deleteRoleFromTeam = async (role, teamName) => {
    console.debug(`Deleting role: ${role} from team: ${team}`);
    const reqBody = {
      team: teamName,
      roles: [role]
    };
    const jwt = await getIDToken();
    if (jwt) {
      // proceed to delete team
      axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API}/admin/teams/roles`,
        data: reqBody,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
      })
        .then((response) => {
          console.debug(`successfully reemoved role from team: ${JSON.stringify(response.data)}`);
          openSnackbar({ message: 'Role removed from team successfully!' });
          updateTable();
          return response.data;
        })
        .catch((error) => {
          console.error(error.message);
          openSnackbar({ message: 'Failed to remove role from team', severity: 'error' });
        });
    } else {
      console.error('JWT has expired, cannot remove role from team');
    }
  };

  const handleDialogCancel = () => {
    setDialogOpen(false);
  };
  const handleDialogConfirm = () => {
    deleteRoleFromTeam(role, team);
    setDialogOpen(false);
  };
  const handleDialogOpen = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  return (
    <>
      <ConfirmDialog
        title="Delete Role?"
        content="Are you sure you wish to remove the specified role from the team?"
        onCancelHandler={handleDialogCancel}
        onConfirmHandler={handleDialogConfirm}
        isOpen={confirmDialogOpen}
      />
      {variant === 'icononly' && (
        <Tooltip title="Delete Role">
          <IconButton onClick={handleDialogOpen} sx={{ ...sx }} disabled={disabled} color="primary">
            <RemoveCircleOutlineIcon data-testid="delete-icon" />
          </IconButton>
        </Tooltip>
      )}
      {variant === 'text' && (
        <Tooltip title="Delete Role">
          <Button
            onClick={handleDialogOpen}
            variant="contained"
            sx={{ ...sx }}
            disabled={disabled}
            startIcon={<RemoveCircleOutlineIcon />}
          >
            Delete Role
          </Button>
        </Tooltip>
      )}
    </>
  );
}
