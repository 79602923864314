import PropTypes from 'prop-types';
// material
import { Card, CardContent, Typography, Tooltip, Stack, IconButton } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import palette from '../../../theme/palette';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import BetaChip from '../../common/BetaChip';
import { getConnectivityObject, getLastCheckedText } from './Connectivity';
import useVrackApi from '../../../hooks/useVrackApi';

const tooltipText = 'enables ground services connectivity in the rack';
const tooltipTextUnavailable =
  'rack was created prior to ground connectivity being available or you do not have xid/tailnumber set for your rack';

const connectivityType = 'ku';

GCSConnectivity.propTypes = {
  rackName: PropTypes.string,
  connectivity: PropTypes.object,
  xid: PropTypes.number,
  tailNumber: PropTypes.string
};

GCSConnectivity.defaultProps = {
  connectivity: { ku: { state: 'disabled' } }
};

export default function GCSConnectivity({ rackName, connectivity, xid, tailNumber }) {
  const [isConfiguring, setIsConfiguring] = useState(false);
  const identity = useSelector((state) => state.identity.value);
  const { fetchData: vrackApiSetConnectivity } = useVrackApi('post', '/racks/connectivity');
  const dispatch = useDispatch();

  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  // checks if connectivity is unavailable. this means the ground connectivity was never available when rack was created
  const isUnavailable = () => {
    if (!('ku' in connectivity) || xid === 0 || tailNumber === '') {
      return true;
    }
    return false;
  };

  // checks if connectivity button should be disabled. should be disabled if ground connectivity is unavailable, if configuring, or if xid/tailnumber are not set
  const isButtonDisabled = () => {
    if (isUnavailable() || xid === 0 || tailNumber === '') {
      return true;
    }

    return isConfiguring;
  };

  useEffect(() => {
    const conn = getConnectivityObject(connectivity, connectivityType);
    // check connectivity state if it's configuring
    setIsConfiguring(conn.state === 'configuring');
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [connectivity]);

  const setRackConnectivityHandler = async () => {
    const conn = getConnectivityObject(connectivity, connectivityType);
    const enabled = conn.state !== 'enabled';

    const bodyData = {
      environment: 'aws',
      identity,
      releaseName: rackName,
      type: 'ku',
      enabled
    };

    vrackApiSetConnectivity({ data: bodyData })
      .then(() => {
        setIsConfiguring(true);
        openSnackbar({
          message: `'successfully sent request to set rack connectivity'`
        });
      })
      .catch((error) => {
        console.error('failed to set rack connectivity', error.response.data.message);
        openSnackbar({
          message: `${error.response.data.message}`,
          severity: 'error'
        });
      });
  };

  const getColor = () => {
    const conn = getConnectivityObject(connectivity, connectivityType);
    // check to verify if connectivity is unavailable
    if (isUnavailable()) {
      return palette.secondary;
    }
    // get the appropriate color based on connectivity state
    if (conn.state === 'configuring' || isConfiguring) {
      return palette.warning;
    }
    if (conn.state === 'enabled') {
      return palette.success;
    }
    if (conn.state === 'failed' || conn.state === 'cancelled') {
      return palette.error;
    }
    if (conn.state === 'disabled') {
      return palette.primary;
    }
    return palette.secondary;
  };

  const getConnectivityState = () => {
    const conn = getConnectivityObject(connectivity, connectivityType);
    // returns the appropriate connectivity state to show
    if (isConfiguring) {
      return 'configuring';
    }
    if (connectivity) {
      if (isUnavailable()) {
        return 'not provisioned';
      }
      return conn.state;
    }
    return '';
  };

  const RootStyle = styled(Card)(() => ({
    boxShadow: 'none',
    color: getColor().contrastText,
    backgroundColor: isUnavailable() ? getColor().main : getColor().darker
  }));

  return (
    <RootStyle>
      <CardContent sx={{ marginY: 1 }}>
        <Stack direction="row" spacing={3}>
          <Tooltip title={isUnavailable() ? tooltipTextUnavailable : tooltipText}>
            <span>
              <IconButton
                disabled={isButtonDisabled()}
                size="large"
                sx={{
                  display: isUnavailable() ? 'none' : '',
                  border: 'solid 1px',
                  borderColor: getColor().light,
                  height: 50
                }}
                onClick={setRackConnectivityHandler}
              >
                <PowerSettingsNewOutlinedIcon htmlColor={getColor().light} />
              </IconButton>
            </span>
          </Tooltip>
          <Stack>
            <Typography variant="h5" sx={{ color: isUnavailable() ? getColor().light : 'white' }}>
              GCS Ku Network
            </Typography>
            <Typography variant="overline" sx={{ fontWeight: 100 }}>
              {getConnectivityState()}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            right: 10,
            bottom: 10,
            fontSize: '8pt',
            color: isUnavailable() ? getColor().light : 'lightgrey'
          }}
        >
          {getLastCheckedText(connectivity, connectivityType)}
        </Typography>
        <SatelliteAltIcon
          sx={{
            fontSize: 120,
            position: 'absolute',
            zIndex: -1,
            right: 0,
            top: 0,
            opacity: 0.5
          }}
          htmlColor={getColor().dark}
        />
        <BetaChip sx={{ position: 'absolute', right: 10, top: 10 }} />
      </CardContent>
    </RootStyle>
  );
}
