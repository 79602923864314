import {
  Box,
  Modal,
  Fade,
  Backdrop,
  Grid,
  Card,
  CardHeader,
  Divider,
  Button,
  Tabs,
  Tab,
  Stack
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { setOpenSnackbar } from '../../../../store/slices/snackbarSlice';
import { getIDToken } from '../../../authentication/login/amplify';
import LoadComponent from '../../loading/LoadComponent';
import LoadKitTabPanel from './LoadKitTabPanel';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  p: 4
};

function canLoad(kitID, components) {
  return !(
    kitID === '' &&
    components.software.length === 0 &&
    components.content.length === 0 &&
    components.onemedia.length === 0 &&
    components.media.length === 0
  );
}

LoadModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  rackName: PropTypes.string
};

export default function LoadModal({ isOpen, closeHandler, rackName }) {
  const dispatch = useDispatch();
  const identity = useSelector((state) => state.identity.value);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [kitID, setKitID] = useState('');
  const [kitIDType, setKitIDType] = useState('kit_release_id');
  const [kitType, setKitType] = useState('software');
  const [kitConfig, setKitConfig] = useState(null);
  const [componentsList, setComponentsList] = useState({
    software: [],
    content: [],
    onemedia: [],
    media: []
  });

  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));
  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const loadHandler = () => {
    if (tabValue === 0) {
      return loadComponentHandler();
    }
    return loadKitHandler();
  };

  const loadKitHandler = async () => {
    // validate fields
    if (kitID === '') {
      openSnackbar({ message: 'Kit Identifier must not be empty', severity: 'error' });
      return;
    }

    const jwt = await getIDToken();
    const url = `${process.env.REACT_APP_API}/racks/${kitType}kit`;
    const bodyData = {
      environment: 'aws',
      identity,
      releaseName: rackName
    };

    if (kitIDType === 'kit_release_id') {
      bodyData.fileId = parseInt(kitID, 10);
    } else {
      bodyData.srr = parseInt(kitID, 10);
    }

    // add kit config payload if specified
    if (kitConfig && kitConfig !== '') {
      bodyData.config = {
        partnumber: kitConfig.partnumber,
        version: kitConfig.version
      };
    }

    setLoading(true);
    axios({
      url,
      method: 'post',
      data: bodyData,
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
    })
      .then((response) => {
        console.debug(`Response from kit load API: ${JSON.stringify(response.data)}`);
        openSnackbar({ message: 'Kit Load Sent Successfully!' });
      })
      .catch((error) => {
        console.error(error);
        openSnackbar({ message: 'Failed to load kit', severity: 'error' });
      })
      .finally(() => {
        setLoading(false);
        closeModal();
      });
  };

  const loadComponentHandler = async () => {
    const jwt = await getIDToken();
    const url = `${process.env.REACT_APP_API}/racks/component`;
    const bodyData = { ...componentsList };
    bodyData.environment = 'aws';
    bodyData.identity = identity;
    bodyData.releaseName = rackName;
    setLoading(true);
    axios({
      url,
      method: 'post',
      data: bodyData,
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
    })
      .then((response) => {
        console.debug(`Response from component API: ${JSON.stringify(response.data)}`);
        openSnackbar({ message: 'Components Load Sent Successfully!' });
      })
      .catch((error) => {
        console.error(error);
        openSnackbar({ message: 'Failed to load components', severity: 'error' });
      })
      .finally(() => {
        setLoading(false);
        closeModal();
      });
  };

  const closeModal = () => {
    // clear component list before closing the modal
    setComponentsList({
      software: [],
      content: [],
      onemedia: [],
      media: []
    });
    closeHandler();
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      data-testid="load-modal"
      aria-labelledby="load-modal"
      aria-describedby="modal to show loading options for virtual racks"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <Grid container>
            <Grid item xs={12} md={1} lg={2} xl={3} onClick={closeModal} />
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Card>
                <CardHeader title="Loading VRack" />
                <Box
                  sx={{
                    p: 2,
                    width: '100%',
                    marginTop: '10px',
                    maxHeight: '70vh',
                    overflow: 'auto'
                  }}
                >
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      textColor="primary"
                      indicatorColor="primary"
                      variant="fullWidth"
                      value={tabValue}
                      onChange={handleTabChange}
                    >
                      <Tab label="Components" />
                      <Tab label="Kit" />
                    </Tabs>
                  </Box>
                  <TabPanel value={tabValue} index={0}>
                    <LoadComponent
                      componentsList={componentsList}
                      setComponentsList={setComponentsList}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <LoadKitTabPanel
                      kitID={kitID}
                      setKitID={setKitID}
                      kitIDType={kitIDType}
                      setKitIDType={setKitIDType}
                      kitType={kitType}
                      setKitType={setKitType}
                      kitConfig={kitConfig}
                      setKitConfig={setKitConfig}
                    />
                  </TabPanel>
                </Box>
                <Divider />
                <Stack direction="row" spacing={1} justifyContent="flex-end" p={1}>
                  <Button onClick={closeModal} variant="outlined" color="secondary">
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={loading}
                    loadingIndicator="... Loading"
                    onClick={loadHandler}
                    variant="contained"
                    color="secondary"
                    disabled={!canLoad(kitID, componentsList)}
                  >
                    Load {tabValue === 0 ? 'Components' : 'Kit'}
                  </LoadingButton>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} med={1} lg={2} xl={3} onClick={closeModal} />
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}
