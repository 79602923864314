import { useState, useEffect } from 'react';

import { FormControl, FormHelperText, InputLabel, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getIDToken } from '../../authentication/login/amplify';

const kitConfigStyles = {
  select: { marginY: 1, minWidth: 200 }
};

SoftwareKitConfigSelect.propTypes = {
  kitID: PropTypes.string,
  kitIDType: PropTypes.string,
  kitConfig: PropTypes.object,
  setKitConfig: PropTypes.func
};

export default function SoftwareKitConfigSelect({ kitID, kitIDType, kitConfig, setKitConfig }) {
  const [kitConfigList, setKitConfigList] = useState([]);
  const [buildType, setBuildType] = useState('srr');

  const handleChange = (event) => {
    setKitConfig(JSON.parse(event.target.value));
  };

  const updateKitConfigList = async () => {
    // updates the list of kit configs using the kit id and type specified
    const jwt = await getIDToken();
    if (jwt) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}/scmdb/builds/${kitID}/configs?type=${buildType}`,
        headers: { Authorization: `Bearer ${jwt}` }
      })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const configs = response.data.reverse();
            setKitConfigList(configs);
          } else {
            setKitConfigList([]);
          }
        })
        .catch((err) => {
          console.error('failed to retrieve kit configs', err);
          setKitConfigList([]);
          setKitConfig('');
        });
    } else {
      console.error('JWT has expired, cannot fetch available kit configs');
    }
  };

  useEffect(() => {
    // clear if kit is undefined or empty
    if (!kitID || kitID === '') {
      setKitConfigList([]);
      setKitConfig('');
      return;
    }
    updateKitConfigList();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [kitID, buildType]);

  useEffect(() => {
    // since the build type param we use to query the kit configs don't have 'kit' prepended to it,
    // we have to use a different variable for the querying
    if (kitIDType === 'kit_srr') {
      setBuildType('srr');
    } else {
      setBuildType('release_id');
    }
  }, [kitIDType]);

  return (
    <FormControl sx={kitConfigStyles.select} size="small">
      <InputLabel data-testid="kit-config-select-label">Kit Configuration</InputLabel>
      <Select
        value={kitConfig ? JSON.stringify(kitConfig) : ''}
        labelId="kit-config-select-label"
        label="Kit Configuration"
        onChange={handleChange}
        autoWidth
        displayEmpty
      >
        {kitConfigList.length === 0 ? (
          <MenuItem disabled>
            <em>No Configs Found</em>
          </MenuItem>
        ) : null}
        {kitConfigList.map((config) => (
          <MenuItem key={config.name} value={JSON.stringify(config)}>
            {config.name} (pn: {config.partnumber} version: {config.version})
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        You can choose to specify which kit configuration to load. The first config is activated by
        default.
      </FormHelperText>
    </FormControl>
  );
}
