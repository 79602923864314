// configures the store for Redux for global app state management
import { configureStore } from '@reduxjs/toolkit';
import identityReducer from './slices/identitySlice';
import jwtPayloadReducer from './slices/jwtPayloadSlice';
import snackbarReducer from './slices/snackbarSlice';
import userDetailSliceReducer from './slices/userDetailsSlice';
import featureFlagsReducer from './slices/featureFlagsSlice';

export default configureStore({
  reducer: {
    identity: identityReducer,
    jwtPayload: jwtPayloadReducer,
    snackbar: snackbarReducer,
    userDetails: userDetailSliceReducer,
    featureFlags: featureFlagsReducer
  }
});
