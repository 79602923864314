import * as React from 'react';
import {
  Box,
  Typography,
  Modal,
  Fade,
  Backdrop,
  Grid,
  Card,
  CardHeader,
  Divider,
  Button,
  Tabs,
  Tab
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CopyButton from './CopyButton';

const styles = {
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    p: 4
  },
  codeBlock: {
    display: 'block',
    p: 1,
    m: 1,
    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
    color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
    border: '1px solid',
    borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300'),
    borderRadius: 1,
    whiteSpace: 'pre-wrap'
  }
};

function getSSHProxyHost() {
  // Get the appropriate ssh proxy host depending on the STAGE env
  switch (process.env.REACT_APP_STAGE) {
    case 'dev':
      return 'ssh.virtualrack-dev.nextcloud.aero:443';
    case 'test':
      return 'ssh.virtualrack-test.nextcloud.aero:443';
    default:
      return 'ssh.virtualrack.nextcloud.aero:443';
  }
}

function getSSHConfig() {
  return `Host *.vrack
  ServerAliveInterval 60
  UserKnownHostsFile=/dev/null
  StrictHostKeyChecking no
  ProxyCommand openssl s_client -quiet  -servername %h -connect ${getSSHProxyHost()}
`;
}

function getSSHInstructions(sshCommand) {
  const sshConfig = getSSHConfig();
  return (
    <>
      1. Open an SSH client, such as Terminal for OSX or MobaXTerm for Windows.
      <br />
      2. Copy this SSH config to your local machine under '~/.ssh/config': <br />
      <Box
        component="span"
        sx={{
          display: 'block',
          p: 1,
          m: 1,
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
          color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
          border: '1px solid',
          borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300'),
          borderRadius: 1,
          whiteSpace: 'pre-wrap'
        }}
      >
        {sshConfig}
        <CopyButton content={sshConfig} />
      </Box>
      3. SSH to your VRack gateway using its hostname:
      <Box
        component="span"
        sx={{
          display: 'block',
          p: 1,
          m: 1,
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
          color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
          border: '1px solid',
          borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300'),
          borderRadius: 1,
          whiteSpace: 'pre-wrap'
        }}
      >
        <CopyButton content={sshCommand} /> {sshCommand}
      </Box>
    </>
  );
}

function getSSHPanel(tabValue, sshCommand, index) {
  return (
    <TabPanel value={tabValue} index={index} style={{ height: '70vh', overflow: 'scroll' }}>
      <Typography variant="subtitle2" color="red">
        We are significantly enhancing the security of Virtual Rack and expanding its accessibility
        to external customers. To do this, we are implementing essential functionality to strengthen
        the authentication process. <br /> <br />
        As of July 28, 2023 (date may be subject to change), SSH access to Virtual Rack (VRack)
        gateways via username and password (basic auth) will be removed. In its place, we are
        introducing a more secure method of authentication and login to Virtual Rack, using the
        vrackctl tool. Please visit the other tab for more details about vrackctl <br /> <br />
        The vrackctl tool provides a secure and streamlined method to access Virtual Rack, ensuring
        a reliable and user-friendly experience. We kindly request all users to familiarize
        themselves with vrackctl to smoothly transition to this new authentication method.
        <br />
        <br />
      </Typography>
      <Typography variant="subtitle2" paragraph>
        To ssh to the Gateway VM locally, follow these instructions:
      </Typography>
      <Typography variant="body2" paragraph>
        {getSSHInstructions(sshCommand)}
      </Typography>
      <Typography variant="body2">
        For additional information on ssh please refer to this guide: &nbsp;
        <a
          href="https://confluence.panasonic.aero/display/dtauto/How+to+SSH+in+to+the+Virtual+Rack+Gateway"
          target="_blank"
          // to prevent tabnabbing attacks
          rel="noopener noreferrer"
        >
          SSH Guide
        </a>
      </Typography>
    </TabPanel>
  );
}

function getVrackctlInstructions(rackName) {
  const vrackctlLoginCommand = './vrackctl login';
  const vrackctlConfigureCommand = './vrackctl configure';
  const vrackctlConnectCommand = `./vrackctl instance connect ${rackName}`;
  return (
    <>
      1. Download and install the vrackctl binary for your machine: &nbsp;
      <a
        href="/static/help-system/Content/PDFs/Virtual%20Rack%20CLI%20Installation%20Guide.pdf"
        target="_blank"
        // to prevent tabnabbing attacks
        rel="noopener noreferrer"
      >
        Vrackctl Installation Guide
      </a>
      <br />
      2. Login using your nextcloud credentials
      <Box component="span" sx={styles.codeBlock}>
        <CopyButton content={vrackctlLoginCommand} />
        {vrackctlLoginCommand}
      </Box>
      3. Select your team identity using the configure command
      <Box component="span" sx={styles.codeBlock}>
        <CopyButton content={vrackctlConfigureCommand} />
        {vrackctlConfigureCommand}
      </Box>
      4. Connect to your vrack
      <Box component="span" sx={styles.codeBlock}>
        <CopyButton content={vrackctlConnectCommand} /> {vrackctlConnectCommand}
      </Box>
    </>
  );
}

function getVrackctlPanel(tabValue, rackName, index) {
  return (
    <TabPanel value={tabValue} index={index}>
      <Typography variant="subtitle2" paragraph>
        To connect to the Gateway VM locally using Vrackctl, follow these instructions:
      </Typography>
      <Typography variant="body2" paragraph>
        {getVrackctlInstructions(rackName)}
      </Typography>
      <Typography variant="body2">
        For additional information on how to use vrackctl please refer to this guide: &nbsp;
        <a
          href="/static/help-system/Content/PDFs/Virtual%20Rack%20CLI%20Developer%20Guide.pdf"
          target="_blank"
          // to prevent tabnabbing attacks
          rel="noopener noreferrer"
        >
          Vrackctl Developer Guide
        </a>
      </Typography>
    </TabPanel>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

SSHModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  hostname: PropTypes.string,
  rackName: PropTypes.string
};

export default function SSHModal({ isOpen, closeHandler, hostname, rackName }) {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const sshCommand = `ssh user@${hostname ? hostname.split(':')[0] : ''}`;

  return (
    <>
      <Modal
        open={isOpen}
        onClose={closeHandler}
        aria-labelledby="ssh-modal"
        aria-describedby="modal to show connect information for virtual racks"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isOpen}>
          <Grid container direction="row" sx={styles.wrapper}>
            <Grid item xs={12} md={1} lg={3} onClick={closeHandler} />
            <Grid item xs={12} md={10} lg={6}>
              <Card>
                <CardHeader title="Connect to Vrack" />
                <Box sx={{ width: '100%', marginTop: '10px' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      textColor="primary"
                      indicatorColor="primary"
                      variant="fullWidth"
                      value={tabValue}
                      onChange={handleChange}
                    >
                      <Tab label="Vrackctl" />
                      <Tab label="Local SSH Client (Discontinued)" />
                    </Tabs>
                  </Box>
                  {getVrackctlPanel(tabValue, rackName, 0)}
                  {getSSHPanel(tabValue, sshCommand, 1)}
                </Box>

                <Divider />
                <Box sx={{ p: 2, textAlign: 'right' }}>
                  <Button
                    onClick={closeHandler}
                    size="small"
                    color="secondary"
                    endIcon={<CloseOutlinedIcon />}
                  >
                    Close
                  </Button>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} med={1} lg={3} onClick={closeHandler} />
          </Grid>
        </Fade>
      </Modal>
    </>
  );
}
