import PropTypes from 'prop-types';
// material
import { Stack, Card, CardHeader, CardContent, Grid, Alert } from '@material-ui/core';
import { CellularConnectivity, GCSConnectivity } from '.';

NetworkInfo.propTypes = {
  rackDetails: PropTypes.object
};

// retrieve the appropriate connectivity details from the provided object. this is to support backwards compatability for racks before ground connectivity
export const getConnectivityObject = (connectivity, connectivityType) => {
  if (connectivity) {
    if (connectivityType === 'cellmodem') {
      return 'cellmodem' in connectivity ? connectivity.cellmodem : connectivity;
    }
    if (connectivityType === 'ku') {
      return 'ku' in connectivity ? connectivity.ku : {};
    }
  }
  return {};
};

export const getLastCheckedText = (connectivity, connectivityType) => {
  const conn = getConnectivityObject(connectivity, connectivityType);
  return !('last_checked' in conn) || conn.last_checked === 0
    ? `Last Modified: N/A`
    : `Last Modified: ${new Date(conn.last_checked * 1000).toLocaleString()}`;
};

export default function NetworkInfo({ rackDetails }) {
  return (
    <Card sx={{ minHeight: '260px' }}>
      <CardHeader
        title={
          <Stack spacing={2} alignItems="flex-start" justifyContent="space-between" direction="row">
            <span>Connectivity</span>
            {(getConnectivityObject(rackDetails.connectivity, 'cellmodem').state ||
              getConnectivityObject(rackDetails.connectivity, 'ku').state) && (
              <Alert sx={{ py: 0 }} severity="warning">
                Experiencing connectivity issues? Restart connectivity below
              </Alert>
            )}
          </Stack>
        }
      />

      <CardContent>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={12}>
            <CellularConnectivity
              rackName={rackDetails.name}
              connectivity={rackDetails.connectivity}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <GCSConnectivity
              rackName={rackDetails.name}
              connectivity={rackDetails.connectivity}
              xid={rackDetails.xid}
              tailNumber={rackDetails.tailNumber}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
