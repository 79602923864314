import { useState } from 'react';
import axios from 'axios';
import { getIDToken } from '../components/authentication/login/amplify';

const invalidJwtError = 'invalid JWT from nextcloud';

// A Custom Hook to Call Vrack API Endpoints
export default function useVrackApi(method, endpoint) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async (options = {}) => {
    const jwt = await getIDToken();
    if (jwt) {
      setLoading(true);
      try {
        const response = await axios({
          method,
          baseURL: process.env.REACT_APP_API,
          url: `${endpoint}`,
          headers: { Authorization: `Bearer ${jwt}` },
          ...options
        });
        setError(null);
        return response.data;
      } catch (error) {
        setError(error);
        throw error;
      } finally {
        setLoading(false);
      }
    } else {
      setError(invalidJwtError);
      throw error;
    }
  };

  return { loading, error, fetchData };
}
