import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Modal,
  Fade,
  Backdrop,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Autocomplete,
  TextField,
  Radio,
  Stack
} from '@material-ui/core';

import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getIDToken } from '../../authentication/login/amplify';
import MMALoadComponent from './MMALoadComponent';
import { useIsFeatureFlagEnabled } from '../../../utils/FeatureFlags';

const componentPNExistsHelperText = 'Component partnumber already exists';
const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    p: 4
  },
  modalFooter: {
    p: 1
  },
  componentDetails: {
    marginTop: 1,
    marginBottom: 2
  },
  componentsTable: {
    marginY: 2
  },
  tableReleaseNotes: {
    whiteSpace: 'nowrap',
    maxWidth: '200px',
    width: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

function getComponentVersions(componentVersions) {
  const components = componentVersions.map((v) => v.version);
  return components.reverse();
}

AddNewComponentModal.propTypes = {
  componentsList: PropTypes.object,
  setComponentsList: PropTypes.func
};

export default function AddNewComponentModal({ componentsList, setComponentsList }) {
  const [isOpen, setIsOpen] = useState(false);
  const [sourceValue, setSourceValue] = useState('scmdb');
  const [typeValue, setTypeValue] = useState('software');
  const [partNumberValue, setPartNumberValue] = useState('');
  const [versionValue, setVersionValue] = useState('');
  const [componentVersions, setComponentVersions] = useState([]);
  const [filenameValue, setFilenameValue] = useState('');
  const [uploaded, setUploaded] = useState([]);
  const [addDisabled, setAddDisabled] = useState(false);
  const [mmaAirline, setMMAAirline] = useState(null);
  const [mmaProfile, setMMAProfile] = useState(null);
  const [mmaCycle, setMMACycle] = useState('');
  const [mmaExportVersion, setMMAExportVersion] = useState(null);
  const isMMAEnabled = useIsFeatureFlagEnabled('media_metadata');
  const [searchParams, setSearchParams] = useSearchParams();

  const identity = useSelector((state) => state.identity.value);

  const openHandler = () => {
    searchParams.append('new_component', true);
    setSearchParams(searchParams);
    setIsOpen(true);
  };
  const closeHandler = () => {
    searchParams.delete('new_component');
    setSearchParams(searchParams);
    setIsOpen(false);
  };

  const addComponent = (components) => {
    // change the payload and type depending on the type of source value they chose
    let componentPayload = {};
    let componentType = typeValue;

    switch (sourceValue) {
      case 'aws':
        if (sourceValue === 'aws' && filenameValue === '') {
          console.error('filename cannot be empty');
          return;
        }
        componentPayload = {
          source: sourceValue,
          filename: filenameValue
        };
        break;
      case 'scmdb':
        if (partNumberValue === '' || versionValue === '') {
          console.error('part number or version cannot be empty');
          return;
        }
        componentPayload = {
          partnumber: partNumberValue,
          version: versionValue,
          source: sourceValue
        };
        break;
      case 'mma':
        if (!mmaAirline || !mmaProfile || mmaCycle === '' || !mmaExportVersion) {
          console.error(
            'mma fields cannot be empty',
            mmaAirline,
            mmaProfile,
            mmaCycle,
            mmaExportVersion
          );
          return;
        }
        componentPayload = {
          source: 'mma',
          version: mmaExportVersion.export_version,
          mediainfo: {
            airline: mmaAirline.icao?.toLowerCase(),
            profile: mmaProfile.name,
            cycle: mmaCycle,
            version: mmaExportVersion.export_version,
            mediatype: 'preview_kit'
          }
        };
        componentType = 'media';
        break;
      default:
        break;
    }

    // adds a component to the current list
    const newComponents = { ...components };
    newComponents[componentType].push(componentPayload);
    setComponentsList(newComponents);
    // clears input values
    setPartNumberValue('');
    setVersionValue('');
    setFilenameValue('');
    closeHandler();
  };

  const handlePartNumberChange = (event, newValue) => {
    setVersionValue('');
    if (sourceValue === 'aws') {
      setPartNumberValue(newValue);
      setComponentVersions([]);
    } else {
      setPartNumberValue(event.target.value);
      // query available versions of the component
      fetchComponentVersions(event.target.value);
    }
  };

  const handleVersionChange = (event, newValue) => {
    setVersionValue(newValue);
  };

  const handleFilenameChange = (event, newValue) => {
    setFilenameValue(newValue);
  };

  const handleTypeChange = (event) => {
    setTypeValue(event.target.value);
  };
  const handleSourceChange = (event) => {
    setPartNumberValue('');
    setVersionValue('');
    setFilenameValue('');

    if (event.target.value === 'aws') {
      fetchUploadedComponents();
    }
    setSourceValue(event.target.value);
  };

  const getUploadedOptions = () => {
    // get all the filenames that were added to the componentsList already
    const existingFiles = [];
    Object.keys(componentsList).forEach((key) => {
      const components = componentsList[key];
      components.forEach((component) => {
        if (component.filename) {
          existingFiles.push(component.filename);
        }
      });
    });
    // return a list of filenames excluding the ones added already
    return uploaded.filter((filename) => !existingFiles.includes(filename));
  };

  const fetchUploadedComponents = async () => {
    const jwt = await getIDToken();
    if (jwt) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}/identities/${identity}/loadables`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((resp) => {
          const data = resp.data.components;
          setUploaded(data.map((c) => c.filename));
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.error('JWT has expired, cannot retrieve uploaded loadables');
    }
  };

  const fetchComponentVersions = async (partnumber) => {
    const jwt = await getIDToken();
    if (jwt) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}/scmdb/component/${partnumber}/versions`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((resp) => {
          if (resp.status === 200) {
            setComponentVersions(resp.data);
          } else {
            setComponentVersions([]);
          }
        })
        .catch((err) => {
          console.error(err);
          setComponentVersions([]);
        });
    } else {
      console.error('JWT has expired, cannot retrieve component versions');
    }
  };

  // checks if the current component partnumber being added already exists on the current component list
  const checkComponentExists = () => {
    const componentFound = (comp) => comp.partnumber === partNumberValue;
    const componentIndex = componentsList[typeValue].findIndex(componentFound);
    // index will be greater than -1 if component is found
    return componentIndex > -1;
  };

  // returns the Component Details section that dictates extra information to be selected for the component
  const getComponentDetails = () => {
    switch (sourceValue) {
      case 'aws':
        return (
          <Autocomplete
            id="filename"
            size="small"
            onInputChange={handleFilenameChange}
            options={getUploadedOptions()}
            value={filenameValue}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText="Filename of the component to load"
                label="Filename"
              />
            )}
            sx={styles.componentDetails}
          />
        );
      case 'mma':
        return (
          <MMALoadComponent
            mmaAirline={mmaAirline}
            setMMAAirline={setMMAAirline}
            mmaProfile={mmaProfile}
            setMMAProfile={setMMAProfile}
            mmaCycle={mmaCycle}
            setMMACycle={setMMACycle}
            mmaExportVersion={mmaExportVersion}
            setMMAExportVersion={setMMAExportVersion}
          />
        );
      default:
        return (
          <Stack spacing={2} sx={styles.componentDetails}>
            <TextField
              error={addDisabled}
              size="small"
              required
              inputProps={{ 'data-testid': 'component-partnumber' }}
              label="Part Number"
              value={partNumberValue}
              sx={{ width: '100%' }}
              helperText={
                !addDisabled ? 'Part number of the component to load' : componentPNExistsHelperText
              }
              onChange={handlePartNumberChange}
            />
            <Autocomplete
              size="small"
              options={getComponentVersions(componentVersions)}
              value={versionValue}
              onInputChange={handleVersionChange}
              inputprops={{ 'data-testid': 'component-version' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText="Version of the component"
                  label="Select a Component Version"
                />
              )}
              sx={styles.componentDetails}
            />
          </Stack>
        );
    }
  };

  useEffect(() => {
    if (checkComponentExists()) {
      setAddDisabled(true);
    } else {
      setAddDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partNumberValue]);

  useEffect(() => {
    // change the default type value depending on new source value
    if (sourceValue === 'mma') {
      setTypeValue('metadata');
    } else {
      setTypeValue('software');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceValue]);

  return (
    <>
      <Button variant="outlined" onClick={openHandler}>
        Add New Component
      </Button>
      <Modal
        open={isOpen}
        onClose={closeHandler}
        sx={{ outline: 'none' }}
        aria-labelledby="add-new-component-modal"
        aria-describedby="modal to add a new component"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isOpen}>
          <Grid
            data-testid="add-new-component-modal-content"
            direction="row"
            sx={styles.modal}
            container
          >
            <Grid item xs={12} md={1} lg={3} onClick={closeHandler} />
            <Grid item xs={12} md={10} lg={6}>
              <Card>
                <CardHeader sx={{ padding: 2 }} title="Add New Component" />
                <Divider />
                <CardContent sx={{ paddingTop: 1 }}>
                  <Stack spacing={2}>
                    <FormControl>
                      <FormLabel>Source</FormLabel>
                      <RadioGroup value={sourceValue} onChange={handleSourceChange}>
                        <FormControlLabel
                          value="scmdb"
                          control={<Radio size="small" />}
                          label="SCMDB"
                        />
                        <FormControlLabel
                          value="aws"
                          control={<Radio size="small" />}
                          label="Team Loadable"
                        />
                        {isMMAEnabled && (
                          <FormControlLabel
                            value="mma"
                            control={<Radio size="small" />}
                            label="MMA"
                          />
                        )}
                      </RadioGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Type</FormLabel>
                      <RadioGroup value={typeValue} onChange={handleTypeChange}>
                        {sourceValue !== 'mma' ? (
                          <>
                            <FormControlLabel
                              value="software"
                              control={<Radio size="small" />}
                              label="Software"
                            />
                            <FormControlLabel
                              value="content"
                              control={<Radio size="small" />}
                              label="Content"
                            />
                            <FormControlLabel
                              value="onemedia"
                              control={<Radio size="small" />}
                              label="One Media"
                            />
                          </>
                        ) : (
                          <FormControlLabel
                            value="metadata"
                            control={<Radio size="small" />}
                            label="Media Metadata"
                          />
                        )}
                      </RadioGroup>
                    </FormControl>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormLabel>Component Details</FormLabel>
                        {getComponentDetails()}
                      </Grid>
                    </Grid>
                  </Stack>
                </CardContent>
                <Divider />
                <Stack
                  justifyContent="flex-end"
                  direction="row"
                  sx={styles.modalFooter}
                  spacing={1}
                >
                  <Button
                    sx={{ marginLeft: 1 }}
                    color="secondary"
                    variant="outlined"
                    onClick={closeHandler}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={addDisabled}
                    variant="contained"
                    color="success"
                    onClick={() => {
                      addComponent(componentsList);
                    }}
                  >
                    Add Component
                  </Button>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} med={1} lg={3} onClick={closeHandler} />
          </Grid>
        </Fade>
      </Modal>
    </>
  );
}
