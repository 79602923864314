// material
import { Stack } from '@material-ui/core';

// pages
import Login from '../pages/Login';

// layouts
import DashboardFooter from './dashboard/DashboardFooter';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AuthLayout() {
  return (
    <Stack sx={{ height: '100%' }} justifyContent="space-between">
      <Login />
      <DashboardFooter />
    </Stack>
  );
}
