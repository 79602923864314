import { filter } from 'lodash';
import { useState } from 'react';

import PropTypes from 'prop-types';
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { UserListHead, UserListToolbar } from '../../user';
import { DeletePolicyButton } from '.';
import { getComparatorWithOrderBy } from '../../../utils/sort';

const policyTableHead = [
  { id: 'action', label: 'Action', alignRight: false },
  { id: 'resource', label: 'Resource', alignRight: false },
  { id: 'limit', label: 'Limit', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: '' }
];

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      // searches policy role
      if (_user.role.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        return true;
      }
      // searches policy action
      if (_user.action.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        return true;
      }
      return false;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

PolicyTable.propTypes = {
  policyList: PropTypes.array,
  updatePolicies: PropTypes.func
};

export default function PolicyTable({ policyList, updatePolicies }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - policyList.length) : 0;
  const filteredUsers = applySortFilter(
    policyList,
    getComparatorWithOrderBy(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Card data-testid="policy-table-card">
      <UserListToolbar
        placeholder="Search policies..."
        filterName={filterName}
        onFilterName={handleFilterByName}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={policyTableHead}
              rowCount={policyList.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers != null &&
                filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const { action, resource, limit, role } = row;

                    return (
                      <TableRow hover key={i} tabIndex={-1}>
                        <TableCell align="left">{'action' in row ? action : 'N/A'}</TableCell>
                        <TableCell align="left">{'resource' in row ? resource : 'N/A'}</TableCell>
                        <TableCell align="left">{'limit' in row ? limit : 'N/A'}</TableCell>
                        <TableCell align="left">{role}</TableCell>
                        <TableCell align="right">
                          <DeletePolicyButton
                            updatePolicies={updatePolicies}
                            policy={row}
                            variant="icononly"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={policyList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
