import { Icon } from '@iconify/react';
import teamIcon from '@iconify/icons-ant-design/team-outlined';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography, Grid } from '@material-ui/core';

// utils
import PropTypes from 'prop-types';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 5),
  color: theme.palette.primary.darker
  // backgroundColor: theme.palette.warning.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  textAlign: 'right',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

TeamRacksCount.propTypes = {
  rackList: PropTypes.object
};

export default function TeamRacksCount({ rackList }) {
  return (
    <RootStyle>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Team VRacks
          </Typography>
          <Typography variant="h3">{rackList ? rackList.length : 0}</Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="right">
          <IconWrapperStyle>
            <Icon icon={teamIcon} width={35} height={35} />
          </IconWrapperStyle>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
