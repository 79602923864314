import { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Button } from '@material-ui/core';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch } from 'react-redux';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import ConfirmDialog from '../../ConfirmDialog';
import { getIDToken } from '../../authentication/login/amplify';

DeleteTeamButton.propTypes = {
  variant: PropTypes.string,
  team: PropTypes.string,

  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object
  ]),
  disabled: PropTypes.bool,
  updateTeams: PropTypes.func
};

export default function DeleteTeamButton({ variant, team, sx = [], disabled, updateTeams }) {
  const dispatch = useDispatch();
  const [confirmDialogOpen, setDialogOpen] = useState(false);
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  const deleteTeam = async (team) => {
    console.debug(`Deleting team: ${team}`);
    const reqBody = [team];
    const jwt = await getIDToken();
    if (jwt) {
      // proceed to delete team
      axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API}/admin/teams`,
        data: reqBody,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
      })
        .then((response) => {
          console.debug(`successfully deleted team: ${JSON.stringify(response.data)}`);
          openSnackbar({ message: 'Team deleted successfully!' });
          updateTeams();
          return response.data;
        })
        .catch((error) => {
          console.error(error.message);
          openSnackbar({ message: 'Failed to delete team', severity: 'error' });
        });
    } else {
      console.error('JWT has expired, cannot delete team');
    }
  };

  const handleDialogCancel = () => {
    setDialogOpen(false);
  };
  const handleDialogConfirm = () => {
    deleteTeam(team);
    setDialogOpen(false);
  };
  const handleDialogOpen = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  return (
    <>
      <ConfirmDialog
        title="Delete Team?"
        content="Are you sure you wish to delete the specified team?"
        onCancelHandler={handleDialogCancel}
        onConfirmHandler={handleDialogConfirm}
        isOpen={confirmDialogOpen}
      />
      {variant === 'icononly' && (
        <Tooltip title="Delete Team">
          <IconButton onClick={handleDialogOpen} sx={{ ...sx }} disabled={disabled} color="primary">
            <DeleteOutlineIcon data-testid="delete-icon" />
          </IconButton>
        </Tooltip>
      )}
      {variant === 'text' && (
        <Tooltip title="Delete Team">
          <Button
            onClick={handleDialogOpen}
            variant="contained"
            sx={{ ...sx }}
            disabled={disabled}
            startIcon={<DeleteOutlineIcon />}
          >
            Delete Team
          </Button>
        </Tooltip>
      )}
    </>
  );
}
