import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFeatureFlags } from '../store/slices/featureFlagsSlice';
import { getIDToken } from '../components/authentication/login/amplify';

export function useIsFeatureFlagEnabled(feature) {
  // custom hook that checks to see if the feature flag is enabled
  const featureFlags = useSelector((state) => state.featureFlags.value);
  // check the list of featureFlags to see if feature is enabled
  const found = featureFlags.find((ff) => {
    if (ff.name.toLowerCase() === feature.toLowerCase()) {
      return ff.enabled;
    }
    return false;
  });
  return found !== undefined;
}

// this hook retrieves the available feature flags from the vrack api and stores it in redux
export default function useFeatureFlags() {
  const dispatch = useDispatch();
  const jwtPayload = useSelector((state) => state.jwtPayload.value);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      const jwt = await getIDToken();
      if (jwt) {
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_API}/featureflags`,
          headers: { Authorization: `Bearer ${jwt}` }
        })
          .then((response) => {
            console.debug(`successfully fetched feature flags: ${JSON.stringify(response.data)}`);
            dispatch(setFeatureFlags(response.data));
            return response.data;
          })
          .catch(() => {});
      }
    };
    if (jwtPayload !== '') {
      fetchFeatureFlags();
    }
  }, [dispatch, jwtPayload]);

  return null;
}
