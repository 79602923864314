import { useState, useEffect } from 'react';
import {
  Stack,
  Autocomplete,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Alert
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useVrackApi from '../../../hooks/useVrackApi';

const styles = {
  componentDetails: {
    marginTop: 2,
    marginBottom: 2
  }
};

MMALoadComponent.propTypes = {
  mmaAirline: PropTypes.object,
  setMMAAirline: PropTypes.func,
  mmaProfile: PropTypes.object,
  setMMAProfile: PropTypes.func,
  mmaCycle: PropTypes.string,
  setMMACycle: PropTypes.func,
  mmaExportVersion: PropTypes.object,
  setMMAExportVersion: PropTypes.func
};

export default function MMALoadComponent({
  mmaAirline,
  setMMAAirline,
  mmaProfile,
  setMMAProfile,
  mmaCycle,
  setMMACycle,
  mmaExportVersion,
  setMMAExportVersion
}) {
  const [mmaAirlines, setMMAAirlines] = useState([]);
  const { loading: airlinesLoading, fetchData: fetchAirlines } = useVrackApi(
    'get',
    '/mma/airlines'
  );
  const [mmaProfiles, setMMAProfiles] = useState([]);
  const { loading: profilesLoading, fetchData: fetchMMAProfiles } = useVrackApi(
    'get',
    '/mma/profiles/:airline'
  );
  const [mmaCycles, setMMACycles] = useState({}); // will be a dict of years as keys and months as values {2000: [1,2,3,4]}
  const [mmaCycleYear, setMMACycleYear] = useState(null);
  const [mmaCycleMonth, setMMACycleMonth] = useState(null);
  const { loading: mmaCyclesLoading, fetchData: fetchMMACycles } = useVrackApi(
    'get',
    '/mma/cycles/:airline'
  );
  const [mmaExportVersions, setMMAExportVersions] = useState([]);
  const { loading: mmaVersionsLoading, fetchData: fetchMMAExportVersions } = useVrackApi(
    'get',
    '/mma/exports/:airline'
  );
  const [showReleasedVersions, setShowReleasedVersions] = useState(false);
  const nextProfileSelected = mmaProfile ? mmaProfile.name?.toLowerCase().includes('next') : false;

  useEffect(() => {
    getMMAAirlines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mmaAirline) {
      // retrieve profiles when mma airline updates
      getMMAProfiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mmaAirline]);

  useEffect(() => {
    if (mmaProfile) {
      // retrieve cycles when mma profile updates
      getMMACycles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mmaProfile]);

  useEffect(() => {
    // reset cycle and export values when cycle year changes
    setMMACycleMonth(null);
    setMMACycle('');
    setMMAExportVersions([]);
    setMMAExportVersion(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mmaCycleYear]);

  useEffect(() => {
    // sets the final mma cycle value when the cycle month is selected
    setMMACycle(getMMACycleFormatted());
    setMMAExportVersion(null);
    setMMAExportVersions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mmaCycleMonth]);

  useEffect(() => {
    if (mmaCycle !== '') {
      // if mma cycle is updated, fetch new mma export versions
      getMMAExportVersions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mmaCycle, showReleasedVersions]);

  const getMMAAirlines = async () => {
    // reset other dependent fields
    await setMMAProfile(null);
    await setMMAProfiles([]);
    await setMMACycles({});
    await setMMACycle('');
    await setMMAExportVersion(null);
    await setMMAExportVersions([]);
    try {
      const result = await fetchAirlines();
      setMMAAirlines(result);
    } catch (error) {
      console.error('Error fetching mma airlines:', error);
    }
    return [];
  };

  const getMMAProfiles = async () => {
    // reset other dependent fields
    await setMMAProfile(null);
    await setMMAProfiles([]);
    await setMMACycle('');
    await setMMACycleYear(null);
    await setMMACycleMonth(null);
    await setMMACycles({});
    await setMMAExportVersion(null);
    await setMMAExportVersions([]);
    try {
      const result = await fetchMMAProfiles({
        url: `/mma/profiles/${mmaAirline.icao?.toLowerCase()}`
      });
      setMMAProfiles(result);
    } catch (error) {
      console.error('Error fetching mma profiles:', error);
    }
    return [];
  };

  const getMMACycles = async () => {
    await setMMACycle('');
    await setMMACycleMonth(null);
    await setMMACycleYear(null);
    await setMMACycles({});
    await setMMAExportVersion(null);
    await setMMAExportVersions([]);
    try {
      const result = await fetchMMACycles({
        url: `/mma/cycles/${mmaAirline.icao?.toLowerCase()}`,
        params: { profile: mmaProfile.name }
      });

      const cycles = {};
      result.forEach((cycle) => {
        if (cycle.year in cycles) {
          cycles[cycle.year].push(cycle.month);
        } else {
          cycles[cycle.year] = [cycle.month];
        }
      });
      setMMACycles(cycles);
    } catch (error) {
      console.error('Error fetching mma cycles:', error);
    }
    return [];
  };

  const getMMACycleMonths = () => (mmaCycleYear in mmaCycles ? mmaCycles[mmaCycleYear] : []);

  // Formats the Cycle date to MMA cycle format (MMYY)
  const getMMACycleFormatted = () => {
    if (mmaCycleYear && mmaCycleMonth) {
      const year = mmaCycleYear.slice(-2);
      const month = mmaCycleMonth < 10 ? `0${mmaCycleMonth}` : `${mmaCycleMonth}`;
      return `${month}${year}`;
    }
    return '';
  };

  const getMMAExportVersions = async () => {
    await setMMAExportVersions([]);
    await setMMAExportVersion(null);
    try {
      const params = {
        profile: mmaProfile.name,
        cycle: getMMACycleFormatted()
      };
      if (showReleasedVersions) {
        // apply isReleased query only if showReleasedVersions is toggled
        params.isReleased = true;
      }
      const result = await fetchMMAExportVersions({
        url: `/mma/exports/${mmaAirline.icao?.toLowerCase()}`,
        params
      });
      setMMAExportVersions(result);
    } catch (error) {
      console.error('Error fetching mma export versions:', error);
    }
    return () => {
      // Cancel the fetch request if the component unmounts
      getMMAExportVersions.cancel();
    };
  };

  return (
    <Stack spacing={2} sx={styles.componentDetails} data-testid="mma-load-component">
      <Autocomplete
        data-testid="mma-airline-select"
        aria-required
        size="small"
        value={mmaAirline}
        options={mmaAirlines}
        getOptionLabel={(option) => option.name || ''}
        loading={airlinesLoading}
        onChange={(_, value) => {
          setMMAAirline(value);
        }}
        inputprops={{ 'data-testid': 'mma-airlines' }}
        renderInput={(params) => <TextField {...params} label="Select an Airline" />}
      />
      <Autocomplete
        data-testid="mma-profile-select"
        size="small"
        value={mmaProfile}
        options={mmaProfiles}
        getOptionLabel={(option) => option.name || ''}
        loading={profilesLoading}
        onChange={(_, value) => {
          setMMAProfile(value);
        }}
        inputprops={{ 'data-testid': 'mma-profile' }}
        renderInput={(params) => <TextField {...params} label="Select a Profile" />}
        disabled={!mmaAirline}
        noOptionsText="No Profiles Found"
      />
      <Stack direction="row" spacing={2}>
        <Autocomplete
          data-testid="mma-cycle-year-select"
          fullWidth
          size="small"
          value={mmaCycleYear}
          options={Object.keys(mmaCycles)}
          loading={mmaCyclesLoading}
          onChange={(_, value) => {
            setMMACycleYear(value);
          }}
          inputprops={{ 'data-testid': 'mma-cycle-year' }}
          renderInput={(params) => <TextField {...params} label="Select Media Cycle Year" />}
          disabled={!mmaProfile}
          noOptionsText="No Media Cycle Years Found"
        />
        <Autocomplete
          data-testid="mma-cycle-month-select"
          fullWidth
          size="small"
          value={mmaCycleMonth}
          options={getMMACycleMonths()}
          loading={mmaCyclesLoading}
          onChange={(_, value) => {
            setMMACycleMonth(value);
          }}
          inputprops={{ 'data-testid': 'mma-cycle-month' }}
          renderInput={(params) => <TextField {...params} label="Select Media Cycle Month" />}
          disabled={!mmaCycleYear}
          noOptionsText="No Media Cycle Months Found"
        />
      </Stack>
      <Autocomplete
        data-testid="mma-export-version-select"
        size="small"
        options={mmaExportVersions}
        getOptionLabel={(option) => option.export_version || ''}
        loading={mmaVersionsLoading}
        value={mmaExportVersion}
        onChange={(_, value) => {
          setMMAExportVersion(value);
        }}
        inputprops={{ 'data-testid': 'mma-export-version' }}
        renderInput={(params) => <TextField {...params} label="Select a Version" />}
        disabled={mmaCycle === ''}
        noOptionsText="No Versions Found"
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={showReleasedVersions}
              onChange={(_, value) => {
                setShowReleasedVersions(value);
              }}
              disabled={!mmaCycle}
            />
          }
          label="Released Versions Only"
        />
      </FormGroup>
      {nextProfileSelected && (
        <Alert severity="info">
          For NEXT profiles, the vrack must have a software kit that includes a certificate that
          allows this media to be loaded.
        </Alert>
      )}
    </Stack>
  );
}
