import * as React from 'react';
import {
  Modal,
  Fade,
  Backdrop,
  Grid,
  Card,
  CardHeader,
  Button,
  TextField,
  Alert,
  Stack,
  Divider
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FlightIcon from '@mui/icons-material/Flight';
import { LoadingButton } from '@material-ui/lab';
import useVrackApi from '../../../hooks/useVrackApi';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  p: 4
};

const cityPairErrorText = 'The City Pair value is invalid.  Make sure that value is 1-4 characters';
const defaultArrivalText = `Destination City ID for the flight`;
const defaultDepartText = `Departure City ID for the flight`;
const cityPairMaxLength = 4;
const maxFlightNumber = 10;
const validCityPair = new RegExp(`^[A-Z]{1,${cityPairMaxLength}}$`);
const defaultFlightNumberText = `Identification number of the flight`;
const flightNumberTextError = `The Flight Number is invalid.  Please make sure the Flight Number is 10 characters or less`;

const tdMap = {
  arrival: '42',
  depart: '43',
  flight: '44'
};

function validateCityPair(cityPairValue) {
  return validCityPair.test(cityPairValue);
}

function validateFlightNumber(flightNumber) {
  return flightNumber.length <= maxFlightNumber;
}

RackTDsModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  rackName: PropTypes.string,
  setLoading: PropTypes.func,
  loading: PropTypes.bool
};

export default function RackTDsModal({ isOpen, closeHandler, rackName, loading, setLoading }) {
  const identity = useSelector((state) => state.identity.value);

  const [departTD, setDepartTD] = useState('');
  const [arrivalTD, setArrivalTD] = useState('');
  const [flightNumber, setFlightNumber] = useState('');
  const [departError, setDepartError] = useState(false);
  const [arrivalError, setArrivalError] = useState(false);
  const [flightError, setFlightError] = useState(false);
  const [flightNumberText, setFlightNumberText] = useState(defaultFlightNumberText);
  const [departText, setDepartText] = useState(defaultDepartText);
  const [arrivalText, setArrivalText] = useState(defaultArrivalText);
  const [validFlightNumber, setValidFlightNumber] = useState(false);
  const [disabledSetButton, setDisabledSetButton] = useState(false);
  const [validDepart, setValidDepart] = useState(false);
  const [validArrival, setValidArrival] = useState(false);
  const { fetchData: vrackApiGetRackTds } = useVrackApi('get', `/racks/${rackName}/tds`);
  const { fetchData: vrackApiSetRackTDs } = useVrackApi('post', `/racks/${rackName}/tds`);
  const dispatch = useDispatch();
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  const handleDepartChange = async (event) => {
    const departID = event.target.value.toUpperCase();
    setDepartTD(departID);

    if (departID !== '') {
      if (validateCityPair(departID)) {
        setDepartError(false);
        setValidDepart(false);
      } else {
        setDepartError(true);
        setValidDepart(true);
      }
    } else {
      setDepartError(false);
      setValidDepart(false);
    }
  };
  const handleArrivalCHange = async (event) => {
    const arrivalID = event.target.value.toUpperCase();
    setArrivalTD(arrivalID);
    if (arrivalID !== '') {
      if (validateCityPair(arrivalID)) {
        setArrivalError(false);
        setValidArrival(false);
      } else {
        setArrivalError(true);
        setValidArrival(true);
      }
    } else {
      setArrivalError(false);
      setValidArrival(false);
    }
  };
  const handleFlightChange = async (event) => {
    const flightID = event.target.value;
    setFlightNumber(flightID);
    if (flightNumber !== '') {
      if (validateFlightNumber(flightID)) {
        setFlightError(false);
        setValidFlightNumber(false);
      } else {
        setFlightError(true);
        setValidFlightNumber(true);
      }
    } else {
      setFlightError(false);
      setValidFlightNumber(false);
    }
  };

  const handleOnSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const mappedData = [
      { key: tdMap.arrival, value: arrivalTD },
      { key: tdMap.depart, value: departTD },
      { key: tdMap.flight, value: flightNumber }
    ];
    await vrackApiSetRackTDs({
      data: { tds: mappedData, identity, releaseName: `${rackName}` }
    })
      .then((response) => {
        openSnackbar({ message: 'Flight Data Values set successfully' });
        console.debug(`successfully set Flight Data Values: ${JSON.stringify(response.tds)}`);
      })
      .catch((error) => {
        console.error(error.message);
        openSnackbar({ message: 'Failed to set Flight Data Values', severity: 'error' });
      });
  };

  const getRackTDs = async (rackName) => {
    await vrackApiGetRackTds('get', `racks/${rackName}/tds`)
      .then((response) => {
        console.debug(`Response from TDs endpoint: ${JSON.stringify(response)}`);
        response.forEach((item) => {
          switch (item.key) {
            case tdMap.arrival:
              setArrivalTD(item.value);
              break;
            case tdMap.depart:
              setDepartTD(item.value);
              break;
            case tdMap.flight:
              setFlightNumber(item.value);
              break;
            default:
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const validateInputs = () => {
    if (!validArrival && !validDepart && !validFlightNumber) {
      setDisabledSetButton(false);
    } else {
      setDisabledSetButton(true);
    }
  };

  useEffect(() => {
    validateInputs();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [validArrival, validDepart, validFlightNumber]);
  useEffect(() => {
    if (isOpen === true) {
      getRackTDs(rackName);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isOpen]);

  useEffect(() => {
    if (flightError) {
      setFlightNumberText(flightNumberTextError);
    } else {
      setFlightNumberText(defaultFlightNumberText);
    }
  }, [flightError]);
  useEffect(() => {
    if (departError) {
      setDepartText(cityPairErrorText);
    } else {
      setDepartText(defaultDepartText);
    }
  }, [departError]);
  useEffect(() => {
    if (arrivalError) {
      setArrivalText(cityPairErrorText);
    } else {
      setArrivalText(defaultArrivalText);
    }
  }, [arrivalError]);
  return (
    <Modal
      open={isOpen}
      onClose={closeHandler}
      aria-labelledby="Flight-Data"
      aria-describedby="modal to show the flight data for virtual racks"
      BackdropComponent={Backdrop}
      data-testid="tds-modal"
    >
      <Fade in={isOpen}>
        <Grid container direction="row" sx={style}>
          <Grid item xs={12} md={1} lg={3} onClick={closeHandler} />
          <Grid item xs={12} md={10} lg={6}>
            <Card>
              <CardHeader title="Flight Data" />
              <Grid container item px={2} pb={4} spacing={3}>
                <Grid item xs={12}>
                  <Alert severity="warning">
                    Flight Data parameters will not persist if the LRU is rebooted. If a reboot
                    occurs, you need to reset the parameters.
                  </Alert>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="flightNumber"
                    label="Flight Number"
                    error={flightError}
                    helperText={flightNumberText}
                    onChange={handleFlightChange}
                    value={flightNumber}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="departTD"
                      label="Departure City ID"
                      error={departError}
                      helperText={departText}
                      onChange={handleDepartChange}
                      value={departTD}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="arrivalTD"
                      label="Destination City ID"
                      error={arrivalError}
                      helperText={arrivalText}
                      onChange={handleArrivalCHange}
                      value={arrivalTD}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <Stack spacing={2} direction="row" sx={{ p: 2, justifyContent: 'right' }}>
                <Button
                  onClick={closeHandler}
                  size="small"
                  color="secondary"
                  endIcon={<CloseOutlinedIcon />}
                  variant="outlined"
                >
                  Close
                </Button>
                <LoadingButton
                  onClick={handleOnSubmit}
                  size="small"
                  loading={loading}
                  loadingPosition="end"
                  endIcon={<FlightIcon />}
                  variant="contained"
                  disabled={disabledSetButton}
                >
                  <span> Set Flight Values</span>
                </LoadingButton>
              </Stack>
            </Card>
            <Grid item xs={12} med={1} lg={3} onClick={closeHandler} />
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
}
