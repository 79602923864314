import { CardContent, Stack, Skeleton, Grid } from '@material-ui/core';

const numSkeletonBlocks = 3;
const numLinesPerSkeletonBlock = 4;

export default function LoadingSkeleton() {
  return (
    <Grid data-testid="loading-skeleton" item xs sx={{ overflow: 'auto' }}>
      <CardContent
        sx={{
          width: '100%',
          height: '100%'
        }}
      >
        <Stack
          sx={{ marginRight: 1 }}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Skeleton
            animation="wave"
            sx={{ borderRadius: '30px', width: '230px', height: '55px' }}
          />
        </Stack>

        <Stack spacing={3} sx={{ marginTop: 1 }}>
          {[...Array(numSkeletonBlocks)].map((e, i) => (
            <Stack key={i} spacing={1}>
              {[...Array(numLinesPerSkeletonBlock)].map((e, i) => (
                <Stack key={i} spacing={1}>
                  <Skeleton animation="wave" />
                </Stack>
              ))}
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Grid>
  );
}
