/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import filesize from 'filesize';
import {
  Container,
  Grid,
  Typography,
  Button,
  Stack,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';
import LoadablesTable from '../components/team/LoadablesTable';
import LoadableDropzone from '../components/team/LoadableDropzone';
import LoadableDetails from '../components/team/LoadableDetails';
import LoadableCapacity from '../components/team/LoadableCapacity';
import Page from '../components/Page';
import { setOpenSnackbar } from '../store/slices/snackbarSlice';
import { GetUserEmail } from '../utils/authentication';
import { getIDToken } from '../components/authentication/login/amplify';

const descriptionMaxLength = 64;
const validDescription = new RegExp(
  `^(?=.{1,${descriptionMaxLength}}$)[a-zA-Z0-9]+(\\s{0,1}[a-zA-Z0-9.:]+)*(\\s{0,1}[a-zA-Z0-9]+)+$`
);

export default function UploadLoadable() {
  const identity = useSelector((state) => state.identity.value);
  const dispatch = useDispatch();
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [filename, setFilename] = useState('');
  const [description, setDescription] = useState('');

  const [descriptionError, setDescriptionError] = useState(false);

  const [acceptedFiles, setAcceptedFiles] = useState(null);
  const [loadables, setLoadables] = useState([]);
  const [totalStored, setTotalStored] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);

  const [fileDropzoneError, setFileDropzoneError] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const validateInputs = () => {
    // check that partnumber is 8 characters long
    let errEncountered = false;

    if (!validDescription.test(description)) {
      errEncountered = true;
      setDescriptionError(true);
    }

    // check that a file was provided
    if (!acceptedFiles) {
      errEncountered = true;
      setFileDropzoneError(true);
    }

    return errEncountered;
  };

  useEffect(() => {
    if (identity) {
      getLoadables();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [identity]);

  const performUpload = (file) => {
    // retrieve upload url
    retrieveUploadURL(file)
      .then((resp) => {
        uploadLoadable(file, resp.data.presignedURL)
          .then(() => {
            getLoadables();
            setDescription('');
            openSnackbar({ message: `${file.name} uploaded successfully` });
          })
          .catch((err) => {
            console.error(err.message);
            openSnackbar({
              message: `${file.name} failed to uploaded successfully`,
              severity: 'error'
            });
          })
          .finally(() => {
            setUploadDisabled(false);
          });
      })
      .catch((err) => {
        let message = `${file.name} failed to uploaded successfully`;
        if (err.response) {
          if (err.response.data.message.includes('object already exists')) {
            message = `Loadable already exists with the specified filename. Please change the filename to be unique and attempt your upload again`;
          } else if (err.response.data.message.includes('exceed the maximum capacity')) {
            message = `Loadable of size ${filesize(
              file.size
            )} would exceed maximum storage capacity. Please delete some existing loadables and attempt your upload again`;
          }
        }
        console.error(err.message);
        openSnackbar({
          message,
          severity: 'error'
        });
        setUploadDisabled(false);
      });
  };

  const uploadLoadable = (file, uploadURL) => {
    const email = GetUserEmail();

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      }
    };

    return axios({
      method: 'put',
      url: uploadURL,
      data: file,
      onUploadProgress: config.onUploadProgress,
      headers: {
        'Content-Type': file.type,
        'x-amz-tagging': `description=${encodeURIComponent(description).replace(
          /%20/g,
          '+'
        )}&identity=${encodeURIComponent(identity.toLowerCase())}&user=${encodeURIComponent(
          email.toLowerCase()
        )}`
      }
    });
  };

  const getLoadables = async () => {
    const jwt = await getIDToken();
    if (jwt) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}/identities/${identity}/loadables`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((resp) => {
          setLoadables(resp.data.components);
          setTotalStored(resp.data.size);
          setTotalCapacity(resp.data.total_capacity);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.error('JWT has expired, cannot retrieve uploaded loadables');
    }
  };

  const retrieveUploadURL = async (file) => {
    const jwt = await getIDToken();
    if (jwt) {
      // proceed to retrieve url for uploading file
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/identities/${identity}/loadables`,
        data: { filename, description },
        headers: {
          'Content-Type': 'application/json',
          'file-content-type': file.type,
          'file-content-size': file.size,
          Authorization: `Bearer ${jwt}`
        }
      });
    }
    return Promise.reject(new Error('missing jwt'));
  };

  return (
    <Page title="Admin | Teams | Virtual Racks">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Loadables</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Upload Loadable"
                subheader="Upload a loadable from your machine which can then be loaded to your Virtual Rack. Note: Filename must be unique"
              />
              <CardContent>
                <Grid container spacing={2} item xs={12} mb={3}>
                  <LoadableDetails
                    description={description}
                    setDescription={setDescription}
                    descriptionError={descriptionError}
                    clearErrors={() => {
                      if (descriptionError) {
                        setDescriptionError(false);
                      }
                    }}
                  />
                  <Grid item xs={12}>
                    <LoadableDropzone
                      uploadProgress={uploadProgress}
                      setUploadProgress={setUploadProgress}
                      acceptedFiles={acceptedFiles}
                      setAcceptedFiles={setAcceptedFiles}
                      setFilename={setFilename}
                      setDropzoneError={setFileDropzoneError}
                      fileDropzoneError={fileDropzoneError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      disabled={uploadDisabled}
                      onClick={() => {
                        setUploadDisabled(true);
                        if (!validateInputs()) {
                          performUpload(acceptedFiles);
                        } else {
                          setUploadDisabled(false);
                        }
                      }}
                      size="large"
                      variant="contained"
                    >
                      Upload
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Existing Loadables"
                subheader="All currently uploaded loadables for the currently selected identity"
              />
              <CardContent>
                <LoadablesTable updateLoadableList={getLoadables} loadableList={loadables} />
                <Grid container item xs={12}>
                  <Grid item mt={1} ml={2} xs={5} md={2}>
                    <LoadableCapacity capacity={totalCapacity} totalStored={totalStored} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
