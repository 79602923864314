/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import PropTypes from 'prop-types';
// material
import { Typography, Box } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

// components
import filesize from 'filesize';
import palette from '../../theme/palette';
// ----------------------------------------------------------------------

// ---------------------------------------------------------------------

LoadableCapacity.propTypes = {
  totalStored: PropTypes.number,
  capacity: PropTypes.number
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[500_16]
}));

const errorColor = '#d93025';

const getColorByPercent = (percent) => {
  let color = palette.primary.main;

  if (percent >= 50 && percent <= 75) {
    color = palette.warning.main;
  } else if (percent > 75) {
    color = errorColor;
  }
  return color;
};

export default function LoadableCapacity({ totalStored, capacity }) {
  const percentUsed = Math.min(100, Math.round((totalStored / capacity) * 100));
  const isLoading = capacity === 0;
  return (
    <Box>
      <BorderLinearProgress
        sx={{ '& .MuiLinearProgress-bar': { backgroundColor: getColorByPercent(percentUsed) } }}
        variant={isLoading ? 'indeterminate' : 'determinate'}
        value={percentUsed}
      />
      {!isLoading && (
        <Box pt={0.5} pl={2}>
          <Typography variant="caption">
            {filesize(totalStored)} of {filesize(capacity)} used
          </Typography>
        </Box>
      )}
    </Box>
  );
}
