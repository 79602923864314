import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import AuthLayout from './layouts/AuthLayout';
//
import NotFound from './pages/Page404';
import PageUnauthorized from './pages/PageUnauthorized';
import Racks from './pages/Racks';
import CreateRack from './pages/CreateRack';
import RackDetails from './pages/RackDetails';
import Policies from './pages/Policies';
import Teams from './pages/Teams';
import VSMConnect from './pages/VSMConnect';
import VSMRemoteView from './pages/VSMRemoteView';

import AdminSettings from './pages/AdminSettings';
import UploadLoadable from './pages/UploadLoadable';
//
import ProtectedRoute from './components/nav/ProtectedRoute';

// ----------------------------------------------------------------------
const reload = () => window.location.reload();

export default function Router() {
  return useRoutes([
    {
      path: '/racks',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: '/racks', element: <Racks /> },
        {
          path: '/racks/details/:rackname',
          element: <RackDetails />
        }
      ]
    },
    {
      path: '/admin',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: 'policies', element: <Policies /> },
        { path: 'teams', element: <Teams /> },
        { path: 'settings', element: <AdminSettings /> }
      ]
    },
    {
      path: '/createrack',
      element: (
        <ProtectedRoute>
          <DashboardLayout>
            <CreateRack />
          </DashboardLayout>
        </ProtectedRoute>
      )
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '401', element: <PageUnauthorized /> },
        { path: '/', element: <Navigate to="/racks" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/login',
      element: <AuthLayout />
    },
    {
      path: '/static/help-system/*',
      onEnter: { reload }
    },
    {
      path: '/vsm/:rackname',
      element: (
        <ProtectedRoute>
          <VSMConnect />
        </ProtectedRoute>
      )
    },
    {
      path: '/webrtc/:rackname',
      element: (
        <ProtectedRoute>
          <VSMRemoteView />
        </ProtectedRoute>
      )
    },
    {
      path: '/team',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [{ path: 'upload', element: <UploadLoadable /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
