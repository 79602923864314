import { filter } from 'lodash';

function descendingComparator(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

// returns a comparator to use based on the order passed in
export function getComparator(order) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b)
    : (a, b) => -descendingComparator(a, b);
}

// applies a sort filter on an array of items based on a comparator
export function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (items) => {
      // searches the array of items
      if (items.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        return true;
      }
      return false;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparatorWithOrderBy(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// returns a comparator that supports an order by param
export function getComparatorWithOrderBy(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparatorWithOrderBy(a, b, orderBy)
    : (a, b) => -descendingComparatorWithOrderBy(a, b, orderBy);
}
