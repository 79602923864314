import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, Button } from '@material-ui/core';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { RackStatusHistoryModal } from '.';

RackStatusHistoryButton.propTypes = {
  rackStatuses: PropTypes.array,
  variant: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object
  ]),
  jobInProgress: PropTypes.bool
};

RackStatusHistoryButton.defaultProps = {
  rackStatuses: [],
  variant: 'outlined',
  color: 'secondary',
  type: 'text',
  jobInProgress: 'false'
};

export default function RackStatusHistoryButton({
  rackStatuses,
  variant,
  color,
  type,
  sx = [],
  jobInProgress
}) {
  const [rackStatusModalOpen, setRackStatusModalOpen] = useState(false);

  const handleDialogCancel = () => {
    setRackStatusModalOpen(false);
  };

  const handleDialogOpen = (event) => {
    event.stopPropagation();
    setRackStatusModalOpen(true);
  };

  return (
    <>
      <RackStatusHistoryModal
        rackStatuses={rackStatuses}
        isOpen={rackStatusModalOpen}
        closeHandler={handleDialogCancel}
        jobInProgress={jobInProgress}
      />
      <Tooltip title="Shows Rack Status History" placement="top">
        <>
          {type === 'icononly' && (
            <IconButton variant={variant} onClick={handleDialogOpen} sx={{ ...sx }} color={color}>
              <ListAltIcon />
            </IconButton>
          )}
          {type === 'text' && (
            <Button
              onClick={handleDialogOpen}
              variant={variant}
              sx={{ ...sx }}
              startIcon={<ListAltIcon />}
              color={color}
            >
              History
            </Button>
          )}
        </>
      </Tooltip>
    </>
  );
}
