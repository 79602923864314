import PropTypes from 'prop-types';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Box } from '@material-ui/core';
import { fDateTimeSuffix } from '../../../../utils/formatTime';

const styles = {
  table: {
    '.MuiDataGrid-cellContent': {
      overflow: 'auto',
      textOverflow: 'inherit'
    }
  }
};

const columns = [
  {
    field: 'identifier',
    headerName: 'Identifier',
    description: 'the name or identifier for the component',
    flex: 0.6
  },
  {
    field: 'version',
    headerName: 'Version',
    description: 'the version of the component',
    flex: 0.3,
    valueGetter: (params) => getComponentVersion(params.row)
  },
  {
    field: 'type',
    headerName: 'Type',
    description: 'the type of the component',
    flex: 0.3
  },
  {
    field: 'loaded_by',
    description: 'the user that initiated the loading operation',
    headerName: 'Loaded By',
    flex: 0.7
  },
  {
    field: 'date',
    headerName: 'Date Loaded',
    description: 'the date the component was loaded to the rack',
    flex: 0.5,
    valueGetter: (params) => fDateTimeSuffix(params.value)
  },
  {
    field: 'status',
    headerName: 'Status',
    description: 'the status of the load operation',
    flex: 0.3
  }
];

// retrieves the version field for the component
function getComponentVersion(comp) {
  if (comp.mediainfo?.airline) {
    // for media component, the version is found inside mediainfo
    return comp.mediainfo.version;
  }
  return comp.version;
}

// Gets an easily readable label for the component since they can vary depending on the component type
function getComponentIdentifier(comp) {
  switch (comp.source) {
    case 'aws':
      return comp.filename;
    case 'scmdb':
      return comp.partnumber;
    case 'mma':
      return `${comp.mediainfo.airline}-${comp.mediainfo.profile}-${comp.mediainfo.cycle}`.toLowerCase();
    default:
      return '';
  }
}

function getComponentsLoadingHistory(componentsLoadingHistory) {
  // returns a list of components loaded in order from newest to oldest
  const componentsList = [];
  const componentTypes = ['onemedia', 'content', 'software', 'media'];
  if (componentsLoadingHistory != null) {
    componentsLoadingHistory.forEach((componentEntry) => {
      componentTypes.forEach((compType) => {
        if (componentEntry[compType].components) {
          componentEntry[compType].components.forEach((comp) => {
            componentsList.push({
              ...comp,
              id: `${componentEntry.job_id}-${comp.partnumber}-${comp.version}`,
              identifier: getComponentIdentifier(comp),
              type: compType,
              loaded_by: componentEntry.loaded_by,
              date: componentEntry.date,
              status: componentEntry[compType].status
            });
          });
        }
      });
    });
  }
  return componentsList.reverse();
}

ComponentLoadHistory.propTypes = {
  rackDetails: PropTypes.object
};

export default function ComponentLoadHistory({ rackDetails }) {
  const [pageSize, setPageSize] = useState(5);
  const rows = rackDetails.loading_history
    ? getComponentsLoadingHistory(rackDetails.loading_history.components)
    : [];

  return (
    <Box>
      <Typography variant="subtitle2" display="block" my={2}>
        Components
      </Typography>

      <DataGrid
        getRowId={(row) => row.id + row.filename}
        rows={rows}
        columns={columns}
        autoHeight
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        disableSelectionOnClick
        density="compact"
        sx={styles.table}
      />
    </Box>
  );
}
