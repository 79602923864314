import axios from 'axios';
import { useState, useEffect } from 'react';
import {
  Typography,
  Modal,
  Fade,
  Backdrop,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Stack
} from '@material-ui/core';
import PropTypes from 'prop-types';
import UserTable from './UserTable';
import RoleTable from './RoleTable';
import AssignUserToTeamDialog from './AssignUserToTeamDialog';
import AssignRoleToTeamDialog from './AssignRoleToTeamDialog';
import { getIDToken } from '../../authentication/login/amplify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  p: 4
};

TeamDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  team: PropTypes.string
};

export default function TeamDetailsModal({ isOpen, closeHandler, team }) {
  const [users, setUsers] = useState([]);
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    // initial call to get racklist when team is updated
    fetchTeamsRoles();
    fetchTeamsUsers();
    // eslint-disable-next-line
  }, [team]);

  const fetchTeamsUsers = async () => {
    const jwt = await getIDToken();
    if (jwt && team !== '') {
      axios
        .get(`${process.env.REACT_APP_API}/admin/teams/${team}/users`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
        .then((response) => {
          setUsers(response.data);
          return response.data;
        })
        .catch((error) => {
          console.error('failed to get list of teams ', error.message);
        });
    }
  };

  const fetchTeamsRoles = async () => {
    const jwt = await getIDToken();
    if (jwt && team !== '') {
      axios
        .get(`${process.env.REACT_APP_API}/admin/teams/${team}/roles`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
        .then((response) => {
          setRoles(response.data);
          return response.data;
        })
        .catch((error) => {
          console.error('failed to get list of teams ', error.message);
        });
    }
  };

  const handleUserCloseDialog = () => {
    setUserDialogOpen(false);
  };
  const handleRoleCloseDialog = () => {
    setRoleDialogOpen(false);
  };
  const handleRoleOpenDialog = () => {
    setRoleDialogOpen(true);
  };
  const handleUserOpenDialog = () => {
    setUserDialogOpen(true);
  };
  return (
    <>
      <AssignUserToTeamDialog
        isOpen={userDialogOpen}
        updateUsers={fetchTeamsUsers}
        closeHandler={handleUserCloseDialog}
        team={team}
      />
      <AssignRoleToTeamDialog
        isOpen={roleDialogOpen}
        closeHandler={handleRoleCloseDialog}
        updateRoles={fetchTeamsRoles}
        team={team}
      />
      <Modal
        open={isOpen}
        sx={{ outline: 'none' }}
        onClose={closeHandler}
        aria-labelledby="team-details-modal"
        aria-describedby="modal to display team details"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isOpen}>
          <Grid direction="row" sx={style} container>
            <Grid item xs={12} md={1} lg={3} onClick={closeHandler} />
            <Grid item xs={12} md={10} lg={6}>
              <Card>
                <CardHeader sx={{ padding: 2 }} title={`${team} Details`} />
                <Divider />
                <CardContent sx={{ paddingTop: 1 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ paddingTop: 2 }}
                  >
                    <Typography variant="h6">Users</Typography>
                    <Button onClick={handleUserOpenDialog} variant="contained">
                      Add User
                    </Button>
                  </Stack>
                  <UserTable team={team} updateUsers={fetchTeamsUsers} users={users} />
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ paddingTop: 2 }}
                  >
                    <Typography variant="h6">Roles</Typography>
                    <Button onClick={handleRoleOpenDialog} variant="contained">
                      Add Role
                    </Button>
                  </Stack>
                  <RoleTable team={team} updateRoles={fetchTeamsRoles} roles={roles} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} med={1} lg={3} onClick={closeHandler} />
          </Grid>
        </Fade>
      </Modal>
    </>
  );
}
