import { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, Button } from '@material-ui/core';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useDispatch } from 'react-redux';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import ConfirmDialog from '../../ConfirmDialog';
import { getIDToken } from '../../authentication/login/amplify';

async function jobCancel(rackName) {
  const jwt = await getIDToken();
  if (jwt) {
    const url = `${process.env.REACT_APP_API}/job/release/${rackName}`;
    return axios({
      url,
      method: 'delete',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
    });
  }
  return Promise.reject(new Error('missing jwt'));
}

function isCancelDisabled(rackDetails) {
  // checks if the cancel button should be disabled
  // this will see if the last job in the rack statuses list is inprogress
  if (rackDetails.statuses && rackDetails.statuses.length > 0) {
    if (rackDetails.statuses.at(-1).status === 'inprogress') {
      return false;
    }
  }
  return true;
}

CancelButton.propTypes = {
  rackDetails: PropTypes.object,
  variant: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object
  ])
};

CancelButton.defaultProps = {
  rackDetails: { running: true },
  variant: 'outlined',
  color: 'primary',
  type: 'text'
};

export default function CancelButton({ rackDetails, variant, color, type, sx = [] }) {
  const dispatch = useDispatch();

  const [confirmDialogOpen, setDialogOpen] = useState(false);
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  const handleDialogCancel = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const handleDialogConfirm = () => {
    jobCancel(rackDetails.name)
      .then(() => {
        openSnackbar({
          message: `Request to cancel current job sent successfully!`
        });
      })
      .catch((error) => {
        openSnackbar({
          message: `${error.response.data.message}`,
          severity: 'error'
        });
      });
    setDialogOpen(false);
  };

  return (
    <>
      <ConfirmDialog
        title={`Cancel the current running job for ${rackDetails.name}?`}
        content="This will cancel any running job for the virtual rack"
        onCancelHandler={handleDialogCancel}
        onConfirmHandler={handleDialogConfirm}
        isOpen={confirmDialogOpen}
      />
      <Tooltip title="Cancel the current running job" placement="top">
        <>
          {type === 'icononly' && (
            <IconButton
              variant={variant}
              onClick={handleDialogOpen}
              sx={{ ...sx }}
              disabled={isCancelDisabled(rackDetails)}
              color={color}
            >
              <CancelOutlinedIcon />
            </IconButton>
          )}
          {type === 'text' && (
            <Button
              onClick={handleDialogOpen}
              variant={variant}
              sx={{ ...sx }}
              disabled={isCancelDisabled(rackDetails)}
              startIcon={<CancelOutlinedIcon />}
              color={color}
            >
              Cancel
            </Button>
          )}
        </>
      </Tooltip>
    </>
  );
}
